<template>
  <v-container fluid>
    <v-col cols="12">
      <v-card>
        <v-card-title>Orden de pago #{{ payload.id }} <StatusLabel v-if="payload.status==='canceled'" :status="payload.status"></StatusLabel></v-card-title>
        <v-card-text>

          <v-row>
            <v-col cols="3" md="3">
              <v-btn fab small elevation="0" color="primary" @click="getPDF()">
                <v-icon>mdi-file-pdf-box-outline</v-icon>
              </v-btn>
            </v-col>
            <v-col>
              <h3>{{ payload.customer_name }}</h3>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-data-table :items="payload.lines" :headers="headers" disable-sort hide-default-footer>
              </v-data-table>
            </v-col>
          </v-row>

          <v-row>
            <v-col><h5>Total: ${{ payload.amount }}</h5></v-col>
            <v-col><h5>Pagos: ${{ calculatePayments }}</h5></v-col>
            <v-col><h5>Creditos: {{ calculateCredits }}</h5></v-col>
            <v-col v-if="this.payload.status==='created'"><h5>Saldo: ${{ calculateBalance }}</h5></v-col>
            <v-col v-else><h5>Saldo: ${{ this.payload.balance }}</h5></v-col>
          </v-row>

        </v-card-text>
      </v-card>
    </v-col>

    <v-col>
      <v-tabs fixed-tabs>
        <v-tab href="#payments">Pagos</v-tab>
        <v-tab href="#credits">Creditos</v-tab>
        <v-tab-item value="payments">
          <v-card>

            <v-card-text>
              <v-col cols="12" md="1" v-if="calculateBalance > 0&& this.payload.status==='created'">
                <v-btn fab small elevation="0" color="primary" @click="addPayment">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-data-table :items="payload.payments" :headers="paymentModal.headers" disable-sort
                              hide-default-footer></v-data-table>
              </v-col>

            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item value="credits">
          <v-card>
            <v-card-text>
              <v-col cols="12" md="1" v-if="calculateBalance != 0 && this.payload.status==='created'">
                <v-btn fab small elevation="0" color="primary" @click="addCredit">
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-col>
              <v-col>
                <v-data-table :items="payload.credits" :headers="creditsModal.headers" disable-sort
                              hide-default-footer>

                  <template v-slot:item.actions="{item}" v-if="payload.status === 'created' && $store.state.user.data.type==='staff'">
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn class="mx-1" fab x-small color="error" dark depressed
                               @click="destroyRow(item,'credit')" v-on="on">
                          <v-icon >mdi-delete-circle</v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar</span>
                    </v-tooltip>
                  </template>

                </v-data-table>
              </v-col>
            </v-card-text>

          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-col>
    <v-col>
      <v-card-actions v-if="$store.state.user.data.type==='staff' && this.calculateBalance==0">
        <v-col>
          <v-btn class="secondary" @click="receive">Recibir</v-btn>
        </v-col>
      </v-card-actions>
    </v-col>

    <!--    Payment Modal-->
    <v-dialog v-model="paymentModal.visible" max-width="400">
      <v-col cols="12">
        <v-card>
          <v-card-title>Registrar Pago</v-card-title>
          <v-card-text>
            <v-form class="pa-0" ref="form" lazy-validation>
              <v-row>
                <v-col>
                  <v-select :rules="emptyRule" label="Metodo de pago" :items="paymentModal.paymentMethods"
                            v-model="paymentModal.payload.payment_method"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field label="Referencia" v-model="paymentModal.payload.reference"></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field :rules="emptyRule" type="number" label="Importe"
                                v-model="paymentModal.payload.amount"></v-text-field>
                </v-col>
              </v-row>

            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-col>
              <v-row>
                <v-col align="center">
                  <v-alert color="warning">Por seguridad de nuestro distribuidor los pagos no pueden ser modificados o
                    cancelados. Por favor revise la información
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox label="He corroborado que los datos ingresados son correctos"
                              v-model="paymentModal.modalChecked"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="left">
                  <v-btn color="primary" v-if="paymentModal.modalChecked" @click="paymentStore">Recibir Pago</v-btn>
                </v-col>
                <v-col align="right">
                  <v-btn color="error" @click="paymentModal.visible=false">Cancelar</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-dialog>

<!--    Credits Modal-->
    <v-dialog v-model="creditsModal.visible" max-width="600">
      <v-col cols="12">
        <v-card>
          <v-card-title>Aplicar nota de credito</v-card-title>
          <v-card-text v-if="creditsModal.view === 'list'">
            <v-col>
              <v-data-table :headers="creditsModal.creditHeaders" :items="creditsModal.creditItems" hide-default-footer disable-sort>
                <template v-slot:item.select="props">
                  <v-btn dark small color="green" @click="selectCredit(props.item)">
                    <v-icon dark>mdi-check</v-icon>
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
          </v-card-text>

          <v-card-text v-else>
            <v-col>
              <v-row><h5>Nota de credito: <strong>{{creditsModal.payload.return_order_id}}</strong></h5></v-row>
              <v-row><h5>Importe: <strong>${{creditsModal.payload.amount}}</strong></h5></v-row>
            </v-col>
          </v-card-text>
          <v-card-actions v-if="creditsModal.view === 'form'">
            <v-col>
              <v-row>
                <v-col align="center">
                  <v-alert color="warning">Esta accion es irreversible, por favor asegurese de que la información es correcta.
                  </v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-checkbox label="He corroborado que los datos ingresados son correctos"
                              v-model="creditsModal.modalChecked"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col align="left">
                  <v-btn color="primary" v-if="creditsModal.modalChecked" @click="creditStore">Aplicar Nota</v-btn>
                </v-col>
                <v-col align="right">
                  <v-btn color="error" @click="creditsModal.visible=false">Cancelar</v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-card-actions>

        </v-card>

      </v-col>
    </v-dialog>

    <v-dialog v-model="destroyModal.visible" max-width="400">
      <v-card>
        <v-card-title class="headline">¿Estas seguro?</v-card-title>

        <v-card-text>
          Una vez elimines el registro, no podrás recuperarlo.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn rounded color="primary" text @click="destroyModal.visible = false">
            Mejor no
          </v-btn>

          <v-btn elevation="0" class="pa-4" rounded color="error" @click="destroyAction">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>

import StatusLabel from "@/components/helpers/statusLabel";
export default {
  components: {StatusLabel},
  data() {
    return {
      destroyModal:{
        visible: false,
        payload: {
        type: null,
        id:null
        },
      },

      paymentModal: {
        visible: false,

        modalChecked: false,
        paymentMethods: [],
        payload: {},
        headers: [
          {'text': 'ID', 'value': 'id'},
          {'text': 'Fecha', 'value': 'date'},
          {'text': 'Metodo', 'value': 'payment_method_translated'},
          {'text': 'Referencia', 'value': 'reference'},
          {'text': 'Importe', 'value': 'amount'},]
      },

      creditsModal:{
        visible:false,
        view:'list',
        modalChecked: false,
        creditHeaders:[
            {'text': 'ID', 'value': 'id'},
          {'text': 'IMPORTE', 'value': 'amount'},
          {'text': 'SALDO', 'value': 'balance'},
          {'text': 'SELECCIONAR', 'value': 'select'},],
        creditItems:[],
        payload:{},
        headers: [
          {'text': 'ID', 'value': 'id'},
          {'text': 'NOTA', 'value':'return_order_id'},
          {'text': 'Fecha', 'value': 'date'},
          {'text': 'Importe', 'value': 'amount'},
          {'text': 'ACCIONES', 'value': 'actions'}]

      },

      emptyRule: [v => !!v || 'Necesario'],
      modalChecked: false,

      payload: {},
      headers: [
        {'text': 'ID', 'value': 'id'},
        {'text': 'CONCEPTO', 'value': 'concept'},
        {'text': 'IMPORTE', 'value': 'amount'},
      ],
    };
  },
  computed: {
    sortedItems() {
      return this.items.sort((a, b) => (a.id < b.id) ? 1 : -1)
    },

    calculatePayments() {
      let sum = 0;
      if (this.payload.payments) {
        this.payload.payments.forEach(function (item) {
          sum += parseFloat(item.amount)
        })
      } else {
        sum = 0
      }
      return sum.toFixed(2)
    },
    calculateCredits() {
      let sum = 0;
      if (this.payload.credits) {
        this.payload.credits.forEach(function (item) {
          sum += parseFloat(item.amount)
        })
      } else {
        sum = 0
      }
      return sum.toFixed(2)
    },

    calculateBalance() {
      let balance = 0;
      let payments = this.calculatePayments
      let credits = this.calculateCredits
      balance = this.payload.balance - payments - credits

      return balance.toFixed(2)
    },


  },
  methods: {

    // Payment Modal Methods
    addPayment() {
      this.paymentModal.payload = {}
      this.paymentModal.visible = true

    },


    paymentStore() {
      if(this.paymentModal.payload.amount > this.calculateBalance)
      {
        alert('El importe del pago no puede ser superior al saldo')
        return
      }
      this.paymentModal.payload.payment_order_id = this.$route.params.id
      this.paymentModal.payload.customer_id = this.payload.customer_id
      //console.log(this.paymentModal.payload)
      //return
      this.$axios
          .post('/driver/payments', this.paymentModal.payload)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success) ? this.getDataFromAPI() : '';
            (response.data.success) ? this.paymentModal.visible = false : '';

          })
          .catch((error) => {
            console.log(error);
          });
    },

    // Credit Modal Methods
    rowCreditExists(id)
    {
      for(let i = 0; i< this.payload.credits.length; i++)
      {
        let itemId = this.payload.credits[i].return_order_id

        if (itemId === id) {
          return true}
        else {
          return false}
      }
    },

    addCredit(){
      this.creditsModal.payload = {}
      this.getCreditsFromAPI()
      this.creditsModal.view = 'list'
      this.creditsModal.visible = true
    },

    selectCredit(item)
    {
      if(this.rowCreditExists(item.id))
      {
        alert('Ya ha seleccionado esta nota de credito')
        return
      }
      this.creditsModal.payload.return_order_id = item.id
      this.creditsModal.payload.amount = item.amount
      this.creditsModal.view = 'form'

    },

    creditStore() {


      this.creditsModal.payload.payment_order_id = this.$route.params.id
      this.creditsModal.payload.customer_id = this.payload.customer_id
      this.$axios
          .post('/driver/credits', this.creditsModal.payload)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success) ? this.getDataFromAPI() : '';
            (response.data.success) ? this.creditsModal.visible = false : '';

          })
          .catch((error) => {
            console.log(error);
          });
    },

    creditDestroy()
    {

      let formData = new FormData();

      // Laravel delete workaround
      formData.append('_method', 'DELETE')

      this.$axios
          .post(`/driver/credits/${this.destroyModal.payload.id}`, formData)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit( 'OPEN_SNACKBAR', response.data);
            if (response.data.success)
            {
             this.getDataFromAPI()
              this.destroyModal.visible=false
          }
          })
          .catch((error)=>{
            console.log(error);
          });
    },

    calculateTotal() {
      let sum = 0;
      this.payload.items.forEach(function (item) {
        sum += parseFloat(item.amount)
      })
      this.payload.amount = sum.toFixed(2)
    },
    getDataFromAPI() {
      this.$axios
          .get('/admin/paymentorders/' + this.$route.params.id + '/edit')
          .then((response) => {
            this.payload = response.data.payment_order
            this.paymentModal.paymentMethods = response.data.payment_methods

            //console.log(this.payload);
          })
    },

    getCreditsFromAPI() {
      this.$axios
          .get('/driver/returns/reseller/' + this.payload.customer_id)
          .then((response) => {
            this.creditsModal.creditItems = response.data


            //console.log(this.payload);
          })
    },

    receive()
    {
      this.$axios
          .post('admin/paymentorder/receive', {id: this.payload.id})
          .then((response) => {
            //console.log(response)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success)? this.getDataFromAPI():''
            //console.log(this.payload.id);
          })
          .catch((error)=>{
            console.log(error);
          });
    },

    async getPDF() {
      await
              this.$axios
                      .get('admin/paymentorder/download/' + this.payload.id, {responseType: 'blob'})
                      .then((response) => {
                        //console.log(response)
                        let url = window.URL.createObjectURL(new Blob([response.data]));
                        let link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', 'Orden de pago '+this.payload.id + '.pdf');
                        document.body.appendChild(link)
                        link.click()
                      })

    },

    destroyRow(item,type)
    {
      this.destroyModal.payload.id = item.id
      this.destroyModal.payload.type = type
      this.destroyModal.visible = true
    },
    destroyAction()
    {
      if (this.destroyModal.payload.type === 'credit')
      {
        this.creditDestroy()
      }else if(this.destroyModal.payload.type==='payment')
      {
        alert('payment')
      }
    },


  },
  mounted() {
    this.getDataFromAPI()
  }
}
</script>

<style scoped>

</style>