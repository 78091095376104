<template>
  <v-container fluid>


    <v-toolbar flat color="transparent">
      <v-toolbar-title>
        <h2 v-if="$route.params.id" :class="$vuetify.theme.isDark ? 'white--text' : ''">
          Orden
        </h2>
      </v-toolbar-title>
      <v-spacer/>
    </v-toolbar>

    <v-card>
      <v-card-text>
        <v-row>

          <v-col cols="6">
            <v-row>
              <v-col>
                <v-btn icon color="green" v-if="1===2">
                  <v-icon>mdi-cached</v-icon>
                </v-btn>
                <strong>Terminal</strong><h6>{{ payload.user.name }}</h6>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="6">
            <v-row>
              <v-col>
                <strong>Sucursal</strong><h6>{{ payload.branch.name }}</h6>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>


    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" md="12">
            FOLIO: {{ payload.number }}
            <v-chip color="orange" text-color="white">{{ orderStatus }}</v-chip>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn fab small elevation="0" color="primary" @click="getPDF()">
              <v-icon>mdi-file-pdf-box-outline</v-icon>
            </v-btn>


          </v-col>
        </v-row>

      </v-card-title>

      <v-card-text>
        <v-data-table :items="payload.lines" :headers="headers" hide-default-footer>
          <template v-slot:item.line_amount="{item}">
            {{ (item.amount * item.quantity).toFixed(2) }}
          </template>


        </v-data-table>

        <v-row>
          <v-col cols="12" md="8"></v-col>
          <v-col align="right">
            <v-row>
              <v-col align="left">
                <strong>Subtotal:</strong>
              </v-col>
              <v-col align="left">${{ payload.subtotal }}</v-col>
            </v-row>



            <v-row>
              <v-col align="left">
                <strong>Total:</strong>
              </v-col>
              <v-col align="left">${{ payload.total }}</v-col>
            </v-row>




          </v-col>
        </v-row>
      </v-card-text>


<!--      <v-card-actions v-if="payload.status==='authorized' || payload.status==='created'">-->
<!--        <v-col align="left">-->
<!--          <v-btn color="primary" @click="dialogShow('reserve')">-->
<!--            Reservar-->
<!--          </v-btn>-->
<!--        </v-col>-->
<!--        <v-col align="right">-->
<!--          <v-btn v-if="payload.status !== 'created'" color="secondary" @click="dialogShow('reject')">-->
<!--            Rechazar-->
<!--          </v-btn>-->
<!--        </v-col>-->
<!--      </v-card-actions>-->


<!--      <v-card-actions v-if="payload.status==='reserved'">-->
<!--        <v-col align="left">-->
<!--          <v-btn color="primary" @click="dialogShow('process')">-->
<!--            Procesar-->
<!--          </v-btn>-->
<!--        </v-col>-->
<!--        <v-col align="right">-->
<!--          <v-btn color="secondary" @click="dialogShow('unReserve')">-->
<!--            Anular Reserva-->
<!--          </v-btn>-->
<!--        </v-col>-->
<!--      </v-card-actions>-->


    </v-card>


    <!--    Dialog -->
    <v-dialog max-width="400" v-model="modal.visible">
      <v-card>
        <v-card-title>
          {{ modal.title }}
        </v-card-title>
        <v-card-text>
          {{ modal.message }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn rounded color="primary" text @click="modal.visible = false">
            Mejor no
          </v-btn>

          <v-btn elevation="0" class="pa-4" rounded color="error" @click="dialogAction(modal.action)">
            {{ modal.textAction }}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!--End Dialog-->

    <!-- Change User Dialog   -->

    <v-dialog v-model="changeResellerModal.visible" max-width="400">
      <v-card>
        <v-card-title>
          <h5>Cambiar distribuidor</h5>
        </v-card-title>
        <v-card-text>
          <v-autocomplete label="Distribuidor" :items="changeResellerModal.resellers" item-value="id" item-text="name" v-model="changeResellerModal.payload.reseller_id"></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-row>

            <v-col align="right">
              <v-btn color="secondary" @click="changeResellerModal.visible=false">Cancelar</v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Change User DIalog   -->

<!--    Start Coupon Modal -->
    <v-dialog v-model="couponModal.visible" max-width="700">
      <v-card>
        <v-card-title>
          <h5>Aplicar cupón</h5>
        </v-card-title>
        <v-card-text>
          <v-autocomplete :items="couponModal.coupons" item-value="id" item-text="code" label="Cupón" v-model="couponModal.payload.coupon_id"></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col><v-btn color="primary" v-if="couponModal.payload.coupon_id" @click="applyCoupon">Aplicar</v-btn></v-col>
            <v-col align="right"><v-btn color="secondary" @click="couponModal.visible=false">Mejor No!!</v-btn></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
<!--    End Coupon Modal-->

  </v-container>


</template>

<script>

export default {

  data() {

    return {
      formUpdated: false,
      validForm: false,
      emptyRule: [v => !!v || 'Necesario'],
      chipStatus: null,
      modal: {
        visible: false,
        title: null,
        message: null,
        textAction: null,
        action: null
      },
      changeResellerModal:
          {
            visible: false,
            resellers:[],
            payload : {
              reseller_id:null
            }
          },

      couponModal:
          {
            visible:false,
            coupons:[],
            payload:{},
          },
      headers: [
        {
          text: 'Cantidad',
          align: 'left',
          value: 'quantity',
        },
        {
          text: 'Producto',
          align: 'left',
          value: 'description',
        },

        {
          text: 'Precio Base',
          align: 'left',
          value: 'price',
        },


        {
          text: 'Precio',
          align: 'left',
          value: 'amount',
        },

        {
          text: 'Importe',
          align: 'left',
          value: 'line_amount',
        },



      ],
      payload: {
        reseller: [],
        user: []
      }
    }
  },

  methods: {
    getDataFromAPI() {
      this.$axios
          .get(`admin/branchsaleorders/${this.$route.params.id}`)
          .then((response) => {
            this.payload = response.data.sale_order
            this.changeResellerModal.resellers = response.data.resellers
           // console.log(response.data)
          })
    },



    dialogShow($action) {
      switch ($action) {
        case 'authorize':
          this.modal.title = 'Autorizar'
          this.modal.message = 'Esta seguro que desea autorizar este pedido? Al autorizar la orden acepta la compra del producto asi como los cargos establecidos en la orden'
          this.modal.textAction = ' SI, LO AUTORIZO'
          this.modal.action = $action
          this.modal.visible = true
          break;

        case 'reserve':
          this.modal.title = 'Reservar'
          this.modal.message = 'Esta seguro que desea reservar este pedido? la mercancia se marcara como reservada en inventario y dejara de estar disponible para venta'
          this.modal.textAction = ' SI, RESERVAR'
          this.modal.action = $action
          this.modal.visible = true
          break;

        case 'reject':
          this.modal.title = 'Rechazar pedido'
          this.modal.message = 'Esta seguro que desea rechazar el pedido? esta accion es irreversible por lo que sera necesario ingresar el pedido de nuevo'
          this.modal.textAction = ' SI, RECHAZAR'
          this.modal.action = $action
          this.modal.visible = true
          break;

        case 'unReserve':
          this.modal.title = 'Revertir reserva'
          this.modal.message = 'Esta seguro que desea anular la reserva?'
          this.modal.textAction = ' SI, ANULAR'
          this.modal.action = $action
          this.modal.visible = true
          break;

        case 'process':
          this.modal.title = 'Procesar'
          this.modal.message = 'Esta seguro que desea procesar el pedido?:  La mercancia dejara de estar reservada, se confirmara la salida de almacen y se generara el cargo' +
              ' al cliente por este pedido'
          this.modal.textAction = ' SI, PROCESAR'
          this.modal.action = $action
          this.modal.visible = true
          break;

      }

    },
    dialogAction($action) {
      switch ($action) {
        case 'authorize':
          this.payload.status = 'authorized';

          //alert(this.payload.estatus);
          break;
        case 'reserve':
          this.reserveOrder();
          break;
        case 'reject':
          this.rejectOrder();
          break;
        case 'unReserve':
          this.unReserveOrder()
          break;
        case 'process':
          this.processOrder()
          break;
      }
      this.modal.visible = false;

    },
    reserveOrder() {
      let formData = new FormData();

      // Loops the payload and inserts it inside formData

      this.$axios
          .post('admin/saleorder/reserve', {'id': this.payload.id})
          .then((response) => {
            //console.log('data',response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.getDataFromAPI() : '')
          })
          .catch((error) => {
            //console.log(error);
          });
    },

    rejectOrder() {
      let formData = new FormData();

      // Loops the payload and inserts it inside formData

      this.$axios
          .post('admin/saleorder/reject', {'id': this.payload.id})
          .then((response) => {
            //console.log('data',response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.getDataFromAPI() : '')
          })
          .catch((error) => {
            //console.log(error);
          });
    },

    unReserveOrder() {
      let formData = new FormData();

      // Loops the payload and inserts it inside formData

      this.$axios
          .post('admin/saleorder/unreserve', {'id': this.payload.id})
          .then((response) => {
            //console.log('data',response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.getDataFromAPI() : '')
          })
          .catch((error) => {
            //console.log(error);
          });
    },
    processOrder() {
      let formData = new FormData();

      // Loops the payload and inserts it inside formData

      this.$axios
          .post('admin/saleorder/process', {'id': this.payload.id})
          .then((response) => {
            //console.log('data',response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.getDataFromAPI() : '')
          })
          .catch((error) => {
            //console.log(error);
          });
    },
    async getPDF() {
      await
          this.$axios
              .get('admin/saleorder/download/' + this.payload.id, {responseType: 'blob'})
              .then((response) => {
                //console.log(response)
                let url = window.URL.createObjectURL(new Blob([response.data]));
                let link = document.createElement('a')
                link.href = url
                link.setAttribute('download', this.payload.number + '.pdf');
                document.body.appendChild(link)
                link.click()
              })

    },
    orderUpdated() {
      //console.log(this.payload.lines);
      let self = this;
      let updatedRows = 0
      this.payload.lines.forEach(function (line) {
        updatedRows += (line.unavailable) ? 1 : 0
        self.formUpdated = (updatedRows > 0)
        self.formUpdated = (updatedRows != self.payload.lines.length && updatedRows > 0)

      })

    },


  },
  computed: {

    orderStatus() {
      switch (this.payload.status) {
        case 'created':
          return 'Esperando a sucursal';
        case 'authorized':
          return 'Autorizado';
        case 'reserved':
          return 'Reservado';
        case 'rejected':
          return 'Rechazado';
        case 'processed':
          return 'Procesado';
        case 'transit':
          return 'En transito';
        case 'delivered':
          return 'Entregado';
        case 'cancel':
          return 'Cancelado';

      }
    }
  },
  mounted() {
    this.getDataFromAPI();
  }
}

</script>

<style scoped>

</style>