<template>
  <v-container fluid>

    <v-col cols="12">

      <v-row align="center" justify="space-between">
        <v-col cols="12" md="4">
          <h1 class="mb-0"
              :class="$vuetify.theme.isDark ? 'white--text' : ''" >
            Ordenes de Pago
          </h1>
        </v-col>

        <v-col cols="8" md="7">

        </v-col>

<!--        <v-col cols="4" md="1">-->
<!--          <v-row justify="center">-->
<!--            <v-btn fab small elevation="0" color="primary"-->
<!--                   @click="create()">-->
<!--              <v-icon>mdi-plus</v-icon>-->
<!--            </v-btn>-->
<!--          </v-row>-->
<!--        </v-col>-->

      </v-row>
    </v-col>


    <v-card>
      <v-card-text>
        <v-row class="elevated-3">
          <v-col>
            <v-col xs="12" md="12" sm="12">

              <v-data-table :headers="headers" :items="sortedItems" disable-sort>

                <template v-slot:item.actions="{item}">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-list>

                      <v-list-item v-if="item.status === 'created'" @click="edit(item.id)">
                        <v-list-item-title>
                          <v-icon small class="mr-2"> mdi-cash </v-icon>
                          Registrar Pago
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>

              </v-data-table>

            </v-col>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-text>
        <v-row >
          <v-col align="center"
              class="display-1"
          >
            Total: ${{ total }}
          </v-col>

        </v-row>
      </v-card-text>

    </v-card>

    <v-dialog max-width="400" v-model="showDialog">
      <v-card>
        <v-card-title>Registrar Pago</v-card-title>
        <v-card-text>
          <v-form class="pa-0" ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col><v-autocomplete :rules="emptyRule" label="Distribuidor" :items="resellers" item-text="name" item-value="id" v-model="payload.reseller_id"></v-autocomplete></v-col>
          </v-row>

          <v-row>
            <v-col><v-select :rules="emptyRule" label="Metodo de pago" :items="paymentMethods" v-model="payload.payment_method" ></v-select></v-col>
          </v-row>

            <v-row>
              <v-col><v-text-field label="Referencia" v-model="payload.reference"></v-text-field></v-col>
            </v-row>

          <v-row>
            <v-col><v-text-field :rules="emptyRule" type="number" label="Importe" v-model="payload.amount"></v-text-field></v-col>
          </v-row>

          <v-row><v-alert color="warning">Por seguridad de nuestro distribuidor los pagos no pueden ser modificados o cancelados. Por favor revise la información</v-alert></v-row>
        <v-row><v-col><v-checkbox label="He corroborado que los datos ingresados son correctos" v-model="modalChecked"/></v-col></v-row>
          </v-form>
        </v-card-text>


        <v-card-actions>
          <v-col align="left"><v-btn color="primary" v-if="modalChecked" @click="validateForm">Recibir Pago</v-btn></v-col>
          <v-col align="right"><v-btn color="error" @click="showDialog=false">Cancelar</v-btn></v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
export default {
  data() {
    return {
      validForm: true,
      emptyRule: [v => !!v || 'Necesario'],
      showDialog:false,
      modalChecked:false,
      resellers:null,
      paymentMethods:null,
      payload:{
        'reseller_id':null,
        'payment_method':'cash',
        'amount':'0.00',
      },

      items: [],
      total:0.00,
      search: '',
      headers: [{
        text: 'ID',
        align: 'center',
        value: 'id',
      },
        {
          text: 'Cliente',
          align: 'center',
          value: 'customer_name',
        },

        {
          text: 'Importe',
          align: 'center',
          value: 'amount'
        },

        {
          text: 'Fecha',
          align: 'center',
          value: 'date'
        },
        {
          text: 'Acciones',
          align: 'center',
          value: 'actions'
        }
      ]
    };
  },
  computed: {
    sortedItems(){
      return this.items.sort((a, b) => (a.id < b.id) ? 1 : -1)
    }
  },
  methods: {


    clearForm(){
      this.payload={
        'reseller_id':null,
        'payment_method':'cash',
        'amount':'0.00',}
    },

    edit(id) {

      this.$router.push('/payment/edit/' + id);

    },




    getDataFromAPI() {
      this.$axios
          .get('driver/payments')
          .then((response) => {
            this.items = response.data.payments
            this.total = response.data.total

          })
    }
  },
  mounted() {
    this.getDataFromAPI()
  }
}
</script>