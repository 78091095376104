<template>
  <v-container fluid>
    <v-form class="pa-0" ref="form" v-model="validForm" lazy-validation>

      <v-toolbar flat color="transparent">

        <v-toolbar-title>
          <h2 v-if="!$route.params.id" :class="$vuetify.theme.isDark ? 'white--text' : ''">
            Crear Categoría
          </h2>

          <h2 v-else :class="$vuetify.theme.isDark ? 'white--text' : ''">
            <v-btn fab small elevation="0" color="secondary" @click="showLog()">
              <v-icon>mdi-post</v-icon>
            </v-btn>  Editar Categoría
          </h2>

        </v-toolbar-title>

        <v-spacer />

        <v-btn :disabled="!validForm" class="mx-1" elevation="0" color="primary" rounded @click="validateForm">
          Guardar
        </v-btn>

        <v-btn class="mx-1" elevation="0" color="error" rounded v-if="$route.params.id" @click="destroyDialog = true">
          Eliminar
        </v-btn>

      </v-toolbar>


      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title class="pb-2">
              <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
                <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-clipboard-text</v-icon>
                Descripción
              </h4>
            </v-card-title>

            <v-card-text>
              <v-col>
                <v-select :rules="emptyRule" hide-details dense outlined label="Genero"
                  v-model="payload.product_gender_id" :items="product_genders" item-value="id" item-text="name">
                </v-select>
              </v-col>

              <v-col>
                <v-select :rules="emptyRule" hide-details dense outlined label="Tipo de producto *"
                  v-model="payload.product_type_id" :items="product_types" item-value="id" item-text="name">
                </v-select>
              </v-col>

              <v-col>
                <v-text-field :rules="emptyRule" hide-details dense outlined v-model="payload.name" label="Nombre *">
                </v-text-field>
                <!-- <v-switch v-model="payload.enabled" label="Activo"></v-switch> -->
              </v-col>

              <v-col cols="12" class="px-6 py-0">
                <v-row align="center" justify="center">

                  <h4 class="mb-0 pr-3"  :class="$vuetify.theme.isDark ? 'white--text' : ''">Activo:</h4>
                  <v-switch :color="$vuetify.theme.isDark ? 'primary' : 'secondary'" v-model="payload.enabled">
                  </v-switch>

                  <h4 class="mb-0 pr-3" :class="$vuetify.theme.isDark ? 'white--text' : ''">Menu:</h4>
                  <v-switch :color="$vuetify.theme.isDark ? 'primary' : 'secondary'" v-model="payload.in_store_menu">
                  </v-switch>
                </v-row>
              </v-col>


            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title>
              <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
                <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-image</v-icon>
                Imagen
              </h4>
            </v-card-title>
            <v-card-text>
              <v-row>

                <v-col v-if="photos.photo.url">
                  <v-img :src="photos.photo.url" :width="192" :height="106">
                    <v-btn @click="photos.photo = {}; payload.photo = null" fab small class="ma-2" elevation="0" dark>
                      <v-icon>
                        mdi-close
                      </v-icon>
                    </v-btn>
                  </v-img>
                </v-col>


                <v-col v-else>
                  <v-file-input class="px-4" v-model="payload.photo" @change="getImage($event,'photo')" accept="image/*"
                    label="Imagen" outlined />
                </v-col>


              </v-row>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>


      <v-card class="elevation-3">
        <v-card-title>
          <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
            <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-image-area</v-icon>
            Banner
          </h4>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col v-if="photos.banner && photos.banner.url">
              <v-img :src="photos.banner.url" :width="1440" :height="597">
                <v-btn @click="photos.banner = {}; payload.banner = null" fab small class="ma-2" elevation="0" dark>
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-img>
            </v-col>

            <v-col v-else>
              <v-file-input v-model="payload.banner" accept="image/*" @change="getImage($event,'banner')" label="Banner"
                outlined />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-divider></v-divider>


      <v-dialog
        v-model="destroyDialog"
        max-width="400"
      >
        <v-card>
          <v-card-title class="headline">¿Estas seguro?</v-card-title>

          <v-card-text>
            Una vez elimines el registro, no podrás recuperarlo.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
            rounded 
              color="primary"
              text
              @click="destroyDialog = false"
            >
              Mejor no
            </v-btn>

            <v-btn
            elevation="0"
            class="pa-4"
            rounded
              color="error"
              @click="destroy"
            >
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <logDialog ref="logDialog"></logDialog>

    </v-form>
  </v-container>
</template>


<script>
import logDialog from '../commons/LogViewer'
  export default {
  components:{
    logDialog
  },
    data() {
      return {
        destroyDialog: false,
        validForm: true,
        emptyRule: [v => !!v || 'Necesario'],
        payload: {
          id: null,
          name: null,
          product_gender_id: null,
          product_type_id: null,
          enabled: true,
          in_store_menu:true,
          photo: null,
          banner: null
        },
        photos: {
          photo: {},
          banner: {},
        },
        product_genders: [],
        product_types: []
      }
    },
    methods: {
      validateForm() {
        if (this.$refs.form.validate()) {
          (this.$route.params.id ? this.update() : this.store())
        }
      },

      create() {
        this.$axios
          .get('/admin/categories/create')
          .then((response) => {
            this.product_genders = response.data.genders
            this.product_types = response.data.types
          })
      },

      edit() {
        this.$axios
          .get(`/admin/categories/${this.$route.params.id}/edit`)
          .then((response) => {
            // console.log(response.data)
            this.product_genders = response.data.genders
            this.product_types = response.data.types
            this.payload = response.data.item
            this.photos = response.data.photos
          })
      },

      store() {
        let formData = new FormData();

        // Loops the payload and inserts it inside formData
        Object.entries(this.payload).forEach(([key, value]) => {
          formData.append(key, value)
        })

        // Display the key/value pairs of FormData
        // for (let pair of formData.entries()) {
        //   console.log(pair[0] + ', ' + pair[1]);
        // }

        this.$axios
          .post('/admin/categories', formData)
          .then((response) => {

            this.$store.commit( 'OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/categories') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
      },

      update() {
        let formData = new FormData();

        // Loops the payload and inserts it inside formData
        Object.entries(this.payload).forEach(([key, value]) => {
          formData.append(key, value)
        })

        // Sends photos
        formData.append('photos', JSON.stringify(this.photos))

        // Laravel put workaround
        formData.append('_method', 'PUT')

        this.$axios
          .post(`/admin/categories/${this.$route.params.id}`, formData)
          .then( (response)=>{
            // console.log(response.data)
            this.$store.commit( 'OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/categories') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
      },

      destroy() {
        let formData = new FormData();
        
        // Laravel delete workaround
        formData.append('_method', 'DELETE')

        this.$axios
          .post(`/admin/categories/${this.$route.params.id}`, formData)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit( 'OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/categories') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
      },

      getImage(e, imageType) {
        // console.log(e)
        // console.log(imageType)
        let file = e
        if (file) {
          this.loadImage(file, imageType)
        } else {
          if (imageType == 'photo') {
            this.photos.photo.url = null
          } else if (imageType == 'banner') {
            this.photos.banner.url = null
          }
        }
      },

      loadImage(file, imageType) {
        let reader = new FileReader()
        reader.onload = (e) => {
          if (imageType == 'photo') {
            this.photos.photo = {}
            this.photos.photo.url = e.target.result
            // console.log(this.photos)
          } else if (imageType == 'banner') {
            this.photos.banner = {}
            this.photos.banner.url = e.target.result
          }
        }
        reader.readAsDataURL(file)
      },
      showLog(){
        this.$refs.logDialog.getModelLogFromAPI('App\\Models\\ProductCategory',this.$route.params.id)
      }

    },

    mounted() {
      if (this.$route.params.id) {
        this.edit()
      } else {
        this.create()
      }
    }
  }
</script>