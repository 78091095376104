var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-row',{attrs:{"align":"center","justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('h1',{staticClass:"mb-0"},[_vm._v(" Productos ")])]),_c('v-col',{attrs:{"cols":"8","md":"7"}},[_c('v-text-field',{attrs:{"hide-details":"","dense":"","outlined":"","flat":"","append-icon":"mdi-magnify","label":"Buscar..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"4","md":"1"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"fab":"","small":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.createItem()}}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1)],1)],1)],1),_c('v-card',[_c('v-card-text',[_c('v-row',{staticClass:"elevated-3"},[_c('v-col',[_c('v-col',{attrs:{"xs":"12","md":"12","sm":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","x-small":"","color":"warning","dark":"","depressed":""},on:{"click":function($event){return _vm.editItem(item.id)}}},on),[_c('v-icon',[_vm._v("mdi-dots-horizontal")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar")])])]}},{key:"item.colors",fn:function(ref){
var item = ref.item;
return [(item.colors[0])?_c('v-row',{attrs:{"justify":"center"}},_vm._l((item.colors.filter(function (e) { return e.enabled; })),function(color,index){return _c('v-icon',{key:index,attrs:{"small":"","large":"","color":color.code}},[_vm._v("mdi-checkbox-blank-circle")])}),1):_vm._e()]}},{key:"item.cost",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(("" + (_vm.formatPrice(item.cost)))))])]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(("" + (_vm.formatPrice(item.price)))))])]}},{key:"item.enabled",fn:function(ref){
var item = ref.item;
return [(item.enabled)?_c('v-icon',{attrs:{"large":"","color":"success"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"large":"","color":"grey"}},[_vm._v("mdi-close-circle-outline")])]}}])})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }