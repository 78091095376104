<template >
  <v-app id="v-app" :dark="appConfig.dark">

    <BaseToolbar/>
    <SidebarBasic/>
    
    <v-main class="v-content__wrap">
      <vue-page-transition name="fade-in-down" class="pa-5">
        <router-view />
      </vue-page-transition>
      <BaseFooter/>
    </v-main>
    <Snackbar/>
  </v-app>
</template>



<script>
  import BaseToolbar from "@/components/admin/Base/BaseToolbar.vue";
  import SidebarBasic from "@/components/admin/Sidebar/SidebarBasic.vue";
  import BaseFooter from "@/components/admin/Base/BaseFooter.vue";
  import Snackbar from "@/components/admin/Base/Snackbar.vue";

  export default {
    components: {
      BaseToolbar,
      SidebarBasic,
      BaseFooter,
      Snackbar
    },
    data() {
      return {

        // 
      };
    }
  };
</script>

<style>
  .setting-fab {
    top: 50% !important;
    right: 0 !important;
    margin-top: 50% !important;
    border-radius: 10px !important;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }

</style>