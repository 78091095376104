/**
 * menu() returns menu json the order
 * @param <String> tag
 * @return <Element> element
 */
const Menu = [{
        title: "Tablero",
        action: "icon icon-home",
        path: "dashboard",
    },
    {
        title: "Catalogos",
        action: "icon ion-ios-gear",
        items: [
            {
                path: "categories",
                action: "ti-cloud",
                title: "Categorías",
                color: "blue",
                group: "charts"
            },
            {
                path: "materials",
                action: "ti-cloud",
                title: "Materiales",
                color: "blue",
                group: "charts"
            },            
            {
                path: "colors",
                action: "ti-cloud",
                title: "Colores",
                color: "blue",
            },
            {
                path: "brands",
                action: "ti-cloud",
                title: "Marcas",
                color: "blue",
                group: "charts"
            },

            {
                path: "size-collections",
                action: "ti-cloud",
                title: "Corridas",
                color: "blue",
                group: "charts"
            },            

            // ***

            {
                path: "products",
                action: "ti-cloud",
                title: "Productos",
                color: "blue",
            },

            {
                path: "routes",
                action: "ti-cloud",
                title: "Rutas",
                color: "blue",
            },
            {
                path: "localities",
                action: "ti-cloud",
                title: "Localidades",
                color: "blue",
            },


        ]
    },
    {
        title: "Usuarios",
        action: "icon ion-ios-people",
        items: [
            {path:"users",
                action:"ti-cloud",
                title:"Usuarios",
                color: "blue",
                group: "charts"
            },

        ]},

    {
        title: "Tienda",
        action: "icon mdi-cart-outline",
        items: [
            {
                path: "sliders",
                action: "ti-cloud",
                title: "Slider",
                color: "blue",
                group: "charts"
            },

            {
                path: "branches",
                action: "ti-cloud",
                title: "Sucursales",
                color: "blue",
                group: "charts"
            }
        ]},


    {
        title: "Configuracion",
        action: "icon ion-ios-gear",
        items: [
            {
                path: "tableprice",
                action: "ti-cloud",
                title: "Tabla de Descuentos",
                color: "blue",
                group: "charts"
            },
            {
                path: "pricefactors",
                action: "ti-cloud",
                title: "Factores de Precios",
                color: "blue",
                group: "charts"
            }
    ]},

    {
        title: "Almacen",
        action: "icon mdi-package-variant",
        items: [
            {
                path: "warehouses",
                action: "ti-cloud",
                title: "Almacenes",
                color: "blue",
                group: "charts"
          
            },
            {
                path: "stockoperations",
                action: "ti-cloud",
                title: "Operaciones",
                color: "blue",
                group: "charts"
            }
        ]},
    

    {
        title: "Ventas",
        action: "icon mdi-point-of-sale",
        items: [
            {
                path: "orders",
                action: "ti-cloud",
                title: "Pedidos",
                color: "blue",
                group: "charts"
            },
            
            {
                path: "orders/supply",
                action: "ti-cloud",
                title: "Pedidos por surtir",
                color: "blue",
                group: "charts"
            },
            
            {
                path: "branch/orders",
                action: "ti-cloud",
                title: "Pedidos de tienda",
                color: "blue",
                group: "charts"
            },


        ]},
    {
        title: "Finanzas",
        action: "icon mdi-cash-multiple",
        items: [


            {
                path: "paymentorders",
                action: "ti-money",
                title: "Ordenes de pago",
                color: "blue",
                group: "charts"
            },
            {
                path: "returns",
                action: "ti-money",
                title: "Devoluciones",
                color: "blue",
                group: "charts"
            },

            {
                path: "coupons",
                action: "ti-money",
                title: "Cupones",
                color: "blue",
                group: "charts"
            },



        ]},

    {
        title: "Reportes",
        action: "icon mdi-file-document",
        items: [


            {
                path: "report/stock",
                action: "ti-money",
                title: "Stock",
                color: "blue",
                group: "charts"
            },

            {
                path: "report/unavailable",
                action: "ti-money",
                title: "Sin disponibilidad",
                color: "blue",
                group: "charts"
            },
            {
                path: "report/payments",
                action: "ti-money",
                title: "Pagos",
                color: "blue",
                group: "charts"
            },



        ]},




];




Menu.forEach(item => {
    if (item.items) {
        item.items.sort((x, y) => {
            let textA = x.title.toUpperCase();
            let textB = y.title.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    }
});

export default Menu;