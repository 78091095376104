<!--=================================
=            BASETOOLBAR            =
==================================-->
<template>
  <div>
    <v-app-bar nav
      clipped-right
      :clipped-left="appConfig.clipped"
      class="elevation-10"
      :dark="appConfig.dark"
      app
    >
      <v-app-bar-nav-icon
        :dark="appConfig.dark"
        v-if="appConfig.type !== 'permanent'"
        @click.stop="appConfig.model = !appConfig.model"
      >
        <v-btn  :dark="appConfig.dark" icon>
          <v-icon>mdi-view-headline</v-icon>
        </v-btn>
      </v-app-bar-nav-icon>
      <v-spacer></v-spacer>
      <a href="http://genscatalogo.com">
      <v-btn
             color="blue-grey"
             class="ma-2 white--text"
             dark

      >
        Ir a Tienda  <v-icon right
      >
        mdi-cart
      </v-icon></v-btn>
      </a>
      <v-spacer></v-spacer>
      <starter-avatar></starter-avatar>
      <v-app-bar-nav-icon :dark="appConfig.dark"
                          fab
                          small
                          @click.stop="appConfig.drawerRight = !appConfig.drawerRight">
        <i class="fa fa-cog fa-spin fa-2x"></i>
      </v-app-bar-nav-icon>
    </v-app-bar>
    <v-navigation-drawer class="setting-drawer"
                         v-model="appConfig.drawerRight"
                         app
                         clipped
                         right>
      <!-- APPsETTINGS -->
      <starter-settings></starter-settings>
    </v-navigation-drawer>

  </div>
</template>
<script>

  import StarterSettings from "../Starter/StarterSettings.vue";
  import StarterAvatar from "../Starter/StarterAvatar.vue";
  export default {
    name: "base-toolbar",
    components: {

      StarterAvatar,
      StarterSettings
    },
    data: () => ({
      searchActive: false,

      sheet: false,

      items: [{
          text: "Real-Time",
          icon: "mdi-clock"
        },
        {
          text: "Audience",
          icon: "mdi-account"
        },
        {
          text: "Conversions",
          icon: "mdi-flag"
        }
      ]
    })
  };
</script>
<style scoped></style>



<!--====  End of BASETOOLBAR  ====-->