

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'vue-material/dist/vue-material.min.css'
import VuePageTransition from 'vue-page-transition';



import Vue from "vue";
import App from "@/App";
import VueMaterial from 'vue-material';
import BootstrapVue from "bootstrap-vue";

// Plugins
import core from "@/api/core.js";
import vuetify from "@/plugins/vuetify";
import axios from "@/plugins/axios";
import store from "@/plugins/store";
import routerAdmin from "@/plugins/admin/router";
import routerReseller from "@/plugins/reseller/router";
import routerDriver from "@/plugins/driver/router";
import routerBranch from "@/plugins/branch/router";

Vue.use(axios);
Vue.use(core);
Vue.use(BootstrapVue);
Vue.use(VueMaterial)
Vue.use(VuePageTransition);


import "@/theme/main.css"
Vue.config.productionTip = false;


let router;
store.dispatch('getUser').then(() => {

  if(store.state.user.data.type==='staff'){
    if(store.state.user.data.kiosko )
    {
      router = routerBranch
    }else{
      router = routerAdmin
    }
  }else if (store.state.user.data.type === 'reseller'){
    router = routerReseller
  }else if (store.state.user.data.type === 'driver'){
    router = routerDriver
  }

  new Vue({
    router,
    store,
    vuetify,

    render: h => h(App)
  }).$mount("#app");

})
.catch(()=>{
  window.location.href = process.env.VUE_APP_BACKEND_URL + '/login';
})