<template>
  <v-container fluid>

    <v-col cols="12">

      <v-row align="center" justify="space-between">
        <v-col cols="12" md="4">
          <h1 class="mb-0"
              :class="$vuetify.theme.isDark ? 'white--text' : ''" >
            Localidades
          </h1>
        </v-col>

        <v-col cols="8" md="7">
          <v-text-field hide-details dense outlined flat v-model="search" append-icon="mdi-magnify"
                        label="Buscar...">
          </v-text-field>
        </v-col>

        <v-col cols="4" md="1">
          <v-row justify="center">
            <v-btn fab small elevation="0" color="primary" @click="createItem()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-col>


    <v-card>
      <v-card-text>
        <v-row class="elevated-3">
          <v-col>
            <v-col xs="12" md="12" sm="12">

              <v-data-table :headers="headers" :items="sortedItems" :search="search">

                <template v-slot:item.enabled="{item}">
                  <v-icon v-if="item.enabled" large color="success" >mdi-check-circle-outline</v-icon>
                  <v-icon v-else large color="grey" >mdi-close-circle-outline</v-icon>
                </template>

                <template v-slot:item.actions="{item}">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn class="mx-1" fab x-small color="warning" dark depressed
                             @click="editItem(item)" v-on="on">
                        <v-icon >mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>

              </v-data-table>

            </v-col>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
    <v-dialog max-width="400" persistent  v-model="modal.visible">
    <v-card>
      <v-form class="pa-0" ref="form" v-model="validForm" lazy-validation>

      <v-card-title>
        <v-row align="center">
          <v-col cols="12" class="text-end py-0">
            <v-row justify="end">
              <v-col align="left">
                <span v-if="!modal.payload.id">Crear Localidad</span>
                <span v-else>Editar Localidad</span></v-col>
              <v-col xs="4" md="2" lg="2" class="py-0">
                <v-btn
                        fab
                        icon
                        depressed
                        color="error"
                        @click="modal.visible = false">
                  <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>


      </v-card-title>

      <v-card-text>
        <v-row>

          <v-col cols="12" md="12">
            <v-text-field :rules="emptyRule" v-model="modal.payload.name" hide-details dense outlined label="Nombre"></v-text-field>
          </v-col>
          <v-col cols="12" md="12">
            <v-select :rules="emptyRule" :items="routeList" item-value="id" item-text="name" v-model="modal.payload.delivery_route_id" hide-details dense outlined label="Ruta"></v-select>
          </v-col>
          <v-col>
          <v-switch v-model="modal.payload.enabled" label="Activo"></v-switch>
          </v-col>

        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!validForm" color="primary" @click="validateForm">Guardar</v-btn>
        <v-col align="right"> <v-btn color="secondary" @click="modal.visible=false">Cancelar</v-btn></v-col>
      </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>

  </v-container>
</template>

<script>
export default {
  data() {
    return {
      items: [],
      search: '',
      headers: [{
        text: 'ID',
        align: 'center',
        value: 'id',
      },
        {
          text: 'Nombre',
          align: 'center',
          value: 'name',
        },

        {
          text: 'Ruta',
          align: 'center',
          value: 'delivery_route.name',
        },

        {
          text: 'Activo',
          align: 'center',
          value: 'enabled'
        },

        {
          text: 'Acciones',
          align: 'center',
          value: 'actions'
        }
      ],
      routeList:[],
      modal: {
        visible:false,
        payload: {
          name: null,
          enabled:1,
          delivery_route_id:null
        },
      },
      validForm: true,
      emptyRule: [v => !!v || 'Necesario'],
    };
  },
  computed: {
    sortedItems(){
      return this.items.sort((a, b) => (a.id < b.id) ? 1 : -1)
    }
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        (this.modal.payload.id ? this.update() : this.store())
      }
    },
    createItem() {
      this.modal.payload={enabled:1};
      // this.modal.payload.name=''
      // this.modal.payload.enabled=1
      this.modal.visible=true
    },
    store()
    {

      this.$axios
          .post('/admin/deliveryrouteslocalities', this.modal.payload)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.modal.visible=false : '')
            this.getDataFromAPI()
          })
          .catch((error)=>{

          });
    },

    editItem(item) {
      this.modal.payload=item
      this.modal.visible=true
    },

    update() {
      let formData = new FormData();

      // Loops the payload and inserts it inside formData
      Object.entries(this.modal.payload).forEach(([key, value]) => {
        formData.append(key, value)
      })

      // Laravel put workaround
      formData.append('_method', 'PUT')

      this.$axios
          .post(`/admin/deliveryrouteslocalities/${this.modal.payload.id}`, formData)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.modal.visible=false : '')
            this.getDataFromAPI()
          })
          .catch((error)=>{
            console.log(error);
          });
    },


    getDataFromAPI() {
      this.$axios
          .get('/admin/deliveryrouteslocalities')
          .then((response) => {
            //console.log(response)
            this.items = response.data.delivery_route_localities
            this.routeList = response.data.delivery_routes

          })
    }
  },
  mounted() {
    this.getDataFromAPI()
  }
}
</script>