<template>
<div>
  <v-dialog v-model="showDialog" max-width="600">
    <v-card class="elevation-3">
      <v-card-title>
        <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
          <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-post</v-icon>
          Log
        </h4>
      </v-card-title>
      <v-card-text>

        <v-row v-for="logitem in logitems">
          <v-col cols="6" md="4"><strong>{{ logitem.cusar_name }}</strong></v-col>
          <v-col cols="6" md="4"><strong>{{ trans(logitem.description) }}</strong></v-col>
          <v-col cols="6" md="4"><strong>{{ formatDate(logitem.created_at)}}</strong></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions><v-btn class="primary" @click="showDialog=false">Cerrar</v-btn></v-card-actions>
    </v-card>
  </v-dialog>
</div>
</template>

<script>
import moment from "moment";
moment.locale('America/Mexico_City')

export default {
data(){
  return{
    showDialog:false,
    logitems:[],



  }
},

  methods:{
    formatDate(value) {
      return moment(value).format("DD/MM/YYYY h:mm:ss a")
    },

    trans(string){
      switch (string){
        case 'created':
          return 'Nuevo Registro'

        case 'updated':
          return 'Registro Actualizado'

        case 'deleted':
          return 'Registro Eliminado'
      }

    },

    getModelLogFromAPI(model,modelId){
      this.showDialog=true
      let data = {'model': model, model_id: modelId}
      this.$axios
          .post(`/admin/log/model/`, data)
          .then((response) => {
            //console.log(response.data)
            this.logitems = response.data
          })
          .catch((error)=>{
            console.log(error);
          });
    }
  }
}
</script>

