<template>
  <v-container fluid>
    <v-tabs fixed-tabs height="47" class="elevation-3">
      <v-tab :href="'#statics'">
        <v-icon>bar_chart</v-icon>
        Estadisticas
      </v-tab>
      <v-tab :href="'#filters'">
        <v-icon>filter_list</v-icon>
        Filtros
      </v-tab>

      <v-tab-item :value="'statics'">
        <v-card>
          <v-card-text>

            <v-col align="center">
              <span class="subheading font-weight-medium success--text"> Total: ${{ total }} </span>
            </v-col>
          </v-card-text>
        </v-card>
      </v-tab-item>

      <v-tab-item :value="'filters'">
        <v-card>
          <v-card-text class="pt-0 pb-0">
            <v-col>
              <v-row dense>
                <v-col cols="12" md="1">
                  <v-switch
                      v-model="filters.last100"
                      label="< 100"
                  ></v-switch>
                </v-col>
                <v-col cols="12" md="3">
                  <v-autocomplete label="Usuario":items="users" item-text="name"
                                  item-value="id" v-model="filters.deliver_id"></v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete label="Distribuidor" :items="resellers" item-text="name"
                                  item-value="id" v-model="filters.reseller_id"></v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete label="Estatus" :items="status"  v-model="filters.status"></v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                  <v-autocomplete label="Metodos de pago" :items="paymentMethods"  v-model="filters.payment_method"></v-autocomplete>
                </v-col>
                <v-col cols="12" md="2">
                  <v-btn @click="getFilteredDataFromAPI" color="primary">Aplicar</v-btn>
                </v-col>

              </v-row>
            </v-col>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-card class="mt-3">
      <v-card-text>
        <v-row class="elevated-3">
          <v-col>
            <v-col xs="12" md="12" sm="12">



              <v-data-table :headers="headers" :items="sortedItems" disable-sort>
                <template v-slot:item.actions="{item}">
                <v-menu>
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-if="item.status == 'created'" @click="cancel(item)">
                      <v-list-item-title>
                        <v-icon small class="mr-2"> clear </v-icon>
                        Cancelar
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item v-if="item.status == 'created'" @click="markAsReceived(item)">
                      <v-list-item-title>
                        <v-icon small class="mr-2"> check </v-icon>
                        Recibir
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                </template>

                <template v-slot:item.status="{item}">
                  <StatusLabel :status="item.status"></StatusLabel>
                </template>

              </v-data-table>

            </v-col>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions v-if="this.showMarkAsReceived">
        <v-col><v-row><v-col align="right"><v-btn>Marcar como recibido</v-btn></v-col> </v-row></v-col>
      </v-card-actions>

    </v-card>


  </v-container>

</template>

<script>

import StatusLabel from "../../../components/helpers/statusLabel";
export default {
  components: {StatusLabel},
  data() {
    return {
      showMarkAsReceived:false,
     resellers: null,
      users:null,
      paymentMethods: [
        {"text":"TODOS","value":"all"},
        {"text": "Efectivo", "value": "cash"},
        {"text": "Tarjeta Bancaria", "value": "card"},
        {"text": "Cheque", "value": "check"},
        {"text": "Transferencia", "value": "transfer"},
        {"text": "CODI","value": "codi"}],
      status: [
        {"text":"TODOS","value":"all"},
        {"text":"Creado","value":"created"},
        {"text":"Recibido","value":"received"},
        {"text":"Cancelado","value":"canceled"}],
      filters: {
        last100: true,
        status: 'all',
        reseller_id: 0,
        deliver_id: 0,
        payment_method: 'all',
      },


      items: [],
      total: 0.00,
      search: '',
      headers: [

          {
        text: 'ID',
        align: 'center',
        value: 'id',
      },
        {
          text: 'Distribuidor',
          align: 'center',
          value: 'reseller_name',
        },

        {
          text: 'Chofer',
          align: 'center',
          value: 'deliver.name',
        },

        {
          text: 'Importe',
          align: 'center',
          value: 'amount'
        },

        {
          text: 'Fecha',
          align: 'center',
          value: 'date'
        },
        {
          text: 'Estatus',
          align: 'center',
          value: 'status'
        },
        {
          text: 'Acciones',
          align: 'center',
          value: 'actions',
        },
      ]
    };
  },
  computed: {
    sortedItems() {
      return this.items.sort((a, b) => (a.id < b.id) ? 1 : -1)
    },

  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        this.store()
      }
    },


    getDataFromAPI() {
      this.$axios
          .get('admin/deliverpayments')
          .then((response) => {
            this.items = response.data.payments
            this.total = response.data.total
            this.users = response.data.users
            this.resellers = response.data.resellers
          })
    },
    getFilteredDataFromAPI() {
      this.$axios
          .post('admin/deliverpayments/filter', this.filters)
          .then((response) => {
            this.items = response.data.payments
            this.total = response.data.total

          })
          .then((response)=> {
           // (this.filters.deliver_id > 0 && this.filters.status ==='created' && this.items.length > 0) ? this.showMarkAsReceived=true:this.showMarkAsReceived=false;

      })


    },
    markAsReceived(item)
    {
      alert('cancel' + item.id)
      this.$axios
          .post('admin/deliverpayments/markAsReceived', {'id': item.id})
          .then((response) => {

            this.$store.commit( 'OPEN_SNACKBAR', response.data);
            (response.data.success ? this.getFilteredDataFromAPI() : '')
          })

    },
    cancel(item){
      alert('cancel' + item.id)
    },
  },
  mounted() {
    this.getDataFromAPI()
  }
}
</script>