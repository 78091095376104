<template>
    <v-container fluid>

        <v-col cols="12">

            <v-row align="center" justify="space-between">
                <v-col cols="12" md="4">
                    <h1 class="mb-0"
                    :class="$vuetify.theme.isDark ? 'white--text' : ''" >
                        Categorías
                    </h1>
                </v-col>

                <v-col cols="8" md="7">
                    <v-text-field hide-details dense outlined flat v-model="search" append-icon="mdi-magnify"
                        label="Buscar...">
                    </v-text-field>
                </v-col>

                <v-col cols="4" md="1">
                    <v-row justify="center">
                        <v-btn fab small elevation="0" color="primary" @click="createItem()">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>


        <v-card>
            <v-card-text>
                <v-row class="elevated-3">
                    <v-col>
                        <v-col xs="12" md="12" sm="12">
                            <v-data-table :headers="headers" :items="sortedItems" :search="search">

                                <template v-slot:item.enabled="{item}">
                                    <v-icon v-if="item.enabled" large color="success" >mdi-check-circle-outline</v-icon>
                                    <v-icon v-else large color="grey" >mdi-close-circle-outline</v-icon>
                                </template>
                                
                                <template v-slot:item.actions="{item}">
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn class="mx-1" fab x-small color="warning" dark depressed
                                                @click="editItem(item.id)" v-on="on">
                                                <v-icon >mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                </template>

                            </v-data-table>
                        </v-col>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-container>

</template>

<script>
    export default {
        data() {
            return {
                items: [],
                search: '',
                headers: [{
                        text: 'ID',
                        align: 'center',
                        value: 'id',
                    },
                    {
                        text: 'Nombre',
                        align: 'center',
                        value: 'name',
                    },

                    {
                        text: 'Activo',
                        align: 'center',
                        value: 'enabled'
                    },

                    {
                        text: 'Acciones',
                        align: 'center',
                        value: 'actions'
                    }
                ]
            };
        },
        computed: {
            sortedItems(){
                return this.items.sort((a, b) => (a.id < b.id) ? 1 : -1)
            }
        },
        methods: {
            createItem() {
                this.$router.push('/categories/create')
            },

            editItem(id) {
                this.$router.push(`/categories/edit/${id}`)
            },

            getDataFromAPI() {
                this.$axios
                    .get('admin/categories')
                    .then((response) => {
                        this.items = response.data
                        // console.log(this.items)
                    })
            }
        },
        mounted() {
            this.getDataFromAPI()
        }
    }
</script>