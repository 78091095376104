import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store"

//DASHBOARD
import Dashboard from '@/views/reseller/dashboard/Dashboard'


//Customers
import CustomersList from '@/views/reseller/customers/List'
import CustomersForm from '@/views/reseller/customers/Form'

//Prices Table
import PricesTableList from '@/views/reseller/pricesTable/List'

//sales orders
import SaleOrdersList from '@/views/reseller/saleOrders/List'
import SaleOrdersShow from '@/views/reseller/saleOrders/List'
import SaleOrderShow from "@/views/reseller/saleOrders/Show";

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "dashboard",
        component: Dashboard,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },

    {
        path: "/customers",
        name: "customers",
        component: CustomersList,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },

    {
        path: "/customers/create",
        name: "customers.create",
        component: CustomersForm,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },

    {
        path: "/customers/edit/:id",
        name: "customers.edit",
        component: CustomersForm,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },


    {
        path: "/sales",
        name: "sales",
        component: SaleOrdersList,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },

    {
        path: "/order/:id",
        name: "order.show",
        component: SaleOrderShow,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/pricestable",
        name: "pricestable",
        component: PricesTableList,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },
]


const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})



/*router.beforeEach((to, from, next) => {

        if (!store.state.user.isLoggedIn)  {
            console.log('isloginned', store.state.user.isLoggedIn)
           store.dispatch('getUser').then((res) => {
                if (store.state.user.isLoggedIn) {
                    next()
                    alert('login' + store.state.user.isLoggedIn)
                }else{
                    alert('No Login' + store.state.user.isLoggedIn)
                    next(process.env.VUE_APP_BACKEND_URL + '/login')
                }
            })
            //window.location.href = process.env.VUE_APP_BACKEND_URL + '/login';
            //next(`${process.env.VUE_APP_BACKEND_URL}/ URL`)
        }else{
            next()
        }
})*/

router.beforeEach((to, from, next) => {

    if (store.state.user.isLoggedIn)  {
                next()
               // alert('login' + store.state.user.isLoggedIn)
            }else{
                alert('No esta logueado, redireccionando...' + store.state.user.isLoggedIn)
                window.location.href = process.env.VUE_APP_BACKEND_URL + '/login';
            }
})

export default router