<template>
  <v-container fluid>

    <v-col cols="12">

      <v-row align="center" justify="space-between">
        <v-col cols="12" md="4">
          <h1 class="mb-0"
              :class="$vuetify.theme.isDark ? 'white--text' : ''">
            Devoluciones
          </h1>
        </v-col>

        <v-col cols="8" md="7">

        </v-col>

        <v-col cols="4" md="1">
          <v-row justify="center">
            <v-btn fab small elevation="0" color="primary"
                   @click="create()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </v-col>

      </v-row>
    </v-col>


    <v-card>
      <v-card-text>
        <v-row class="elevated-3">
          <v-col>
            <v-col xs="12" md="12" sm="12">

              <v-data-table :headers="headers" :items="sortedItems" disable-sort>
                <template v-slot:item.status="{item}">
                  <StatusLabel :status="item.status"></StatusLabel>
                </template>
                <template v-slot:item.actions="{item}">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="showDetails(item)">
                        <v-list-item-title>
                          <v-icon small class="mr-2"> mdi-eye</v-icon>
                          Ver detalle
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item v-if="item.status === 'created'" @click="confirmAction('reject', item.id)">
                        <v-list-item-title>
                          <v-icon small class="mr-2"> clear</v-icon>
                          Rechazar
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item v-if="item.status === 'created'" @click="confirmAction('approve', item.id)">
                        <v-list-item-title>
                          <v-icon small class="mr-2"> check</v-icon>
                          Autorizar
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
              </v-data-table>

            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="showDetailsDialog.visible" max-width="600">
      <v-card>
        <v-card-text v-if="showDetailsDialog.item">
          <v-col>
            <v-row>
              <v-col cols="6"><strong>Producto</strong></v-col>
              <v-col cols="6"><span>{{ showDetailsDialog.item.description }}</span></v-col>
            </v-row>
            <v-row>
              <v-col cols="6"><strong>Nota</strong></v-col>
              <v-col cols="6"><span>{{ showDetailsDialog.item.note }}</span></v-col>
            </v-row>
          </v-col>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="confirmActionModal.visible" max-width="600">
      <v-card>
        <v-card-title>
          <v-col align="center">
            <h4 v-if="confirmActionModal.action === 'approve'">Autorización de devolución </h4>
            <h4 v-if="confirmActionModal.action === 'reject'">Rechazar devolución </h4>

          </v-col>
        </v-card-title>
        <v-card-text>
          <v-col>
            <v-text-field label="Nota" v-model="confirmActionModal.note"></v-text-field>
          </v-col>
        </v-card-text>
        <v-card-actions >
          <v-col>
            <v-btn v-if="confirmActionModal.note.length > 10 && confirmActionModal.action === 'approve'" class="primary" @click="approve">Aprobar</v-btn>
            <v-btn v-if="confirmActionModal.note.length > 10 && confirmActionModal.action === 'reject'" class="primary" @click="reject">Rechazar</v-btn>
          </v-col>
          <v-btn class="secondary" @click="confirmActionModal.visible=false">Cancelar</v-btn>
        </v-card-actions>


      </v-card>
    </v-dialog>


  </v-container>

</template>

<script>
import StatusLabel from "../../../components/helpers/statusLabel";

export default {
  components: {StatusLabel},
  data() {
    return {

      items: [],

      search: '',
      headers: [{
        text: 'ID',
        align: 'center',
        value: 'id',
      },
        {
          text: 'Nombre',
          align: 'center',
          value: 'reseller.name',
        },

        {
          text: 'Importe',
          align: 'center',
          value: 'amount'
        },
        {
          text: 'Saldo',
          align: 'center',
          value: 'balance'
        },

        {
          text: 'Fecha',
          align: 'center',
          value: 'created_at'
        },
        {
          text: 'Estatus',
          align: 'center',
          value: 'status'
        },
        {
          text: 'Acciones',
          align: 'center',
          value: 'actions'
        }
      ],
      //dialogSHowDetails
      showDetailsDialog: {
        visible: false,
        item: null,
      },
      confirmActionModal: {
        visible: false,
        action: '',
        id:null,
        note:'',
      },
    };
  },
  computed: {
    sortedItems() {
      return this.items.sort((a, b) => (a.id < b.id) ? 1 : -1)
    }
  },
  methods: {

    showDetails(item) {
      this.showDetailsDialog.item = item
      this.showDetailsDialog.visible = true
    },
    create() {
      this.clearForm()
      this.$axios
          .get('/driver/returns/create')
          .then((response) => {
            this.resellers = response.data.resellers
            this.$router.push('/returns/create')
          })

    },

    confirmAction(action, id)
    {

        this.confirmActionModal.id = id
        this.confirmActionModal.note = ''
        this.confirmActionModal.action = action
       console.log(action)
        this.confirmActionModal.visible = true



    },

    approve()
    {

      let formData = new FormData();

      formData.append('id', this.confirmActionModal.id)
      formData.append('approved_note', this.confirmActionModal.note)
      this.$axios
          .post('/admin/return/approve', formData)
          .then((response) => {

            this.$store.commit( 'OPEN_SNACKBAR', response.data);
            if(response.data.success) {
              this.getDataFromAPI()
              this.confirmActionModal.visible = false
              this.confirmActionModal.id = null
            }
          })
          .catch((error)=>{
            console.log(error);
          });
    },

    reject()
    {

      let formData = new FormData();

      formData.append('id', this.confirmActionModal.id)
      formData.append('rejected_note', this.confirmActionModal.note)
      this.$axios
          .post('/admin/return/reject', formData)
          .then((response) => {

            this.$store.commit( 'OPEN_SNACKBAR', response.data);
            if(response.data.success) {
              this.getDataFromAPI()
              this.confirmActionModal.visible = false
              this.confirmActionModal.id = null
            }
          })
          .catch((error)=>{
            console.log(error);
          });
    },

    getDataFromAPI() {
      this.$axios
          .get('admin/returns')
          .then((response) => {
            this.items = response.data

          })
    }
  },
  mounted() {
    this.getDataFromAPI()
  }
}
</script>