<template>

  <v-container fluid>

    <v-col cols="12">

      <v-row align="center" justify="space-between">
        <v-col cols="12" md="4">
          <h1 class="mb-0"
              :class="$vuetify.theme.isDark ? 'white--text' : ''">
            Tabla de Descuentos
          </h1>
        </v-col>

        <v-col cols="8" md="7">
          <v-text-field hide-details dense outlined flat v-model="search" append-icon="mdi-magnify"
                        label="Buscar...">
          </v-text-field>
        </v-col>

        <v-col cols="4" md="1">
          <v-row justify="center">
            <v-btn fab small elevation="0" color="primary" @click="saveDialog = true">
              <v-icon>mdi-content-save</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-col>


  <div>
    <v-data-table
        :headers="headers"
        :items="items"
        :search="search"
        disable-sort
    >
<!--      FROM-->
      <template v-slot:item.from="props">
        <v-edit-dialog
            :return-value.sync="props.item.from"

        > {{ props.item.from }}
          <template v-slot:input>
            <v-text-field
                v-model="props.item.from"
                type="number"
                label="Editar"
                single-line

            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

<!--     TO-->

      <template v-slot:item.to="props">
        <v-edit-dialog
            :return-value.sync="props.item.to"

        > {{ props.item.to }}
          <template v-slot:input>
            <v-text-field
                v-model="props.item.to"
                type="number"
                label="Editar"
                single-line

            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

<!--    Descuento Contado-->

      <template v-slot:item.discount_a="props">
        <v-edit-dialog
            :return-value.sync="props.item.discount_a"

        > {{ props.item.discount_a }}
          <template v-slot:input>
            <v-text-field
                v-model="props.item.discount_a"
                type="number"
                label="Editar"
                single-line

            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

<!--      Descuento Credito-->

      <template v-slot:item.discount_b="props">
        <v-edit-dialog
            :return-value.sync="props.item.discount_b"

        > {{ props.item.discount_b }}
          <template v-slot:input>
            <v-text-field
                v-model="props.item.discount_b"
                type="number"
                label="Editar"
                single-line

            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>

      <!--      Incremento Sugerido   -->

      <template v-slot:item.suggested_increment="props">
        <v-edit-dialog
            :return-value.sync="props.item.suggested_increment"

        > {{ props.item.suggested_increment }}
          <template v-slot:input>
            <v-text-field
                v-model="props.item.suggested_increment"
                type="number"
                label="Editar"
                single-line

            ></v-text-field>
          </template>
        </v-edit-dialog>
      </template>


    </v-data-table>


  </div>

    <v-dialog
        v-model="saveDialog"
        max-width="400"
    >
      <v-card>
        <v-card-title class="headline">¿Estas seguro?</v-card-title>

        <v-card-text>
          Esta acción actualizará la tabla de descuentos y no podra revertise.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
              rounded
              color="primary"
              text
              @click="saveDialog = false"
          >
            Mejor no
          </v-btn>

          <v-btn
              elevation="0"
              class="pa-4"
              rounded
              color="error"
              @click="store"
          >
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
export default {
data(){
  return {
    saveDialog : false,

    onlyNumberRule: v => v.length <= 25 || 'Input too long!',
    pagination: {},
    search:'',
    headers:[{text: 'Desde', value: 'from'},
      {text: 'Hasta', value: 'to'},
      {text: 'Descuento Contado %', value: 'discount_a'},
      {text: 'Descuento Credito %', value: 'discount_b'},
      {text: 'Incremento sugerido', value: 'suggested_increment'},
    ],

    items:[
    ],
  }
},
  methods:{
  getDataFromAPI(){
    this.$axios
        .get('admin/masterpricetable')
        .then((response) => {
          this.items = response.data
          //console.log(this.items)
        })
  },
    store() {

      // Display the key/value pairs of FormData
      // for (let pair of formData.entries()) {
      //   console.log(pair[0] + ', ' + pair[1]);
      // }

      this.$axios
          .post('/admin/masterpricetable', this.items)
          .then((response) => {
            this.$store.commit( 'OPEN_SNACKBAR', response.data)

            this.getDataFromAPI()
            this.saveDialog = false
          })
          .catch((error)=>{
            console.log(error);
          });
    },
  },

  mounted() {
    this.getDataFromAPI()
  }

}
</script>

