<template>
  <v-container fluid>
    <v-form class="pa-0" ref="form" v-model="validForm" lazy-validation>

      <v-toolbar flat color="transparent">

        <v-toolbar-title>
          <h2 v-if="!$route.params.id" :class="$vuetify.theme.isDark ? 'white--text' : ''">
            Crear Factor de precio
          </h2>

          <h2 v-else :class="$vuetify.theme.isDark ? 'white--text' : ''">
            Editar Factor de precio
          </h2>

        </v-toolbar-title>

        <v-spacer />

        <v-btn :disabled="!validForm" class="mx-1" elevation="0" color="primary" rounded @click="validateForm">
          Guardar
        </v-btn>

        <v-btn class="mx-1" elevation="0" color="error" rounded v-if="$route.params.id" @click="destroyDialog = true">
          Eliminar
        </v-btn>

      </v-toolbar>


      <v-row>
        <v-col cols="12" >
          <v-card>
            <v-card-title class="pb-2">
              <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
                <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-clipboard-text</v-icon>
                Descripción
              </h4>
            </v-card-title>

            <v-card-text>

<v-row>
              <v-col cols="6" md="4">
                <v-text-field :rules="[ rules.required]"  dense outlined v-model="payload.name" label="Nombre">
                </v-text-field>

              </v-col>

              <v-col cols="6" md="2">
                <v-text-field type="number" :rules="[ rules.required]"  dense outlined v-model="payload.tax_factor" label="Factor IVA" hint="Ej. 1.16 Para añadir 16% IVA">
                </v-text-field>

              </v-col>

              <v-col cols="6" md="2">
                <v-text-field :rules="[ rules.required]"  dense outlined v-model="payload.profit_factor"  label="Factor Precio" hint="Ej. 1.1 Para añadir 10% Utilidad">
                </v-text-field>

              </v-col>

  <v-col cols="6" md="4">
    <v-text-field  dense outlined v-model="calculatePrice" disabled  persistent-hint label="Resultado" hint="Este seria el precio de un producto con costo de 100 MXN" >
    </v-text-field>

  </v-col>
</v-row>


            </v-card-text>
          </v-card>
        </v-col>

      </v-row>



      <v-dialog v-model="destroyDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">¿Estas seguro?</v-card-title>

          <v-card-text>
            Una vez elimines el registro, no podrás recuperarlo.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn rounded color="primary" text @click="destroyDialog = false">
              Mejor no
            </v-btn>

            <v-btn elevation="0" class="pa-4" rounded color="error" @click="destroy">
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-form>
  </v-container>
</template>


<script>


export default {
  data() {
    return {
      destroyDialog: false,
      validForm: true,

      rules: {
        required: v => !!v || 'Necesario.',
        min: v => v < 1 || 'No puede ser inferior a 1',
      },
      payload: {
        id: null,
        name: null,
        tax_factor: 1,
        profit_factor: 1,

      },
      
    }
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        (this.$route.params.id ? this.update() : this.store())
      }
    },

   
    store() {
      let formData = new FormData();

      // Loops the payload and inserts it inside formData
      Object.entries(this.payload).forEach(([key, value]) => {
        formData.append(key, value)
      })

      this.$axios
          .post('/admin/pricefactors', formData)
          .then((response) => {
             console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/pricefactors') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
    },

     edit() {
        this.$axios
          .get(`/admin/pricefactors/${this.$route.params.id}/edit`)
          .then((response) => {
            // console.log(response.data)           
            this.payload = response.data.item
            
          })
      },

    update() {
      let formData = new FormData();

      // Loops the payload and inserts it inside formData
      Object.entries(this.payload).forEach(([key, value]) => {
        formData.append(key, value)
      })

     // Laravel put workaround
      formData.append('_method', 'PUT')

      this.$axios
          .post(`/admin/pricefactors/${this.$route.params.id}`, formData)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/pricefactors') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
    },

    destroy() {
      let formData = new FormData();

      // Laravel delete workaround
      formData.append('_method', 'DELETE')

      this.$axios
          .post(`/admin/pricefactors/${this.$route.params.id}`, formData)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/pricefactors') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
    },

  },
computed:{
  calculatePrice(){
    const cost = 100
    let tax_amount = 0
    let profit_amount = 0
    let price = 0
    if (this.payload.tax_factor.length > 0){
      tax_amount = cost * (this.payload.tax_factor - 1)
    }
    if (this.payload.profit_factor.length > 0){
      profit_amount = cost * (this.payload.profit_factor - 1)
    }
    price = cost + tax_amount + profit_amount
    return price
  }
},

mounted(){
  if (this.$route.params.id) {
        this.edit()
      }
}
 
}
</script>