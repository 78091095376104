<template>
  <v-container fluid>

    <v-form class="pa-0" ref="form" v-model="validForm" lazy-validation>
      <v-toolbar flat color="transparent">

        <v-toolbar-title>
          <h2 v-if="!$route.params.id" :class="$vuetify.theme.isDark ? 'white--text' : ''">
            Crear Producto
          </h2>

          <h2 v-else :class="$vuetify.theme.isDark ? 'white--text' : ''">
            <v-btn fab small elevation="0" color="secondary" @click="showLog()">
              <v-icon>mdi-post</v-icon>
            </v-btn>Editar Producto
          </h2>

        </v-toolbar-title>

        <v-spacer />

<!--        <v-btn :disabled="!validForm" class="mx-1" elevation="0" color="primary" rounded @click="validateForm">-->
<!--          Guardar-->
<!--        </v-btn>-->

<!--        <v-btn class="mx-1" elevation="0" color="error" rounded v-if="$route.params.id" @click="destroyDialog = true">-->
<!--          Eliminar-->
<!--        </v-btn>-->

      </v-toolbar>

      <v-row>
        <template >
          <v-tabs fixed-tabs>
            <v-tabs-slider></v-tabs-slider>
            <v-tab
                href="#general"
                class="primary--text"
            >
              <v-icon>mdi-book-play-outline</v-icon> GENERAL
            </v-tab>
            <v-tab v-if="payload.size_collection_id"
                href="#tallas"
                class="primary--text"
            >
              <v-icon>mdi-tshirt-crew-outline</v-icon> TALLAS
            </v-tab>

            <v-tab-item :value="'general'">

              <v-row>
                <v-col cols="12" md="6">
                  <v-card class="">
                    <v-card-title class="pb-2">
                      <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
                        <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-clipboard-text</v-icon>
                        Descripción
                      </h4>
                    </v-card-title>
                    <v-card-text>
                      <v-row align="center">

                        <v-col cols="12" md="6">

                          <v-select :readonly="$route.params.id ? true : false" :rules="emptyRule" hide-details dense outlined
                                    label="Tipo de Producto *" v-model="payload.product_type_id" :items="product_types" item-value="id"
                                    item-text="name">

                            <template slot="prepend-inner">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="warning" dark>mdi-alert-circle-outline</v-icon>
                                </template>
                                <span>Una vez seleccionado, ya no podrá ser cambiado.</span>
                              </v-tooltip>
                            </template>

                          </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select :readonly="$route.params.id ? true : false" :rules="emptyRule" hide-details dense outlined
                                    @change="getSizeCollectionItems" label="Corrida *" v-model="payload.size_collection_id" :items="size_collections" item-value="id"
                                    item-text="name">

                            <template slot="prepend-inner">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon v-bind="attrs" v-on="on" color="warning" dark>mdi-alert-circle-outline</v-icon>
                                </template>
                                <span>Una vez seleccionado, ya no podrá ser cambiado.</span>
                              </v-tooltip>
                            </template>

                          </v-select>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field :rules="emptyRule" hide-details dense outlined v-model="payload.name" label="Nombre *">
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field hide-details dense outlined v-model="payload.sku" label="SKU"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field hide-details dense outlined v-model="payload.model" label="Modelo"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select :rules="emptyRule" hide-details dense outlined label="Marca *"
                                    v-model="payload.product_brand_id" :items="brandsFiltered" item-value="id" item-text="name">
                          </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select :rules="emptyRule" hide-details dense outlined label="Genero *"
                                    v-model="payload.product_gender_id" :items="product_genders" item-value="id" item-text="name">
                          </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select :rules="emptyRule" hide-details dense outlined label="Categoría *"
                                    v-model="payload.product_category_id" :items="categoiesFiltered" item-value="id" item-text="name">
                          </v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field hide-details dense outlined v-model="payload.tags" label="Tags"></v-text-field>
                        </v-col>
                        <v-col cols="12" >
                          <v-autocomplete chips multiple hide-details outlined label="Materiales"
                                          v-model="product_material_selection" :items="product_materials" item-value="id" item-text="name">
                          </v-autocomplete>
                        </v-col>
                        <v-col cols="12" >
                          <v-autocomplete chips multiple hide-details  outlined label="Colores"
                                          v-model="product_color_selection" :items="product_colors" item-value="id" item-text="name">

                            <template v-slot:selection="{ item }">
                              <v-chip > <span class="font-weight-bold" :style="`color:${item.code}`" >{{item.name}}</span></v-chip>
                            </template>

                          </v-autocomplete>
                        </v-col>

                        <v-col cols="12" md="12" class="px-6 py-0">
                          <v-row align="center" justify="center">

                            <h4 class="mb-0 pr-3" :class="$vuetify.theme.isDark ? 'white--text' : ''">Activo:</h4>
                            <v-switch :color="$vuetify.theme.isDark ? 'primary' : 'secondary'" v-model="payload.enabled">
                            </v-switch>
                          </v-row>
                        </v-col>

                      </v-row>

                    </v-card-text>
                  </v-card>

                  <v-card>
                    <v-card-title class="pb-2">

                      <v-row justify="space-between" align="center">
                        <v-col cols="8">
                          <h4 class="mb-0" :class="$vuetify.theme.isDark ? 'white--text' : ''">
                            <v-icon
                                :color="$vuetify.theme.isDark ? 'white' : 'secondary'"
                            >mdi-image</v-icon>
                            Estudio
                          </h4>

                        </v-col>
                        <v-btn
                            @click="getStudioImage"
                            class="mx-4"
                            fab
                            small
                            elevation="0"
                            rounded
                            color="primary">
                          <v-icon>
                            mdi-plus
                          </v-icon>
                        </v-btn>

                        <v-col cols="12">
                          <!-- :include-styling="false" -->
                          <vue-dropzone
                              @vdropzone-sending="setUniqueName"
                              @vdropzone-removed-file="removeFileStudio"
                              @vdropzone-success="uploadSuccessStudio"
                              ref="studioDropzone"
                              id="studioDropzone"
                              :useCustomSlot="true"
                              :options="studioDropzoneOptions"
                          >
                            <h4
                                style="color:#bcbcbc"
                                class="text-center dropzone-custom-content">
                              No hay imagenes disponibles
                            </h4>
                          </vue-dropzone>
                        </v-col>
                      </v-row>

                    </v-card-title>
                  </v-card>
                </v-col>

                <v-col cols="12" md="6" class="pa-0">
                  <v-col cols="12">
                    <v-card class="">
                      <v-card-title class="pb-2">
                        <h4 class="mb-0" :class="$vuetify.theme.isDark ? 'white--text' : ''">
                          <v-icon
                              :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-cash-multiple</v-icon> Valores
                        </h4>
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" md="6">
                            <v-select v-model="payload.price_factor_id" :items="price_factors" item-value="id" item-text="name" label="Factor de precio"></v-select>
                          </v-col>
                        </v-row>
                        <v-row align="center">
                          <v-col cols="12" md="6">
                            <v-text-field prepend-inner-icon="mdi-currency-usd" type="number" hide-details dense outlined
                                          v-model="payload.cost" label="Costo"></v-text-field>
                          </v-col>
                          <v-col cols="12" md="6">
                            <v-text-field :rules="emptyRule" v-if="payload.price_factor_id ==0" prepend-inner-icon="mdi-currency-usd" type="number" hide-details
                                          dense outlined v-model="payload.price" label="Precio *"></v-text-field>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-col>
                  <v-col cols="12">
                    <v-card>
                      <v-card-title class="pb-2">

                        <v-row justify="space-between" align="center">
                          <v-col cols="8">
                            <h4 class="mb-0" :class="$vuetify.theme.isDark ? 'white--text' : ''">
                              <v-icon
                                  :color="$vuetify.theme.isDark ? 'white' : 'secondary'"
                              >mdi-image</v-icon>
                              Principal
                            </h4>

                          </v-col>
                          <v-btn
                              @click="getPrimaryImage"
                              class="mx-4"
                              fab
                              small
                              elevation="0"
                              rounded
                              color="primary">
                            <v-icon>
                              mdi-plus
                            </v-icon>
                          </v-btn>

                          <v-col cols="12">
                            <!-- :include-styling="false" -->
                            <vue-dropzone
                                @vdropzone-sending="setUniqueName"
                                @vdropzone-removed-file="removeFilePrimary"
                                @vdropzone-success="uploadSuccessPrimary"
                                ref="primaryDropzone"
                                id="primaryDropzone"
                                :useCustomSlot="true"
                                :options="primaryDropzoneOptions"
                                >
                              <h4
                                  style="color:#bcbcbc"
                                  class="text-center dropzone-custom-content">
                                No hay imagenes disponibles
                              </h4>
                            </vue-dropzone>
                          </v-col>
                        </v-row>

                      </v-card-title>

                      <hr>

                      <v-card-title class="pb-2">
                        <v-row justify="space-between" align="center">
                          <v-col cols="9">
                            <h4 class="mb-0 " :class="$vuetify.theme.isDark ? 'white--text' : ''">
                              <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-image-multiple</v-icon>
                              Secundarias
                            </h4>
                          </v-col>

                          <v-btn
                              @click="getSecondaryImages"
                              class="mx-4"
                              fab
                              small
                              elevation="0"
                              rounded
                              color="primary">
                            <v-icon>
                              mdi-plus
                            </v-icon>
                          </v-btn>

                          <v-col cols="12">
                            <!-- :include-styling="false" -->
                            <vue-dropzone
                                @vdropzone-sending="setUniqueName"
                                @vdropzone-removed-file="removeFile"
                                @vdropzone-success="uploadSuccess"
                                ref="secondariesDropzone"
                                id="dropzone"
                                :useCustomSlot="true"
                                :options="dropzoneOptions"
                                >
                              <h4
                                  style="color:#bcbcbc"
                                  class="text-center dropzone-custom-content">
                                No hay imagenes disponibles
                              </h4>
                            </vue-dropzone>
                          </v-col>

                        </v-row>
                      </v-card-title>
                    </v-card>

                  </v-col>
                </v-col>

              </v-row>


            </v-tab-item>

            <v-tab-item :value="'tallas'">
              <v-row >
                <v-col cols="12">
                  <v-card>
                    <v-card-title class="pb-2">
                      <h4 class="mb-0" :class="$vuetify.theme.isDark ? 'white--text' : ''">
                        <v-icon
                            :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-image-size-select-small</v-icon> Tallas
                      </h4>
                    </v-card-title>
                    <v-card-text>
                      <v-row align="center" v-for="(row, index)  in sortedVariants">
                        <v-col cols="12" md="3">
                          <v-text-field disabled hide-details dense outlined v-model="row.size"  label="Talla"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field hide-details dense outlined v-model="row.barcode" label="Codigo de barras"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="3">
                          <v-text-field hide-details dense outlined v-model="row.order" type="number" label="Orden"></v-text-field>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </template>
      </v-row>



      <v-dialog v-model="destroyDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">¿Estas seguro?</v-card-title>

          <v-card-text>
            Una vez elimines el registro, no podrás recuperarlo.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn rounded color="primary" text @click="destroyDialog = false">
              Mejor no
            </v-btn>

            <v-btn elevation="0" class="pa-4" rounded color="error" @click="destroy">
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <logDialog ref="logDialog"></logDialog>
    </v-form>





    <v-col cols="12">

          <v-btn :disabled="!validForm" class="mx-1" elevation="0" color="primary" rounded @click="validateForm">
            Guardar
          </v-btn>

          <v-btn class="mx-1" elevation="0" color="error" rounded v-if="$route.params.id" @click="destroyDialog = true">
            Eliminar
          </v-btn>

    </v-col>


  </v-container>
</template>


<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import logDialog from '../commons/LogViewer'
export default {

  data() {
    return {
      token: '',

      destroyDialog: false,
      validForm: true,
      emptyRule: [v => !!v || 'Necesario'],
      payload: {
        id: null,
        name: null,

        product_type_id: null,
        size_collection_id: null,
        product_brand_id: null,
        product_gender_id: null,
        product_category_id: null,
        price_factor_id: 0,
        product_variants: [],
        // product_color_id: null,
        // product_material_id: null,

        sku: null,
        tags: null,
        enabled: true,
        cost: 0,
        price: 0,
      },

      photos: {
        photo: {
          name: null,
        },
        secondaryPhotos: [],
        studio:{
          name: null,
        },
      },

      product_color_selection: [],
      product_material_selection: [],

      product_types: [],
      size_collections: [],
      product_genders: [],
      product_brands: [],
      product_categories: [],
      product_colors: [],
      product_materials: [],
      price_factors: [],

      dropzoneOptions: {
        url: `${this.$axios.defaults.baseURL}/admin/products/media`,
        acceptedFiles: 'image/*',
        thumbnailWidth: 150,
        maxFilesize: 2,
        addRemoveLinks: true,
        withCredentials:true,
      },
      primaryDropzoneOptions: {
        url: `${this.$axios.defaults.baseURL}/admin/products/media`,
        acceptedFiles: 'image/*',
        thumbnailWidth: 300,
        maxFilesize: 2,
        maxFiles: 1,
        addRemoveLinks: true,
        sendFileToServer: false,
        withCredentials:true,

      },
      studioDropzoneOptions: {
        url: `${this.$axios.defaults.baseURL}/admin/products/media`,
        acceptedFiles: 'image/*',
        thumbnailWidth: 150,
        maxFilesize: 2,
        addRemoveLinks: true,
        withCredentials:true,
      },
    }
  },

  computed:{
    validationPack(){
      let validationPack = {
        photo: {},
        studio:{},
        secondaryPhotos: [],
      };      

      validationPack.photo.name = this.photos.photo.name;
      validationPack.studio.name = this.photos.studio.name
      validationPack.photo.firstTime = this.photos.photo.firstTime;
      validationPack.secondaryPhotos = this.photos.secondaryPhotos;

      return validationPack
    },

    brandsFiltered(){
      var product_type = this.payload.product_type_id

      if (product_type != 0){
        return this.product_brands.filter(function(brands){
          return brands.product_type_id === product_type
        })
      }
    },

    categoiesFiltered(){
      var product_gender = this.payload.product_gender_id
      var product_type = this.payload.product_type_id

        if (product_type != 0){
        return this.product_categories
        .filter(function(categories){
          return categories.product_type_id === product_type
        })
        .filter(function(categories){
          return categories.product_gender_id === product_gender
        })
      }
      
    },

    sortedVariants() {
      let sorted = this.payload.product_variants.sort((a, b) => {
        return a.order + b.order;
      })
      return sorted
    },
    
  },
  methods: {
    showLog(){
      this.$refs.logDialog.getModelLogFromAPI('App\\Models\\Product',this.$route.params.id)
    },
    getCookie(name) {
      if (!document.cookie) {
        return null;
      }
      const xsrfCookies = document.cookie.split(';')
          .map(c => c.trim())
          .filter(c => c.startsWith(name + '='));

      if (xsrfCookies.length === 0) {
        return null;
      }
      return decodeURIComponent(xsrfCookies[0].split('=')[1]);
    },

    getSizeCollectionItems(id){
      this.size_collections.map((size_collection) =>{
        if (size_collection.id == id){
             this.payload.product_variants = []
             //let variant_order = 0

          size_collection.items.forEach((value,index)=>{
            //variant_order ++
            let row = { size:value.value, barcode:'', order:value.order};
            this.payload.product_variants.unshift(row);
            //console.log(value);
          })
         // console.log (this.payload.product_variants)
        }
      })
    },
    
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace('.', '.')
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
    
    validateForm() {
      if (this.$refs.form.validate()) {
        (this.$route.params.id ? this.update() : this.store())
      }
    },

    getPrimaryImage() {
      this.$refs.primaryDropzone.$el.click()
    },

    getStudioImage() {
      this.$refs.studioDropzone.$el.click()
    },

    getSecondaryImages() {
      this.$refs.secondariesDropzone.$el.click()
    },

    store() {
      this.payload.validationPack = this.validationPack; 
      this.payload.product_color_selection = this.product_color_selection;    
      this.payload.product_material_selection = this.product_material_selection;

      this.$axios
        .post('/admin/products', this.payload)
        .then((response) => {
          this.$store.commit('OPEN_SNACKBAR', response.data);
          (response.data.success ? this.$router.push('/products') : '')
         //console.log(this.payload)
        })
        .catch((error) => {
          //console.log(error);
        });
    },

    update() {
      this.payload.validationPack = this.validationPack; 
      this.payload.product_color_selection = this.product_color_selection;    
      this.payload.product_material_selection = this.product_material_selection;

      // Laravel put workaround
      this.payload._method = 'PUT'
      // formData.append('_method', 'PUT')

      this.$axios
        .post(`/admin/products/${this.$route.params.id}`, this.payload)
        .then((response) => {
          // console.log(response.data)
          this.$store.commit('OPEN_SNACKBAR', response.data);
          (response.data.success ? this.$router.push('/products') : '')
        })
        .catch((error) => {
         // console.log(error);
        });
    },

    clearForm() {
      // 
    },

    create() {
      this.$axios
        .get('/admin/products/create')
        .then((response) => {
          this.size_collections = response.data.size_collections
          this.product_types = response.data.product_types
          this.product_brands = response.data.product_brands
          this.product_categories = response.data.product_categories
          this.product_genders = response.data.product_genders
          this.product_materials = response.data.product_materials
          this.product_colors = response.data.product_colors
          this.price_factors = response.data.price_factors
          //console.log(this.size_collections);
        })
    },

    edit() {
      this.$axios
        .get(`/admin/products/${this.$route.params.id}/edit`)
        .then((response) => {
          this.size_collections = response.data.size_collections
          this.product_types = response.data.product_types
          this.product_brands = response.data.product_brands
          this.product_categories = response.data.product_categories
          this.product_genders = response.data.product_genders
          this.product_materials = response.data.product_materials
          this.product_colors = response.data.product_colors
          this.payload = response.data.item
          this.payload.product_variants = response.data.product_variants
          this.photos = response.data.photos
          this.product_color_selection = response.data.product_color_selection
          this.product_material_selection = response.data.product_material_selection
          this.price_factors = response.data.price_factors

          // Populate primaryDropzone
          if(this.photos.photo.name){
            let file = { size: 123, name: this.photos.photo.name , type: "image" };
            let url = this.photos.photo.url
            this.$refs.primaryDropzone.manuallyAddFile(file, url);
          }
          // Populate studioDropzone
          if(this.photos.studio.name){
            let file = { size: 123, name: this.photos.studio.name , type: "image" };
            let url = this.photos.studio.url
            this.$refs.studioDropzone.manuallyAddFile(file, url);
          }

          
          // Populate secondaryPhotosDropzone
          this.photos.secondaryPhotos.forEach(element => {
            let file = { size: 123, name: element.name , type: "image" };
            let url = element.url;            
            this.$refs.secondariesDropzone.manuallyAddFile(file, url);
          });  
          
        })
    },

    destroy() {
      let formData = new FormData();

      // Laravel delete workaround
      formData.append('_method', 'DELETE')

      this.$axios
        .post(`/admin/products/${this.$route.params.id}`, formData)
        .then((response) => {
          // console.log(response.data)
          this.$store.commit('OPEN_SNACKBAR', response.data);
          (response.data.success ? this.$router.push('/products') : '')
        })
        .catch((error) => {
         // console.log(error);
        });
    },


    setUniqueName(file, xhr, formData){
      let uniqueName = `${Date.now()}-${Math.random().toString(36).substr(2, 5)}.${file.name.split('.').pop()}`;
      //console.log(uniqueName);
      formData.append('uniqueName', uniqueName)
      file.uniqueName = uniqueName;
    },

    removeFile(file, error, xhr){
      // console.log(file)
      this.photos.secondaryPhotos = this.photos.secondaryPhotos.filter(e =>{
          if(!e.firstTime){
            return (e.name !== file.name);
          } else {
            return (e.name !== file.uniqueName);
          }        
        })
    },
    uploadSuccess(file, response){
      let photo = {
        name : file.uniqueName,
        firstTime : true
      };
      this.photos.secondaryPhotos.push(photo)
    },   

    removeFilePrimary(file, error, xhr){
      this.photos.photo.name = null 
    },
    uploadSuccessPrimary(file, response){
      this.photos.photo.name = file.uniqueName
      this.photos.photo.firstTime = true
    },
    removeFileStudio(file, error, xhr){
      this.photos.studio.name = null

    },
    uploadSuccessStudio(file, response){
      //console.log(this.photos);
      this.photos.studio.name = file.uniqueName
      this.photos.studio.firstTime = true

    },
  },


  mounted() {
    if (this.$route.params.id) {
      this.edit()
    } else {
      this.create()
    }
    this.token= this.getCookie('XSRF-TOKEN');

  },
  components: {
    vueDropzone: vue2Dropzone,
    logDialog
  },

}
</script>
<style lang="scss">
  .photo-frame,
  #dropzone .dz-preview, #primaryDropzone .dz-preview, #studioDropzone .dz-preview {
    // color: #00000062
    z-index: 0;
    overflow: hidden;
    border-radius: 0.5rem !important;
  }

  .vue-dropzone>.dz-preview .dz-details:hover, .vue-dropzone>.dz-preview .dz-details {
    bottom: 0;
    top: 0;
    color: #fff;
    background-color: #410082b7;
    -webkit-transition: opacity .2s linear;
    transition: opacity .2s linear;
    text-align: left;
  }
</style>