/**
 * menu() returns menu json the order
 * @param <String> tag
 * @return <Element> element
 */
const Menu = [{
    title: "Tablero",
    action: "icon-home",
    path: "",
},
    {
        title: "Pagos",
        action: "icon-calculator",
        path: "payments",
    },

    {
        title: "Devoluciones",
        action: "icon-handbag",
        path: "returns",
    },



];

Menu.forEach(item => {
    if (item.items) {
        item.items.sort((x, y) => {
            let textA = x.title.toUpperCase();
            let textB = y.title.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
        });
    }
});

export default Menu;