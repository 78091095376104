<template>
  <v-container fluid>
    <v-col cols="12">
      <v-card>
        <v-card-title>Nueva Orden de Pago</v-card-title>
        <v-card-text>
          <v-form class="pa-0" ref="form" v-model="validForm" lazy-validation>
            <v-row>

              <v-col>
                <v-autocomplete :rules="emptyRule" label="Distribuidor" :items="resellers" item-text="name"
                                item-value="id" v-model="payload.reseller_id"
                :disabled="(this.payload.items.length>0)"></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field :rules="emptyRule" type="number" label="Importe" v-model="payload.amount" :disabled="true"></v-text-field>
              </v-col>

              <v-col cols="4" md="1" v-if="this.payload.reseller_id">
                <v-row justify="center">
                  <v-btn fab small elevation="0" color="secondary"
                         @click="addItem()">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-row>
              </v-col>

            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col>

    </v-col>
    <v-col v-if="this.payload.items.length>0">
      <v-card>
        <v-card-text>
          <v-data-table :items="payload.items" :headers="headers" hide-default-footer disable-sort></v-data-table>
        </v-card-text>
      </v-card>
    </v-col>

    <v-card-actions>
      <v-col>

        <v-row>
          <v-col align="left">
            <v-btn color="primary" v-if="payload.items.length>0" @click="validateForm">Generar</v-btn>
          </v-col>
          <v-col align="right">
            <v-btn color="error" @click="$router.push('/paymentorders')">Cancelar</v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-card-actions>

    <v-dialog v-model="obligationsModal.show" max-width="800">
      <v-card v-if = "obligationsModal.view==='saleOrders'">
        <v-card-title>Pedidos</v-card-title>

        <v-card-text>
          <v-data-table :items="obligationsModal.items" :headers="obligationsModal.headers" hide-default-footer
                        disable-sort>
            <template v-slot:item.detail="props">
              <v-btn dark small color="green" @click="selectItem(props.item.id)">
                <v-icon dark>mdi-eye</v-icon>
              </v-btn>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>

      <v-card v-if = "obligationsModal.view==='saleOrdersObligations'">
        <v-card-title>Obligaciones</v-card-title>

        <v-card-text>
          <v-data-table :items="obligationsModal.detailItems" :headers="obligationsModal.detailHeaders" hide-default-footer
                        disable-sort>
            <template v-slot:item.detail="props">
              <v-btn dark small color="green" @click="addRow(props.item)" v-if="!props.item.selected">
                <v-icon dark>mdi-check</v-icon>
              </v-btn>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>

    </v-dialog>


  </v-container>


</template>

<script>
export default {
  data() {
    return {
      validForm: true,

      emptyRule: [v => !!v || 'Necesario'],
      showDialog: false,
      modalChecked: false,
      resellers: null,
      paymentMethods: null,
      payload: {
        'reseller_id': null,
        'payment_method': 'cash',
        'amount': '0.00',
        items: [],
      },
      total: 0.00,
      headers: [{
        text: 'ID',
        align: 'center',
        value: 'sale_order_obligation_id',
      },
        {
          text: 'Concepto',
          align: 'center',
          value: 'concept',
        },

        {
          text: 'Importe',
          align: 'center',
          value: 'amount'
        },


      ],

      obligationsModal: {
        show: false,
        view:null,
        items: [],
        headers: [{text: 'ID', align: 'center', value: 'id',},
          {text: 'Folio', align: 'center', value: 'number',},
          {text: 'Total', align: 'center', value: 'total',},
          {text: 'Saldo', align: 'center', value: 'balance',},
          {text: 'Detalle', align: 'center', value: 'detail'}],

      detailItems:[],
      detailHeaders: [{text: 'id', align: 'left', value: 'id'},
        {text: 'Concepto', align: 'left', value: 'concept'},
        {text: 'Importe', align: 'left', value: 'amount'},
        {text: 'Saldo', align: 'left', value: 'balance'},
        {text: 'Seleccionar', align: 'center', value: 'detail'}]
      },


    };
  },
  computed: {
    sortedItems() {
      return this.items.sort((a, b) => (a.id < b.id) ? 1 : -1)
    },

  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
        this.store()
      }
    },


    store() {

      this.$axios
          .post('/admin/paymentorders', this.payload)
          .then((response) => {
             //onsole.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success)? this.$router.push('/paymentorders'):''

          })
          .catch((error)=>{
            console.log(error);
          });
    },

    calculateTotal(){
      let sum = 0;
      this.payload.items.forEach(function(item){
        sum += parseFloat(item.amount)
      })
      this.payload.amount = sum.toFixed(2)
    },
    getDataFromAPI() {
      this.$axios
          .get('/admin/paymentorders/create')
          .then((response) => {
            this.resellers = response.data.resellers
          })
    },
    addItem() {
      this.getSaleOrdersFromAPI(this.payload.reseller_id)
      this.obligationsModal.view = 'saleOrders'
      this.obligationsModal.show = true
    },
    selectItem(saleOrderId)
    {
      this.getDetailsFromAPI(saleOrderId)
      this.obligationsModal.view = 'saleOrdersObligations'
      this.obligationsModal.show = true
    },
    getDetailsFromAPI(saleOrderId)
    {
      this.$axios
          .get(`/admin/accountstatusdetails/${saleOrderId}`)
          .then((response) => {
            this.obligationsModal.detailItems = response.data

          })
    },
    addRow(item) {
      if(this.rowExists(item.id))
      {
        alert('Ya ha agregado esta obligacion')
      }else{
      let row = {
        'sale_order_obligation_id': item.id,
        'concept': 'Pago de ' + item.concept,
        'amount': item.balance
      };
      this.payload.items.unshift(row)
      this.calculateTotal()
      this.obligationsModal.show=false
      }
    },

    rowExists(id)
    {
      for(let i = 0; i< this.payload.items.length; i++)
      {
        let itemId = this.payload.items[i].sale_order_obligation_id
        if (itemId === id) {
          return true}
        else {
          return false}
      }
    },
    removeRow(index){
      this.payload.items.splice(index, 1);
    },

    getSaleOrdersFromAPI(id) {
      this.$axios
          .get(`/admin/accountstatus/${id}`)
          .then((response) => {
            this.obligationsModal.items = response.data

          })
    },
  },
  mounted() {
    this.getDataFromAPI()
  }
}
</script>

<style scoped>

</style>