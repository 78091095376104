// @ts-nocheck
import menu from './menuAdmin'
var $ = require("jquery");
window.jQuery = $;
window.$ = $;

class core {
    constructor(x) {
        let vm = this;
        vm.menu = x.menu

        vm.appConfig = {
            mini: false,
            navbar: true,
            dark: false,
            drawer: 'navbar',
            model: null,
            type: "default (no property)",
            clipped: false,
            floating: false,
            expandOnHover: false,
            drawerRight: false,
            horizontal: false,
            responsive: false,
            drawers: ["Predeterminado", "Permanente", "Temporal"],
            footer: {
                inset: true
            },

            hideCanvas: true,
            hideInputs: true,
            hideModeSwitch: true,
            mode: "hexa",
            modes: ["rgba", "hsla", "hexa"],
            showSwatches: true,
            right: false,

        }
    }


    install(Vue) {
        let vm = this;

        Vue.mixin({
            props: {
                obj: {
                    type: Object,
                    default () {
                        return {};
                    }
                }
            },
            data() {
                return {
                    appConfig: vm.appConfig,
                    menu: vm.menu,
                }
            },
            computed: {
                path: {
                    get() {
                        let vm = this;
                        return {
                            group: vm.$route.fullPath.split("/")[1],
                            sub: vm.$route.fullPath.split("/")[2]
                        };
                    }
                }
            },


        })
    }
}


let c = new core({
    menu
})

export default c