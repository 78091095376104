import axios from 'axios'

export default {

    install(Vue) {

        Vue.prototype.$axios = axios.create(
            {

               // baseURL: 'http://genscatalogo.com/api',
               baseURL: process.env.VUE_APP_BACKEND_API_URL,
                headers: {
                    common: {
                        'X-Requested-With': 'XMLHttpRequest',


                    }
                },
                withCredentials:true,

            }
        );


    }

};

