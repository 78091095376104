import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store"

//DASHBOARD
import Dashboard from '@/views/reseller/dashboard/Dashboard'




//sales orders
import SaleOrdersList from '@/views/branch/saleOrders/List'
import SaleOrderShow from "@/views/branch/saleOrders/Show";

Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "dashboard",
        component: Dashboard,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },



    {
        path: "/sales",
        name: "sales",
        component: SaleOrdersList,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },

    {
        path: "/order/:id",
        name: "order.show",
        component: SaleOrderShow,
        meta: {
            breadcrumb: true
        }
    },

]


const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})



/*router.beforeEach((to, from, next) => {

        if (!store.state.user.isLoggedIn)  {
            console.log('isloginned', store.state.user.isLoggedIn)
           store.dispatch('getUser').then((res) => {
                if (store.state.user.isLoggedIn) {
                    next()
                    alert('login' + store.state.user.isLoggedIn)
                }else{
                    alert('No Login' + store.state.user.isLoggedIn)
                    next(process.env.VUE_APP_BACKEND_URL + '/login')
                }
            })
            //window.location.href = process.env.VUE_APP_BACKEND_URL + '/login';
            //next(`${process.env.VUE_APP_BACKEND_URL}/ URL`)
        }else{
            next()
        }
})*/

router.beforeEach((to, from, next) => {

    if (store.state.user.isLoggedIn)  {
                next()
               // alert('login' + store.state.user.isLoggedIn)
            }else{
                alert('No esta logueado, redireccionando...' + store.state.user.isLoggedIn)
                window.location.href = process.env.VUE_APP_BACKEND_URL + '/login';
            }
})

export default router