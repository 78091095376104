<!--=================================
=            STARTERLIST            =
==================================-->

<template>
    <div class="scroll transparent mx-auto" height="100%" tile>

        <v-list class="menu" v-for="item in items" :key="item.title" :dark="appConfig.dark" dense rounded>

            <v-list-group
                    class="menu-item"
                    v-model="item.active"
                    v-if="item.items"
                    no-action
            >
                <template v-slot:activator>
                    <v-list-item-icon class="menu-icon">
                        <v-icon :dark="appConfig.dark" v-text="item.action"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="menu-title">
                        <v-list-item-title v-text="item.title"></v-list-item-title>
                    </v-list-item-content>
                </template>

                <v-list-item :dark="appConfig.dark"
                             class="menu-item"
                             v-for="subItem in item.items"
                             :key="subItem.title"
                             :to="{ path: '/' + subItem.path }"
                >
                    <v-list-item-content>
                        <v-list-item-title :dark="appConfig.dark" v-bind:class="{ active: isActive }"
                                           v-text="subItem.title"></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-group>

            <v-list-item
                    :dark="appConfig.dark"
                    :to="{ path: '/' + item.path }"
                    v-else>
                <v-list-item-icon class="menu-icon">
                    <v-icon :dark="appConfig.dark" v-text="item.action"></v-icon>
                </v-list-item-icon>
                <v-list-item-content class="menu-title">
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>

        </v-list>

    </div>
</template>

<script>
    import menuAdmin from "../../../api/menuAdmin";
    import menuReseller from "../../../api/menuReseller";
    import menuDriver from "../../../api/menuDriver";
    import menuBranch from "../../../api/menuBranch";

    export default {
        props: {
            source: String
        },
        data: () => ({
            isActive: true,
            active: true,
            mini: false,
            navbar: true,
            items: ''
        }),
        methods: {
            goGroup(x, y) {
                let vm = this;
                vm.$router.push(`/${x}/${y}`);
            },
            goPage(x, y) {
                let vm = this;
                vm.$router.push(`/${x}/${y}`);
            },
            checkUserLevel(){
              if(this.$store.state.user.data.type ==='staff'){
                  if(this.$store.state.user.data.kiosko)
                  {
                      this.items = menuBranch
                  }else{

                this.items = menuAdmin
                  }
              }else if(this.$store.state.user.data.type ==='reseller'){
                this.items = menuReseller
              }else if(this.$store.state.user.data.type ==='driver'){
                this.items = menuDriver
              }
            }
        },
      mounted() {
        this.checkUserLevel()
        console.log(this.items);
      },
        computed: {},

    };
</script>


<style scoped>
    .menu-title {
        font-family: "Poppins";
        font-size: 16px !important;
        text-align: center;
        margin-left: 3px;
        width: 220px;
        font-weight: 300;
        text-decoration-line: none !important;
        line-height: 1rem;
    }

    .menu-subtitle {
        font-family: "Poppins";
        font-size: 15px !important;
        margin-left: 3px;
        text-decoration-line: none;
    }

    .menu-subtitle::before {
        list-style-type: circle;
    }

    .menu-subtitle::after {
        text-align: left;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 1px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #ccc;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #eee;
    }

    .v-list-item--dense .v-list-item__icon,
    .v-list--dense .v-list-item .v-list-item__icon {
        height: 18px;
        width: 18px;
        margin-top: 8px;
        margin-bottom: 8px;
        padding-left: 5px;
    }

    .v-application--is-ltr .v-list-item__action:first-child,
    .v-application--is-ltr .v-list-item__icon:first-child {
        margin-right: -10px;
    }

    .v-icon.v-icon {
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        display: -webkit-inline-box;
        display: -ms-inline-flexbox;
        /* display: inline-flex; */
        -webkit-font-feature-settings: "liga";
        font-feature-settings: "liga";
        font-size: 16px;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        letter-spacing: normal;
        margin-right: 0px;
        line-height: 1;
        text-indent: 0;
        -webkit-transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        transition: 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
        vertical-align: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .menu-subtitle {
        font-family: "Poppins";
        font-size: 14px;
        margin-left: 0px;
        -webkit-text-decoration-line: none;
        text-decoration-line: none;
        text-transform: capitalize;
    }
</style>

<!--====  End of STARTERLIST  ====-->