import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store"

//DASHBOARD
import Dashboard from '@/views/driver/dashboard/Dashboard'

//PAYMENTS

import Payments from '@/views/driver/payments/List'
//CREATE PAYMENT
import PaymentForm from '@/views/common/payments/Form'

//RETURNS

import Returns from '@/views/driver/returns/List'
import ReturnsForm from '@/views/driver/returns/Form'


Vue.use(VueRouter)

const routes = [
    {
        path: "/",
        name: "dashboard",
        component: Dashboard,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },
    {
        path: "/payments",
        name: "payments",
        component: Payments,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },
    {
        path: "/payment/edit/:id",
        name: "payment.edit",
        component: PaymentForm,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },
    {
        path: "/returns",
        name: "returns",
        component: Returns,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },

    {
        path: "/returns/create",
        name: "returns.create",
        component: ReturnsForm,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },


]


const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})





router.beforeEach((to, from, next) => {

    if (store.state.user.isLoggedIn)  {
                next()
               // alert('login' + store.state.user.isLoggedIn)
            }else{
                alert('No esta logueado, redireccionando...' + store.state.user.isLoggedIn)
                window.location.href = process.env.VUE_APP_BACKEND_URL + '/login';
            }
})

export default router