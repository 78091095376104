<template>
  <v-container fluid>

    <v-col cols="12">

      <v-row align="center" justify="space-between">
        <v-col cols="12" md="4">
          <h1 class="mb-0"
              :class="$vuetify.theme.isDark ? 'white--text' : ''" >
            Cupones
          </h1>
        </v-col>

        <v-col cols="8" md="7">
          <v-text-field hide-details dense outlined flat v-model="search" append-icon="mdi-magnify"
                        label="Buscar...">
          </v-text-field>
        </v-col>

        <v-col cols="4" md="1">
          <v-row justify="center">
            <v-btn v-if="$store.state.user.data.canCreateCoupons" fab small elevation="0" color="primary" @click="discountModal.visible=true">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-col>


    <v-card>
      <v-card-text>
        <v-row class="elevated-3">
          <v-col>
            <v-col xs="12" md="12" sm="12">

              <v-data-table :headers="headers" :items="sortedItems" :search="search">

                <template v-slot:item.discount="{item}">
                  <span v-if="item.method==='amount'">$</span>
                  {{item.discount}}
                  <span v-if="item.method==='percentage'">%</span>
                </template>

                <template v-slot:item.unused="{item}">
                  <v-icon v-if="item.unused" large color="success" >mdi-check-circle-outline</v-icon>
                  <v-icon v-else large color="grey" >mdi-close-circle-outline</v-icon>
                </template>

                <template v-slot:item.actions="{item}">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn class="mx-1" fab x-small color="warning" dark depressed
                             @click="editItem(item.id)" v-on="on">
                        <v-icon >mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>

              </v-data-table>

            </v-col>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
    <v-dialog v-model="discountModal.visible" max-width="700">
      <v-form class="pa-0" ref="form" v-model="validForm" lazy-validation>
      <v-card>
        <v-card-title><h6>Nuevo cupon de descuento</h6></v-card-title>
        <v-card-text>
          <v-col>
            <v-row><v-select :rules="emptyRule" v-model="discountModal.payload.method" label="Tipo" :items="discountModal.methods"></v-select></v-row>
            <v-row><v-text-field :rules="emptyRule" v-model="discountModal.payload.discount" type="number" label="Descuento"></v-text-field></v-row>
            <v-row><v-text-field :rules="emptyRule" v-model="discountModal.payload.note" label="Motivo"></v-text-field></v-row>
          </v-col>
        </v-card-text>

        <v-card-actions>
          <v-row>
          <v-col><v-btn color="primary" :disabled="!validForm && discountModal.payload.discount > 100" @click="validateForm">Guardar</v-btn></v-col>
          <v-col align="right"> <v-btn color="secondary" @click="discountModal.visible=false">Cancelar</v-btn></v-col>
          </v-row>
        </v-card-actions>
      </v-card>
      </v-form>
    </v-dialog>
  </v-container>
</template>

<script>
import StatusLabel from "@/components/helpers/statusLabel";
import moment from "moment";

export default {
  components: {StatusLabel},
  data() {
    return {
      validForm: false,
      items: [],
      search: '',
      emptyRule: [v => !!v || 'Necesario'],

      discountModal:{
        methods:[
          {'text': 'Porcentaje %', 'value':'percentage'},
          {'text': 'Importe $', 'value':'amount'}
        ],
        visible:false,
        payload:[],
      },
      headers: [{
        text: 'ID',
        align: 'center',
        value: 'id',
      },
        {
          text: 'Codigo',
          align: 'center',
          value: 'code',
        },

        {
          text: 'Descuento',
          align: 'center',
          value: 'discount'
        },

        {
          text: 'Vigente',
          align: 'center',
          value: 'unused'
        },

        {
          text: 'Acciones',
          align: 'center',
          value: 'actions'
        }
      ]
    };
  },
  computed: {
    sortedItems(){
      return this.items.sort((a, b) => (a.id < b.id) ? 1 : -1)
    }
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
       this.store()
      }
    },
    formatOnlyDate(value) {
      return moment(value).format("DD/MM/YYYY h:mm:ss a")
    },

    store() {
      let formData = new FormData();

      // Loops the payload and inserts it inside formData
      Object.entries(this.discountModal.payload).forEach(([key, value]) => {
        formData.append(key, value)
      })

      this.$axios
          .post('/admin/coupons', formData)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            console.log(response);
            if(response.data.success )
            {
              this.discountModal.visible = false
              this.getDataFromAPI()
              alert('Se generó el cupón codigo:' + response.data.msg )
            }
          })
          .catch((error)=>{
            console.log(error);
          });
    },

    getDataFromAPI() {
      this.$axios
          .get('admin/coupons')
          .then((response) => {
            this.items = response.data
            console.log(response.data)
          })
    }
  },
  mounted() {
    this.getDataFromAPI()
  }
}
</script>

<style scoped>

</style>