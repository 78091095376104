<template>
  <v-container fluid>
    <v-form class="pa-0" ref="form" v-model="validForm" lazy-validation>

      <v-toolbar flat color="transparent">

        <v-toolbar-title>
          <h2 v-if="!$route.params.id" :class="$vuetify.theme.isDark ? 'white--text' : ''">
            Crear operación de almacén
          </h2>

          <h2 v-else :class="$vuetify.theme.isDark ? 'white--text' : ''">
            Editar operación de almacén
          </h2>

        </v-toolbar-title>

        <v-spacer />

        <v-btn :disabled="!validForm" class="mx-1" elevation="0" v-if="payload.status === 'created'" color="primary" rounded @click="validateForm">
          Guardar
        </v-btn>

        <v-btn class="mx-1" elevation="0" color="error" rounded v-if="$route.params.id && payload.status !== 'canceled'" @click="destroyDialog = true">
          Cancelar
        </v-btn>

        <v-btn class="mx-1" elevation="0" color="success" rounded v-if="$route.params.id && payload.status !== 'processed' && payload.status !== 'canceled'" @click="process() ">
          Procesar
        </v-btn>

      </v-toolbar>


      <v-row>
        <v-col cols="12" >
          <v-card>
            <v-card-title class="pb-2">
              <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
                <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-clipboard-text</v-icon>
                Operacion de almacen numero: {{payload.id}} <v-chip color="orange" text-color="white">{{payload.status}}</v-chip>
              </h4>
            </v-card-title>

            <v-card-text>
              <v-row>

                <v-col cols="12" md="3">
                  <v-select :items="operation_types" :rules="emptyRule" hide-details dense outlined v-model="payload.type" label="Tipo"
                  item-text="text" item-value="value">
                  </v-select>
                </v-col>

                <v-col cols="12" md="3" v-if="payload.type==='OUT' || payload.type==='TRANSFER'">
                  <v-select :items="warehouses"  :rules="emptyRule" hide-details dense outlined v-model="payload.from_warehouse_id" label="Almacén Origen"
                            item-text="name" item-value="id">
                  </v-select>
                </v-col>

                <v-col cols="12" md="3" v-if="payload.type==='IN' || payload.type==='TRANSFER'">
                  <v-select :items="warehouses" :rules="emptyRule" hide-details dense outlined v-model="payload.to_warehouse_id" label="Almacén Destino"
                            item-text="name" item-value="id">
                  </v-select>
                </v-col>

                <v-col cols="12" md="6" >
                  <v-textarea rows="2" class="mx-2" v-model="payload.note" label="Observaciones" hide-details dense outlined></v-textarea>
                </v-col>

                <v-col cols="12" md="6" v-if="payload.origin">
                  <span v-if=" payload.origin.model==='SaleOrder'"> Origen: <router-link  :to="{ name: 'order.show', params: { id: payload.origin.id }}"> {{payload.origin.number}} (PEDIDO:{{payload.origin.id}}) </router-link></span>
                  <span v-if=" payload.origin.model==='BranchSaleOrder'"> Origen: <router-link  :to="{ name: 'branch.order.show', params: { id: payload.origin.id }}"> {{payload.origin.number}} (PEDIDO:{{payload.origin.id}}) </router-link></span>

                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-card>
            <v-card-title class="pb-2">
              <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
                <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-clipboard-text</v-icon>
                Detalle
              </h4>
              <v-col cols="4" md="1">
              <v-row justify="center">
              <v-btn fab small elevation="0" color="primary" @click="productDialog=true">
             <v-icon>mdi-plus</v-icon>
           </v-btn>
         </v-row>
       </v-col>
            </v-card-title>
            <v-card-text>
              <v-row>
                <template>
                  <v-expansion-panels>
                    <v-expansion-panel
                        v-for="(row,index) in payload.lines"
                        :key="index"
                    >
                      <v-expansion-panel-header>{{ row.product_name }}

                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-col cols="12">
                          <v-col md="12">
                          <v-row>
                          <v-col md="1" v-for="(item,index) in row.move_line">
                          <v-text-field v-model="item.quantity" hide-details dense outlined :label="item.text" ></v-text-field>
                        </v-col>
                          </v-row>
                        </v-col>

                        <v-col >
                          <v-card-actions>
                          <v-spacer></v-spacer>
                          <v-btn text color="secondary" @click="removeItem(index)">
                            <v-icon>mdi-delete-circle</v-icon>Eliminar
                          </v-btn>

                        </v-card-actions>
                        </v-col>
                      </v-col>

                      </v-expansion-panel-content>

                    </v-expansion-panel>
                  </v-expansion-panels>
                </template>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="productDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">Seleccione un producto</v-card-title>
          <v-card-text>
            <v-autocomplete v-model="selectedProduct" :items="products" item-value="id" item-text="name" label="Producto"></v-autocomplete>
          </v-card-text>
          <v-card-actions>
            <v-btn rounded color="primary" text @click="productDialog = false">
              Mejor no
            </v-btn>

            <v-btn elevation="0" text color="success" @click="addItem(selectedProduct)">
              Seleccionar
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>

      <v-dialog v-model="destroyDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">¿Estas seguro?</v-card-title>

          <v-card-text>
            Una vez elimines el registro, no podrás recuperarlo.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn rounded color="primary" text @click="destroyDialog = false">
              Mejor no
            </v-btn>

            <v-btn elevation="0" class="pa-4" rounded color="error" @click="destroy">
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>



    </v-form>
  </v-container>
</template>


<script>
export default {
  data() {
    return {
      destroyDialog: false,
      productDialog:false,
      validForm: true,
      emptyRule: [v => !!v || 'Necesario'],

      warehouses:null,
      operation_types:null,
      products:null,
      selectedProduct:null,

      payload: {
        id: null,
        type: 'IN',
        origin:{
          id:null,

        },
        from_warehouse_id: 1,
        to_warehouse_id: 1,
        note:null,
        status:'created',
        lines:[],
      }
    }
  },
  methods: {
    addItem(productId){
      const self = this
      if(productId){
        return this.products.filter(function(product){
          if(product.id ===productId){


          let variants=[]
         // console.log(product)
          product.variants.forEach((variant,index)=>{
            let row={
              order:variant.order,
              id : variant.id,
              text: variant.size,
              quantity:0,
            }
           // console.log('variants',variants);
            variants.unshift(row);
          })

          let sorted = variants.sort((a,b)=>{
            return a.order - b.order;
          })

            let row={
              product_id:product.id,
              product_name:product.name,
              move_line:sorted
            }
          self.payload.lines.unshift(row)
          //console.log(self.payload.lines)
          self.selectedProduct=null
          self.productDialog=false
          }
        })

      }
    },
    removeItem(index){
      this.payload.lines.splice(index, 1);
    },
    validateForm() {
      if (this.$refs.form.validate()) {
        (this.$route.params.id ? this.update() : this.store())
      }
    },
    create(){
      this.$axios
      .get('/admin/stockoperations/create')
      .then((response)=>{
        this.products = response.data.products
        this.operation_types = response.data.operation_types
        this.warehouses=response.data.warehouses
        //console.log(this.products);
      })
    },

    reload()
    {

    },

    edit() {
      this.$axios
          .get(`/admin/stockoperations/${this.$route.params.id}/edit`)
          .then((response) => {
            this.products = response.data.products
            this.operation_types = response.data.operation_types
            this.warehouses=response.data.warehouses
             console.log(response.data)
            this.payload = response.data.item
            this.payload.lines = response.data.lines
           // console.log(this.payload.lines)

          })
    },

    store() {
      let formData = new FormData();

      // Loops the payload and inserts it inside formData
      Object.entries(this.payload).forEach(([key, value]) => {
        formData.append(key, value)
      })
/*console.log(formData);
      return*/
      this.$axios
          .post('/admin/stockoperations', this.payload)
          .then((response) => {
             //console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/stockoperations') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
    },

    update(order = null) {

      if(order){
          this.payload.order_to_do = order
      }
      // Laravel put workaround
      this.payload._method = 'PUT'

      this.$axios
          .post(`/admin/stockoperations/${this.$route.params.id}`, this.payload)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/stockoperations') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
    },

    destroy() {
      let formData = new FormData();

      // Laravel delete workaround
      formData.append('_method', 'DELETE')

      this.$axios
          .post(`//${this.$route.params.id}`, formData)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/materials') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
    },
    process()
    {
      let formData = new FormData();

      // Loops the payload and inserts it inside formData

      this.$axios
          .post('admin/stockoperations/process', {'id': this.$route.params.id})
          .then((response) => {
            console.log('data',response)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.payload.status='processed' : '')

          })
          .catch((error) => {
            //console.log(error);
          });
    }
  },

  mounted() {
    if (this.$route.params.id) {
      this.edit()
    }else{
      this.create()
    }
  }
}
</script>