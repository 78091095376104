<template>
    <v-container fluid>

        <v-col cols="12">

            <v-row align="center" justify="space-between">
                <v-col cols="12" md="6">
                    <h1 class="mb-0"
                    :class="$vuetify.theme.isDark ? 'white--text' : ''" >
                        Reporte de Stock
                    </h1>

                </v-col>

                <v-col cols="12" md="6">
                  <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="filterModal.visible = true"
                  >
                    <v-icon dark>
                      mdi-filter
                    </v-icon>
                  </v-btn>
                </v-col>


            </v-row>
        </v-col>


        <v-card>
            <v-card-text>
                <v-row class="elevated-3">
                    <v-col>
                        <v-col xs="12" md="12" sm="12">


                            <v-data-table :items="items" :search="search">
                              <template v-slot:item="{ item }">
                                <tr>

                                  <td><strong>MODELO</strong></td>
                                  <td><strong>TIPO</strong></td>
                                  <td><strong>GENERO</strong></td>
                                  <td><strong>MARCA</strong></td>
                                  <td v-for="variant in item.variants"><strong>{{variant.size}}</strong></td>

                                </tr>
                                <tr>

                                  <td>{{item.model}}</td>
                                  <td>{{item.type.name}}</td>
                                  <td>{{item.gender.name}}</td>
                                  <td>{{item.brand.name}}</td>
                                  <td v-for="variant in item.variants" v-model.number="item.total = variant.stock">{{(variant.stock_variant_by_warehouse) ? variant.stock_variant_by_warehouse.available : 0}}</td>
                                </tr>


                              </template>


                            </v-data-table>

                        </v-col>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>

      <v-dialog v-model="filterModal.visible" max-width="800">
        <v-card>
          <v-card-text>
            <v-col v-if="!loading">
              <v-row>
                <v-col cols="12" md="4">
                  <v-select label="Almacén" v-model="filterModal.payload.warehouse_id" :items="filterModal.warehouses" item-text="name" item-value="id"></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select label="Producto" v-model="filterModal.payload.product_type_id" :items="filterModal.productTypes" item-text="name" item-value="id"></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select label="Corrida" v-model="filterModal.payload.size_collection_id" :items="filterModal.sizeCollections" item-text="name" item-value="id"></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete label="Marca" v-model="filterModal.payload.product_brand_id" :items="filterModal.productBrands" item-text="name" item-value="id"></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-text-field label="Modelo" v-model="filterModal.payload.model"></v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-select label="Genero" v-model="filterModal.payload.product_gender_id" :items="filterModal.productGenders" item-text="name" item-value="id"></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-autocomplete label="Categoría" v-model="filterModal.payload.product_category_id" :items="filterModal.productCategories" item-text="name" item-value="id"></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete label="Material" v-model="filterModal.payload.material_id" :items="filterModal.materials" item-text="name" item-value="id"></v-autocomplete>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete label="Color" v-model="filterModal.payload.color_id" :items="filterModal.colors" item-text="name" item-value="id"></v-autocomplete>
                </v-col>
              </v-row>
            </v-col>
            <v-col align="center" v-if="loading">
              <v-row>
                <v-col align="center">
              <img src="../../../../assets/img/logo.png" width="200"/>
                </v-col>
              </v-row>
              <v-progress-circular
                                   indeterminate
                                   color="red"
              ></v-progress-circular>
            </v-col>
          </v-card-text>
          <v-card-actions v-if="!loading">
            <v-btn color="primary" @click="generate" >Generar </v-btn>

            <v-col align="right">
            <v-btn color="secondary" @click="filterModal.visible=false"> Cancelar </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

</template>

<script>
    export default {
        data() {
            return {
              loading:false,
              filterModal:{
                visible:true,
                warehouses:[],
                productTypes:[],
                sizeCollections:[],
                productBrands:[],
                productGenders:[],
                productCategories:[],
                materials:[],
                colors:[],
                payload:{
                  warehouse_id:1,
                  product_type_id:null,
                  size_collection_id:null,
                  product_brand_id:null,
                  model:null,
                  product_gender_id:null,
                  product_category_id:null,
                  material_id:null,
                  color_id:null,


                },
              },
                items: [],
                search: '',

            };
        },
        computed: {
            sortedItems(){
                return this.items.sort((a, b) => (a.id < b.id) ? 1 : -1)
            }
        },
        methods: {
            generate() {
              this.loading=true
                console.log(this.filterModal.payload)
              this.$axios
                  .post('admin/reports/stock', this.filterModal.payload)
                  .then((response) => {
                    this.items = response.data
                    this.loading = false
                    this.filterModal.visible=false
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            },



            getDataFromAPI() {
                this.$axios
                    .get('admin/reports/stock')
                    .then((response) => {
                        this.filterModal.warehouses = response.data.warehouses
                      this.filterModal.productTypes = response.data.product_types
                      this.filterModal.sizeCollections = response.data.size_collections
                      this.filterModal.productBrands = response.data.brands
                      this.filterModal.productGenders = response.data.genders
                      this.filterModal.productCategories = response.data.categories
                      this.filterModal.materials = response.data.materials
                      this.filterModal.colors = response.data.colors
                    })
            }
        },
        mounted() {
            this.getDataFromAPI()
        }
    }
</script>