<template>
  <v-chip label small :color="color(status)">
    <small class="white--text">{{ this.text(status) }}</small>
  </v-chip>
</template>

<script>
export default {
  props: ['status'],
  methods:{
    color(status)
    {
      switch(this.status)
      {
        case 'created':
          return 'blue accent-1'
        case 'partially_applied':
          return 'blue accent-1'
        case 'applied':
          return 'blue accent-2'
        case 'processed':
          return 'blue accent-2'
        case 'received':
          return 'green lighten-1'
        case 'authorized':
          return 'green lighten-1'
        case 'reserved':
          return 'green lighten-1'
        case 'paid':
          return 'green lighten-1'
        case 'canceled':
          return 'red accent-2'
        case 'rejected':
          return 'red accent-2'
      }
    },
    text(status)
    {
      switch(this.status)
      {
        case 'created':
          return 'Creado'
        case 'partially_applied':
          return 'Aplicado Parcial'
        case 'applied':
          return 'Aplicado'
        case 'processed':
            return 'Procesado'
        case 'received':
          return 'Recibido'
        case 'authorized':
          return 'Autorizado'
        case 'reserved':
          return 'reservado'
        case 'paid':
          return 'Pagado'
        case 'canceled':
          return 'Cancelado'
        case 'rejected':
          return 'Rechazado'
      }
    }
  }
}
</script>

<style scoped>

</style>