<template>
    <v-container fluid>

        <v-col cols="12">

            <v-row align="center" justify="space-between">
                <v-col cols="12" md="6">
                    <h1 class="mb-0"
                        :class="$vuetify.theme.isDark ? 'white--text' : ''">
                        Reporte de Pagos
                    </h1>

                </v-col>


            </v-row>
        </v-col>


        <v-card>
            <v-card-text>
                <v-row class="elevated-3">
                    <v-col>
                        <v-col xs="12" md="12" sm="12">


                            <v-col>
                                <v-row>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                    >
                                        <v-date-picker
                                                v-model="payload.dates"
                                                range
                                        ></v-date-picker>
                                    </v-col>
                                    <v-col
                                            cols="12"
                                            sm="6"
                                    >
                                        <v-text-field
                                                v-model="dateRangeText"
                                                label="Rango de Fecha"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                        ></v-text-field>

                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-card-actions>
                                <v-btn v-if="payload.dates.length ===2" color="primary" @click="generate">Generar</v-btn>

                            </v-card-actions>

                        </v-col>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>


    </v-container>

</template>

<script>
    import moment from "moment";

    moment.locale('America/Mexico_City')
    export default {
        data() {
            return {
               payload: {
                    dates: [],
                },


            };
        },
        computed: {
            dateRangeText() {
                return this.payload.dates.join(' ~ ')
            },
        },
        methods: {
            formatOnlyDate(value) {
                return moment(value).format("DD/MM/YYYY h:mm:ss a")
            },
            generate() {

                this.$axios
                    .post('admin/reports/payments',this.payload,{responseType: 'blob'})
                    .then((response) => {
                        //let url = window.URL.createObjectURL(new Blob([response.data]))
                        //console.log( url);
                        let url = window.URL.createObjectURL(new Blob([response.data]));
                        let link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download',  'Reporte de pagos.pdf');
                        document.body.appendChild(link)
                        link.click()

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },


        },
        mounted() {

        }
    }
</script>