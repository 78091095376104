<!--===============================
=           Starteravatar           =
================================-->
<template>
    <div>
      <v-menu transition="scroll-y-transition">
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-icon>mdi-account</v-icon>
                </v-btn>
            </template>
            <v-list>
                <v-list-item-group color="primary">
                  <v-list-item  @click="logout()">
                    <v-list-item-icon>
                      <v-icon>mdi-logout</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title >Salir</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-menu>
    </div>
</template>
<script>
export default {
    name: "starter-avatar",
    data: () => ({
        searchActive: false,

        sheet: false,
        itemsAccount: [{
                text: "Perfil",
                img: "mdi-account",
            },
            {
                text: "Salir",
                img: "mdi-logout",
            },

        ],

    }),
  methods:{
    logout() {
      let formData = new FormData();

      this.$axios
          .post(process.env.VUE_APP_BACKEND_URL + '/logout')
          .then((response) => {
            this.$store.state.user.isLoggedIn=false
            this.$store.state.user.data = []
            window.location.href = process.env.VUE_APP_BACKEND_URL + '/login';

          })
          .catch((error)=>{
            console.log(error);
          });
    },
  }
};
</script>
<style></style>
<!--===============================
=           Starteravatar  end          =
================================-->