<template>
  <v-col xs="12" sm="12" md="12">
    <v-card>
      <v-card-title> Estado de cuenta</v-card-title>

      <v-card-text>
        <v-data-table :items="items" :headers="headers">

          <template v-slot:item.detail="props">
            <v-btn   dark small color="green" @click="loadModal(props.item)">
              <v-icon dark>mdi-eye</v-icon>
            </v-btn>
          </template>

        </v-data-table>
      </v-card-text>
    </v-card>

    <v-dialog max-width="800" v-model="modal.visible">
      <v-card>
        <v-card-title>Detalle de operación</v-card-title>

        <v-card-text>
          <v-data-table :items="detailItems" :headers="detailHeaders">

          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-col>
</template>

<script>
export default {
  data(){
    return{
      modal:{
        visible:false
      },
      items: [],
      detailItems:[],
      headers: [{
        text: 'ID',
        align: 'center',
        value: 'id',
      },
        {
          text: 'Folio',
          align: 'center',
          value: 'number',
        },

        {
          text: 'Total',
          align: 'center',
          value: 'total',
        },

        {
          text: 'Saldo',
          align: 'center',
          value: 'balance',
        },

        {
          text: 'Detalle',
          align: 'center',
          value: 'detail'
        }
      ],
      detailHeaders:[
        {text:'id',
        align:'left',
        value:'id'
        },
        {text:'Concepto',
          align:'left',
          value:'concept'
        },
        // {text:'Fracción',
        //   align:'left',
        //   value:'fraction_number'
        // },
        // {text:'# Fracciones',
        //   align:'left',
        //   value:'total_fractions'
        // },
        {text:'Importe',
          align:'left',
          value:'amount'
        },
        {text:'Saldo',
          align:'left',
          value:'balance'
        },

      ]

    }
  },
  methods:{
    getDataFromAPI(id)
    {
      this.$axios
          .get(`/admin/accountstatus/${id}`)
          .then((response) => {
            this.items = response.data
            console.log('response',response.data)
          })
    },
    getDetailsFromAPI(saleOrderId)
    {
      this.$axios
          .get(`/admin/accountstatusdetails/${saleOrderId}`)
          .then((response) => {
            this.detailItems = response.data

          })
    },
    loadModal(item)
    {
      let saleOrderId = item.id
      console.log('item',saleOrderId)
      this.getDetailsFromAPI(saleOrderId)
      this.modal.visible=true
    }
  }
}
</script>

<style scoped>

</style>