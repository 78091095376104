<template>
    <div>
        <v-row>
            <v-col cols="12" md="12" align="centerya t">
                <h3>Hola! {{payload.resellerName}}</h3>
            </v-col>
        </v-row>
    <v-row>
        <v-col cols="12" md="3">
    <v-card color="#952175" dark>
        <v-card-title>
            <v-icon large left>
                mdi-account
            </v-icon>
            <span class="text-h6 font-weight-light">Clientes</span>
        </v-card-title>

        <v-card-text class="text-h5 font-weight-bold text-center">
            {{payload.customersCount}}
        </v-card-text>

        <v-card-actions>
                <v-col cols="12" md="12" align="center"> <v-btn @click="$router.push('customers')"> VER MIS CLIENTES </v-btn></v-col>
        </v-card-actions>
    </v-card>

        </v-col>


        <v-col cols="12" md="3">
            <v-card color="#385F73" dark>
                <v-card-title>
                    <v-icon large left>
                        mdi-printer-pos
                    </v-icon>
                    <span class="text-h6 font-weight-light ">Pedidos</span>
                </v-card-title>

                <v-card-text class="text-h5 font-weight-bold text-center">
                    {{payload.salesCount}}
                </v-card-text>

                <v-card-actions>
                    <v-col cols="12" md="12" align="center"> <v-btn @click="$router.push('sales')"> VER MIS PEDIDOS </v-btn></v-col>
                </v-card-actions>
            </v-card>

        </v-col>


    </v-row>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                payload:{
                    resellerName:0,
                    customersCount:0,
                    salesCount:0,
                }
            }
        },
        mounted() {
            this.getDataFromAPI();
        },
        methods: {
            getDataFromAPI() {
                this.$axios
                    .get('reseller/dashboard')
                    .then((response) => {
                        console.log(response);
                        this.payload.resellerName = response.data.reseller_name;
                        this.payload.customersCount = response.data.customers_count;
                        this.payload.salesCount = response.data.sales_count;
                    })
            }
        }
    }
</script>

<style scoped>

</style>