<template>
  <v-container fluid>

    <v-col cols="12">

      <v-row align="center" justify="space-between">
        <v-col cols="12" md="4">
          <h1 class="mb-0"
              :class="$vuetify.theme.isDark ? 'white--text' : ''" >
            Pedidos por surtir
          </h1>
          <button v-if="idOrders.length > 0" :disabled="loading" class="btn btn-primary btn-rounded" @click="actionProcess">Hacer pedidos</button>
          
        </v-col>

        <v-col cols="8" md="7">
          <v-text-field hide-details dense outlined flat v-model="search" append-icon="mdi-magnify"
                        label="Buscar...">
          </v-text-field>
        </v-col>
 
      </v-row>
    </v-col>


    <v-card>
      <v-card-text>
        <v-row class="elevated-3">
          <v-col>
            <v-col xs="12" md="12" sm="12">

              <v-data-table :headers="headers" :items="items" :search="search" :options.sync="pagination" :server-items-length="total_items"
              :single-select="!search"
              show-select
              
              v-model="idOrders">
                
                <template v-slot:item.status="{item}">
                  <StatusLabel :status="item.status"></StatusLabel>
                </template>

                <template v-slot:item.created_at="{item}">
                  {{formatOnlyDate(item.created_at)}}
                </template>

                <!-- <template v-slot:item.actions="{item}">
                   <input v-model="idOrders" type="checkbox" :value="item.id">
                </template> -->

              </v-data-table>
              

            </v-col>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
    <template>
    <div class="container">
      
        <LoadingComponent :show="loading"></LoadingComponent>
       
    </div>
    </template>
  </v-container>
  
</template>

<script>
import StatusLabel from "@/components/helpers/statusLabel";
import LoadingComponent from "@/components/helpers/LoadingComponent";
import moment from 'moment'
moment.locale('America/Mexico_City')

export default {
  components: {StatusLabel, LoadingComponent},
  data() {
    return {
      selected: [],
      idOrders: [],
      loading: false,
      items: [],
      total_items:0,
      search: '',
      pagination: {
        sortBy:['id'],
        sortDesc:['true']
      },
      headers: [ 
        {
            text: 'ID',
            align: 'center',
            value: 'id',
            sortable: true,
        },
       

        {
          text: 'Distribuidor',
          align: 'center',
          value: 'sale_order.reseller.name',
        },
        {
          text: 'Descripcion',
          align: 'center',
          value: 'description'
        },

        {
          text: 'Folio',
          align: 'center',
          value: 'sale_order.number'
        },

        {
          text: 'Fecha',
          align: 'center',
          value: 'created_at'
        },

        {
          text: 'Status',
          align: 'center',
          value: 'status'
        }
       
      ]
    };
  },

  watch: {
    pagination: {
      handler() {
        this.getDataFromAPI();
        console.log(this.pagination);
      },
    },
    search:{
      handler() {
        this.getDataFromAPI();
      }
    },
     
  },
  methods: {
    
    formatOnlyDate(value) {
      return moment(value).format("DD/MM/YYYY h:mm:ss a")
    },

    editItem(id) {
      this.$router.push(`/order/${id}`)
    },

    getDataFromAPI() {

      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

      var params = {
        sort_by: sortBy,
        descending: sortDesc,
        page: page,
        rowsPerPage: itemsPerPage,
        search: this.search
      };


      this.$axios
          .get('admin/saleordersLine',{
            params: params,
          })
          .then((response) => {
              this.items = response.data.sale_orders.data
            console.log(this.items);
            this.total_items = response.data.sale_orders.total

          })
    },
     
    async actionProcess(){
       
        this.loading = true;
        this.$axios
          .post('/admin/saleOrderLine',{
            idOrders: this.idOrders,
          })
          .then((response) => {
              // this.items = response.data.sale_orders.data
            if (response.data.success) {
               this.getDataFromAPI()
               this.loading = false
               this.idOrders = []
               this.$store.commit('OPEN_SNACKBAR', response.data);
            }
            // this.total_items = response.data.sale_orders.total

          })
    }
       
  },
  mounted() {

    this.getDataFromAPI()
  }
}
</script>
 