<template>
  <v-container fluid>

    <v-col cols="12">

      <v-row align="center" justify="space-between">
        <v-col cols="12" md="4">
          <h1 class="mb-0"
              :class="$vuetify.theme.isDark ? 'white--text' : ''" >
            Ordenes de pago
          </h1>
        </v-col>

        <v-col cols="8" md="7">
          <v-text-field hide-details dense outlined flat v-model="search" append-icon="mdi-magnify"
                        label="Buscar...">
          </v-text-field>
        </v-col>

        <v-col cols="4" md="1">
          <v-row justify="center">
            <v-btn fab small elevation="0" color="primary" @click="createItem()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-col>


    <v-card>
      <v-card-text>
        <v-row class="elevated-3">
          <v-col>
            <v-col xs="12" md="12" sm="12">

              <v-data-table :headers="headers" :items="sortedItems" :search="search">

                <template v-slot:item.status="{item}">
                  <StatusLabel :status="item.status"></StatusLabel>
                </template>

                <template v-slot:item.actions="{item}">
                  <v-menu>
                    <template v-slot:activator="{ on }">
                      <v-btn icon v-on="on">
                        <v-icon>more_vert</v-icon>
                      </v-btn>
                    </template>
                    <v-list>

                      <v-list-item v-if="item.status === 'created'" @click="cancel(item)">
                        <v-list-item-title>
                          <v-icon small class="mr-2"> cancel </v-icon>
                          Cancelar
                        </v-list-item-title>
                      </v-list-item>

                      <v-list-item @click="edit(item.id)">
                        <v-list-item-title>
                          <v-icon small class="mr-2"> mdi-eye </v-icon>
                          Detalle
                        </v-list-item-title>
                      </v-list-item>



                    </v-list>
                  </v-menu>
                </template>

              </v-data-table>

            </v-col>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>


  </v-container>

</template>

<script>
import StatusLabel from "../../../components/helpers/statusLabel";
export default {
  components: {StatusLabel},
  data() {
    return {
      items: [],
      search: '',
      headers: [{
        text: 'ID',
        align: 'center',
        value: 'id',
      },
        {
          text: 'Fecha',
          align: 'center',
          value: 'date',
        },
        {
          text: 'Cliente',
          align: 'center',
          value: 'customer_name',
        },

        {
          text: 'Usuario',
          align: 'center',
          value: 'user_name'
        },
        {
          text: 'Importe',
          align: 'center',
          value: 'amount'
        },
        {
          text: 'Saldo',
          align: 'center',
          value: 'balance'
        },
        {
          text: 'Estatus',
          align: 'center',
          value: 'status'
        },

        {
          text: 'Acciones',
          align: 'center',
          value: 'actions'
        }
      ],

    };
  },
  computed: {
    sortedItems(){
      return this.items.sort((a, b) => (a.id < b.id) ? 1 : -1)
    }
  },
  methods: {

    getDataFromAPI() {
      this.$axios
          .get('admin/paymentorders')
          .then((response) => {
            this.items = response.data

          })
    },

    createItem()
    {
      this.$router.push('/paymentorders/create')
    },
    edit(id) {

      this.$router.push('/payment/edit/' + id);

    },
    cancel(item)
    {
      this.$axios
          .post('admin/paymentorder/cancel', {id: item.id})
          .then((response) => {
             console.log(response)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success)? this.getDataFromAPI():''
          //console.log(this.payload.id);
          })
          .catch((error)=>{
            console.log(error);
          });
    }

  },
  mounted() {
    this.getDataFromAPI()
  }
}
</script>