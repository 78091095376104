<!--==============================
=            settings            =
===============================-->

<template>
  <div class="settings" clipped-right app :dark="appConfig.dark">
    <v-col>
      <v-list-item three-line>
        <v-list-item-content>
          <div class="overline mb-1">Configuración</div>
          <div class="pa-1">
            <v-switch
              v-model="$vuetify.theme.dark"
              inset
              primary
              label="Modo oscuro"
            />
          </div>

          <div class="pa-1">
            <v-switch
              v-model="appConfig.expandOnHover"
              inset
              :label="`Mini Menu`"
            ></v-switch>
          </div>
          <div class="pa-1">
            <v-switch
              v-model="appConfig.footer.inset"
              inset
              label="Footer"
            ></v-switch>
          </div>

          <div class="overline mb-1">Drawer</div>
          <v-radio-group v-model="appConfig.type" column>
            <v-radio
              v-for="drawer in appConfig.drawers"
              :key="drawer"
              :label="drawer"
              :value="drawer.toLowerCase()"
              primary
            />
          </v-radio-group>
        </v-list-item-content>
      </v-list-item>
    </v-col>
  </div>
</template>
<script>
export default {
  name: "starter-settings"
};
</script>
<style scoped>
/* APPSETTINGS */
.settings {
  overflow: hidden !important;
  position: absolute;
  width: 100%;
  margin: 0px;
  padding: 0px;
}

.color-option {
  width: 100%;
}

.color-option--label {
  position: relative;
  display: block;
  cursor: pointer;
}

.color-option--label input[type="radio"] {
  display: none;
}

.color-option--label input[type="radio"] + span {
  position: relative;
}

.color-option--label input[type="radio"] + span > .overlay {
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 25px;
  height: 25px;
  background-color: rgba(0, 0, 0, 0.3);
  text-align: center;
  line-height: 30px;
}

.color-option--label input[type="radio"]:checked + span > .overlay {
  display: block;
}

.color-option--item {
  overflow: hidden;
  display: block;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  margin-bottom: 15px;
}

.color-option--item--header {
  height: 10px;
}

.color-option--item > span {
  display: block;
  float: left;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
</style>

<!--====  End of settings  ====-->
