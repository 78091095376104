<template>
  <v-container fluid>

    <v-col cols="12">

      <v-row align="center" justify="space-between">
        <v-col cols="12" md="4">
          <h1 class="mb-0"
              :class="$vuetify.theme.isDark ? 'white--text' : ''" >
            Devoluciones
          </h1>
        </v-col>

        <v-col cols="8" md="7">

        </v-col>

        <v-col cols="4" md="1">
          <v-row justify="center">
            <v-btn fab small elevation="0" color="primary"
                   @click="create()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </v-col>

      </v-row>
    </v-col>


    <v-card>
      <v-card-text>
        <v-row class="elevated-3">
          <v-col>
            <v-col xs="12" md="12" sm="12">

              <v-data-table :headers="headers" :items="sortedItems" disable-sort>

              </v-data-table>

            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog max-width="400" v-model="showDialog">
      <v-card>
        <v-card-title>Registrar Pago</v-card-title>
        <v-card-text>
          <v-form class="pa-0" ref="form" v-model="validForm" lazy-validation>
          <v-row>
            <v-col><v-autocomplete :rules="emptyRule" label="Distribuidor" :items="resellers" item-text="name" item-value="id" v-model="payload.reseller_id"></v-autocomplete></v-col>
          </v-row>


          </v-form>
        </v-card-text>


        <v-card-actions>
          <v-col align="left"><v-btn color="primary" v-if="modalChecked" @click="validateForm">Recibir Pago</v-btn></v-col>
          <v-col align="right"><v-btn color="error" @click="showDialog=false">Cancelar</v-btn></v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>

</template>

<script>
export default {
  data() {
    return {
      validForm: true,
      emptyRule: [v => !!v || 'Necesario'],
      showDialog:false,
      modalChecked:false,
      resellers:null,
      paymentMethods:null,
      payload:{
        'reseller_id':null,
        'payment_method':'cash',
        'amount':'0.00',
      },

      items: [],
      total:0.00,
      search: '',
      headers: [{
        text: 'ID',
        align: 'center',
        value: 'id',
      },
        {
          text: 'Nombre',
          align: 'center',
          value: 'reseller.name',
        },

        {
          text: 'Importe',
          align: 'center',
          value: 'amount'
        },
        {
          text: 'Saldo',
          align: 'center',
          value: 'balance'
        },

        {
          text: 'Fecha',
          align: 'center',
          value: 'created_at'
        }
      ]
    };
  },
  computed: {
    sortedItems(){
      return this.items.sort((a, b) => (a.id < b.id) ? 1 : -1)
    }
  },
  methods: {
    validateForm() {
      if (this.$refs.form.validate()) {
         this.store()
      }
    },

    clearForm(){
      this.payload={
        'reseller_id':null,
        'payment_method':'cash',
        'amount':'0.00',}
    },

    create() {
      this.clearForm()
      this.$axios
          .get('/driver/returns/create')
          .then((response) => {
            this.resellers = response.data.resellers
            this.$router.push('/returns/create')
          })

    },

    store() {
      let formData = new FormData();

      // Loops the payload and inserts it inside formData
      Object.entries(this.payload).forEach(([key, value]) => {
        formData.append(key, value)
      })

      this.$axios
          .post('/driver/payments', formData)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            this.showDialog=false
            this.getDataFromAPI()

          })
          .catch((error)=>{
            console.log(error);
          });
    },


    getDataFromAPI() {
      this.$axios
          .get('driver/returns')
          .then((response) => {
            this.items = response.data

          })
    }
  },
  mounted() {
    this.getDataFromAPI()
  }
}
</script>