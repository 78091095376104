import Vue from "vue"
import VueRouter from "vue-router"
import store from "../store"

//DASHBOARD
import Dashboard from '@/views/admin/dashboard/Dashboard'

//CATEGORIES
import CategoriesList from '@/views/admin/categories/List'
import CategoriesForm from '@/views/admin/categories/Form'

//MATERIALS
import MaterialsList from '@/views/admin/materials/List'
import MaterialsForm from '@/views/admin/materials/Form'

//COLORS
import ColorsList from '@/views/admin/colors/List'
import ColorsForm from '@/views/admin/colors/Form'

//BRANDS
import BrandsList from '@/views/admin/brands/List'
import BrandsForm from '@/views/admin/brands/Form'

//SIZE COLLECTIONS
import SizeCollectionList from '@/views/admin/sizesCollections/List'
import SizeCollectionForm from '@/views/admin/sizesCollections/Form'

//PRODUCTS
import ProductsList from '@/views/admin/products/List'
import ProductsForm from '@/views/admin/products/Form'

//Routes
import RouteList from '@/views/admin/routes/List'
import LocalityList from '@/views/admin/routeLocalities/List'

//Users
import UsersList from '@/views/admin/users/List'
import UsersForm from '@/views/admin/users/Form'

//SETTINGS

//Table Master
import TablePriceMaster from '@/views/admin/settings/tableprice/master'

// Price Factors
import PriceFactorsList from '@/views/admin/settings/priceFactors/List'
import PriceFactorsForm from '@/views/admin/settings/priceFactors/Form'

//STOCK
//Warehouses

import WarehousesList from '@/views/admin/warehouses/List'

//Stock Operations

import StockOperationsList from '@/views/admin/stockOperations/List'
import StockOperationsForm from '@/views/admin/stockOperations/Form'

//Sliders

import SliderList from '@/views/admin/store/slider/List'
import SliderForm from '@/views/admin/store/slider/Form'

//Branches
import BranchList from '@/views/admin/branches/List'
import BranchForm from '@/views/admin/branches/Form'
// Admin Sale Orders
import SaleOrderList from '@/views/admin/saleOrders/List'
import SaleOrderShow from '@/views/admin/saleOrders/Show'
import SaleOrderLineList from '../../views/admin/SeleOrderLine/List.vue'

//Admin Branch Sale Orders
import BranchSaleOrderList from '@/views/admin/branchSaleOrders/List'
import BranchSaleOrderShow from '@/views/admin/branchSaleOrders/Show'
//Payment Orders
import PaymentOrderList from '@/views/admin/paymentOrders/List'
import PaymentOrderForm from '@/views/admin/paymentOrders/Form'

//Deliver Payments
import DeliverPayments from '@/views/admin/deliverPayments/List'

//Payments common form
import PaymentForm from '@/views/common/payments/Form'

//Returns
import Returns from '@/views/admin/returns/List'
//Coupons
import Coupons from '@/views/admin/coupons/List'
//Stock Report
import StockReport from '@/views/admin/reports/stock/List'

//Unavailable Report
import UnavailableReport from '@/views/admin/reports/unavailable/List'

//Payments Report
import PaymentsReport from '@/views/admin/reports/payments/List'



Vue.use(VueRouter)

const routes = [

    {
        path: "/",
        name: "home",
        component: Dashboard,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },

    {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/categories",
        name: "categories.list",
        component: CategoriesList,
        meta: {
            breadcrumb: true
        }
    },
    {
        path: "/categories/create",
        name: "categories.create",
        component: CategoriesForm,
        meta: {
            breadcrumb: true
        }
    },
    {
        path: "/categories/edit/:id",
        name: "categories.edit",
        component: CategoriesForm,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/materials",
        name: "materials.list",
        component: MaterialsList
    },
    {
        path: "/materials/create",
        name: "materials.create",
        component: MaterialsForm
    },
    {
        path: "/materials/edit/:id",
        name: "materials.edit",
        component: MaterialsForm
    },

    {
        path: "/colors",
        name: "colors.list",
        component: ColorsList
    },
    {
        path: "/colors/create",
        name: "colors.create",
        component: ColorsForm
    },
    {
        path: "/colors/edit/:id",
        name: "colors.edit",
        component: ColorsForm
    },

    {
        path: "/brands",
        name: "brands.list",
        component: BrandsList
    },
    {
        path: "/brands/create",
        name: "brands.create",
        component: BrandsForm
    },
    {
        path: "/brands/edit/:id",
        name: "brands.edit",
        component: BrandsForm
    },


    {
        path: "/size-collections",
        name: "size-collections.list",
        component: SizeCollectionList,
    },
    {
        path: "/size-collections/create",
        name: "size-collections.create",
        component: SizeCollectionForm,
    },
    {
        path: "/size-collections/edit/:id",
        name: "size-collections.edit",
        component: SizeCollectionForm,
    },

    // **********

    {
        path: "/products",
        name: "products.list",
        component: ProductsList,
    },
    {
        path: "/products/create",
        name: "products.create",
        component: ProductsForm,
    },
    {
        path: "/products/edit/:id",
        name: "products.edit",
        component: ProductsForm,
    },

    //Delivery Routes
    {
        path: "/routes",
        name: "routes.list",
        component: RouteList,
    },
    //Delivery Routes Localities
    {
        path: "/localities",
        name: "localities.list",
        component: LocalityList,
    },


    //USERS

    {
        path: "/users",
        name: "users.list",
        component: UsersList,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/users/create",
        name: "users.create",
        component: UsersForm,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/users/edit/:id",
        name: "users.edit",
        component: UsersForm,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/tableprice",
        name: "tableprice.master",
        component: TablePriceMaster,
        meta: {
            breadcrumb: true
        }
    },
    {
        path: "/pricefactors",
        name: "pricefactors.list",
        component: PriceFactorsList,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/pricefactors/create",
        name: "pricefactors.create",
        component: PriceFactorsForm,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/pricefactors/edit/:id",
        name: "pricefactors.edit",
        component: PriceFactorsForm,
        meta: {
            breadcrumb: true
        }
    },

    //WareHouse

    {
        path: "/warehouses",
        name: "warehouses.list",
        component: WarehousesList,
        meta: {
            breadcrumb: true
        }
    },

    //Stock Operations

    {
        path: "/stockoperations",
        name: "stockoperations.list",
        component: StockOperationsList,
        meta: {
            breadcrumb: true
        }

    },
    {
        path: "/stockoperations/create",
        name: "stockoperations.create",
        component: StockOperationsForm,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/stockoperations/edit/:id",
        name: "stockoperations.edit",
        component: StockOperationsForm,
        meta: {
            breadcrumb: true
        }
    },

    //Sliders

    {
        path: "/sliders",
        name: "sliders.list",
        component: SliderList,
        meta: {
            breadcrumb: true
        }

    },
    {
        path: "/sliders/create",
        name: "sliders.create",
        component: SliderForm,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/sliders/edit/:id",
        name: "sliders.edit",
        component: SliderForm,
        meta: {
            breadcrumb: true
        }
    },
    {
        path: "/branches",
        name: "branches.list",
        component: BranchList,
        meta: {
            breadcrumb: true
        }
    },
    {
        path: "/branches/create",
        name: "branches.create",
        component: BranchForm,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/branches/edit/:id",
        name: "branches.edit",
        component: BranchForm,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/orders",
        name: "orders.list",
        component: SaleOrderList,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/orders/supply",
        name: "orders.list",
        component: SaleOrderLineList,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/order/:id",
        name: "order.show",
        component: SaleOrderShow,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/branch/orders",
        name: "branch.orders.list",
        component: BranchSaleOrderList,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/branch/order/:id",
        name: "branch.order.show",
        component: BranchSaleOrderShow,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/deliverpayments",
        name: "deliverPayments.list",
        component: DeliverPayments,
        meta: {
            breadcrumb: true
        }
    },
    {
        path: "/paymentorders",
        name: "paymentorders.list",
        component: PaymentOrderList,
        meta: {
            breadcrumb: true
        }
    },
    {
        path: "/paymentorders/create",
        name: "paymentorders.create",
        component: PaymentOrderForm,
        meta: {
            breadcrumb: true
        }
    },
    {
        path: "/payment/edit/:id",
        name: "payment.edit",
        component: PaymentForm,
        meta: {
            breadcrumb: true,
            transition: "zoom",
        }
    },
    {
        path: "/returns",
        name: "returns.list",
        component: Returns,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/coupons",
        name: "coupons.list",
        component: Coupons,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/report/stock",
        name: "stock.report.list",
        component: StockReport,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/report/unavailable",
        name: "unavailable.report.list",
        component: UnavailableReport,
        meta: {
            breadcrumb: true
        }
    },

    {
        path: "/report/payments",
        name: "payments.report.list",
        component: PaymentsReport,
        meta: {
            breadcrumb: true
        }
    },



]




const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
})



/*router.beforeEach((to, from, next) => {

        if (!store.state.user.isLoggedIn)  {
            console.log('isloginned', store.state.user.isLoggedIn)
           store.dispatch('getUser').then((res) => {
                if (store.state.user.isLoggedIn) {
                    next()
                    alert('login' + store.state.user.isLoggedIn)
                }else{
                    alert('No Login' + store.state.user.isLoggedIn)
                    next(process.env.VUE_APP_BACKEND_URL + '/login')
                }
            })
            //window.location.href = process.env.VUE_APP_BACKEND_URL + '/login';
            //next(`${process.env.VUE_APP_BACKEND_URL}/ URL`)
        }else{
            next()
        }
})*/

router.beforeEach((to, from, next) => {

    if (store.state.user.isLoggedIn)  {
                next()
               // alert('login' + store.state.user.isLoggedIn)
            }else{
                alert('No esta logueado, redireccionando...' + store.state.user.isLoggedIn)
                window.location.href = process.env.VUE_APP_BACKEND_URL + '/login';
            }
})

export default router