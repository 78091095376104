<template>
    <v-container fluid>
         <v-col cols="12">

            <v-row align="center" justify="space-between">
                <v-col cols="12" md="4">
                    <h1 class="mb-0">
                        Productos
                    </h1>
                </v-col>

                <v-col cols="8" md="7">
                    <v-text-field hide-details dense outlined flat v-model="search" append-icon="mdi-magnify"
                        label="Buscar...">
                    </v-text-field>
                </v-col>

                <v-col cols="4" md="1">
                    <v-row justify="center">
                        <v-btn fab small elevation="0" color="primary"
                            @click="createItem()">
                            <v-icon>mdi-plus</v-icon>
                        </v-btn>
                    </v-row>
                </v-col>
            </v-row>
        </v-col>

        <v-card>
            <v-card-text>
                <v-row class="elevated-3">
                    <v-col>
                        <v-col xs="12" md="12" sm="12">

                            <v-data-table :headers="headers" :items="items" :search="search">

                                <template v-slot:item.actions="{item}">

                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                            <v-btn fab x-small color="warning" dark depressed
                                                @click="editItem(item.id)"  v-on="on">
                                                <v-icon>mdi-dots-horizontal</v-icon>
                                            </v-btn>
                                        </template>
                                        <span>Editar</span>
                                    </v-tooltip>
                                </template>
                                
                                <template v-slot:item.colors="{item}">
                                    <v-row v-if="item.colors[0]" justify="center">
                                        <v-icon small :key="index" v-for="(color, index) in item.colors.filter(e => e.enabled)" large :color="color.code" >mdi-checkbox-blank-circle</v-icon>
                                    </v-row>
                                </template>
                                
                                <template v-slot:item.cost="{item}">
                                    <span> {{`${formatPrice(item.cost)}` }}</span>
                                </template>

                              <template v-slot:item.price="{item}">
                                <span> {{`${formatPrice(item.price)}` }}</span>
                              </template>

                                <template v-slot:item.enabled="{item}">
                                    <v-icon v-if="item.enabled" large color="success" >mdi-check-circle-outline</v-icon>
                                    <v-icon v-else large color="grey" >mdi-close-circle-outline</v-icon>
                                </template>

                            </v-data-table>

                        </v-col>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                items: [],
                search: '',
                headers: [
                    // {
                    //     text: 'ID',
                    //     align: 'center',
                    //     value: 'id',
                    // },
                    {
                        text: 'Nombre',
                        align: 'center',
                        value: 'name',
                    },

                    {
                        text: 'Tipo',
                        align: 'center',
                        value: 'type.name',
                    },

                    {
                        text: 'Marca',
                        align: 'center',
                        value: 'brand.name',
                    },
                    
                    {
                        text: 'Genero',
                        align: 'center',
                        value: 'gender.name',
                    },


                    {
                        text: 'Categoria',
                        align: 'center',
                        value: 'category.name',
                    },

                    {
                        text: 'Corrida',
                        align: 'center',
                        value: 'size_collection.name',
                    },



                    {
                        text: '$ Costo',
                        align: 'center',
                        value: 'cost',
                    },

                  {
                    text: '$ Precio',
                    align: 'center',
                    value: 'price',
                  },

                  {
                    text: 'Activo',
                    align: 'center',
                    value: 'enabled'
                  },


                    {
                        text: 'Acciones',
                        align: 'center',
                        value: 'actions'
                    }
                ],

                route_get: '/admin/products',
            };
        },

        methods: {
            createItem() {
                this.$router.push('/products/create')
            },
            editItem(id) {
                this.$router.push(`/products/edit/${id}`)
            },

            getDataFromAPI() {
                this.$axios
                    .get(this.route_get)
                    .then((response)=>{
                        this.items = response.data
                        // console.log(this.items);
                    })
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(2).replace('.', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
        },
        mounted() {
            this.getDataFromAPI()
        },

    }
</script>