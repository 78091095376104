<template>
  <v-stepper
      v-model="e6"
      vertical
  >
    <v-stepper-step
        :complete="e6 > 1"
        step="1"
    >
      Seleccione al distribuidor
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card
          class="mb-12"
          height="200px"
      >
        <v-card-text>
          <v-row>
            <v-col>
              <v-autocomplete label="Distribuidor" :items="resellers" item-text="name" item-value="id"
                              v-model="payload.reseller_id"></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>

      </v-card>
      <v-btn
          v-if="payload.reseller_id"
          color="primary"
          @click="getOrdersFromAPI"
      >
        Siguiente
      </v-btn>

    </v-stepper-content>

    <v-stepper-step
        :complete="e6 > 2"
        step="2"
    >
      Seleccione el pedido del que se devuelve el producto
    </v-stepper-step>

    <v-stepper-content step="2">
      <v-card
          class="mb-12"

      >
        <v-card-text>
          <v-data-table :items="orders" :headers="ordersHeaders">

            <template v-slot:item.action="props">
              <v-btn dark small color="pink" @click="getProductsFromAPI(props.item)">
                <v-icon dark>mdi-check-outline</v-icon>
                Seleccionar
              </v-btn>
            </template>

          </v-data-table>
        </v-card-text>

      </v-card>

    </v-stepper-content>

    <v-stepper-step
        :complete="e6 > 3"
        step="3"
    >
      Seleccione el producto
    </v-stepper-step>

    <v-stepper-content step="3">
      <v-card>
        <v-row dense>
          <v-col v-for="product in products" cols="12" md="4" lg="4" sm="6" align="center">
            <v-img
                height="150"
                width="250"
                :src="product.image"
            ></v-img>
            <span class="sm2">{{ product.description }}</span>
            <v-row>
              <v-col>
                <span><strong>${{ product.amount }} </strong></span>

              </v-col>
            </v-row>
            <v-row>
              <v-col v-if="!product.returned">
                <v-btn @click="selectOrderLine(product)">Seleccionar</v-btn>
              </v-col>
              <v-col v-else>
                <v-alert
                    dense
                    outlined
                    type="error"
                >
                  Este producto ya ha sido <strong>DEVUELTO!</strong>
                </v-alert>
              </v-col>
            </v-row>
          </v-col>

        </v-row>

      </v-card>

    </v-stepper-content>

    <v-stepper-step step="4">
      Resumen de la devolución
    </v-stepper-step>
    <v-stepper-content step="4">
      <v-card
          class="mx-auto my-12"
          max-width="400"
      >
        <v-row>
          <v-col align="center">
            <v-img
                height="150"
                width="250"
                :src="selectedProduct.image"
            ></v-img>
            <span class="sm2">{{ selectedProduct.description }}</span>
            <v-row>
              <v-col>
                <h4><strong>${{ selectedProduct.amount }} </strong></h4>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-col>
        <v-row>
          <v-col><v-select label="Motivo" :items="reasons" v-model="payload.reason"></v-select></v-col>
        </v-row>

          <v-row>
            <v-col><v-text-field label="Detalle" v-model="payload.note"></v-text-field></v-col>
          </v-row>

        </v-col>

      </v-card>
      <v-btn

          color="primary"
          @click="store()"
      >
        Finalizar
      </v-btn>
      <v-btn text>
        Cancelar
      </v-btn>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
export default {
  data() {
    return {
      e6: 1,
      resellers: null,
      reasons: null,
      orders: [],
      order: {},
      ordersHeaders: [{
        text: 'ID',
        align: 'center',
        value: 'id',
      },
        {
          text: 'Folio',
          align: 'center',
          value: 'number',
        },

        {
          text: 'Importe',
          align: 'center',
          value: 'total'
        },

        {
          text: 'Fecha',
          align: 'center',
          value: 'created_at'
        },

        {
          text: 'Seleccionar',
          align: 'center',
          value: 'action'
        }
      ],

      products: [],
      selectedProduct: {},

      payload: {
        reason:'return',
      },
    }
  },
  mounted() {
    this.getDataFromAPI()
  },
  methods: {
    getDataFromAPI() {
      this.$axios
          .get('driver/returns/create')
          .then((response) => {
            this.resellers = response.data.resellers
            this.reasons = response.data.reasons

          })
    },

    getOrdersFromAPI() {
      this.$axios
          .get('driver/orders/reseller/' + this.payload.reseller_id)
          .then((response) => {
            this.orders = response.data
            this.e6 = 2
            //console.log(this.orders)
          })
    },
    getProductsFromAPI(order) {
      this.order = order
      this.payload.sale_order_id = order.id
      this.$axios
          .get('driver/orders/products/' + this.order.id)
          .then((response) => {
            this.products = response.data
            this.e6 = 3
            console.log(response.data);
          })
    },
    selectOrderLine(orderLine) {
      this.selectedProduct = orderLine
      this.payload.sale_order_line_id = orderLine.id
      this.e6 = 4
    },
    store()
    {
      let formData = new FormData();

      // Loops the payload and inserts it inside formData
      Object.entries(this.payload).forEach(([key, value]) => {
        formData.append(key, value)
      })

      this.$axios
          .post('/driver/returns', formData)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            this.showDialog=false
            this.$router.push('/returns')

          })
          .catch((error)=>{
            console.log(error);
          });
    }

  },
}
</script>

<style scoped>

</style>