<template>
    <v-container fluid>
        <v-form class="pa-0" ref="form" v-model="validForm" lazy-validation>

            <v-toolbar flat color="transparent">

                <v-toolbar-title>
                    <h2 v-if="!$route.params.id" :class="$vuetify.theme.isDark ? 'white--text' : ''">
                        Crear Usuario
                    </h2>

                    <h2 v-else :class="$vuetify.theme.isDark ? 'white--text' : ''">
                        Editar Usuario
                    </h2>

                </v-toolbar-title>

                <v-spacer/>

                <v-btn :disabled="!validForm" class="mx-1" elevation="0" color="primary" rounded @click="validateForm">
                    Guardar
                </v-btn>

                <!-- <v-btn class="mx-1" elevation="0" color="error" rounded v-if="$route.params.id" @click="destroyDialog = true">
                   Eliminar
                 </v-btn>-->

            </v-toolbar>


            <v-row>
                <template>
                    <v-tabs fixed-tabs>
                        <v-tabs-slider></v-tabs-slider>
                        <v-tab
                                href="#general"
                                class="primary--text"
                        >
                            <v-icon>mdi-book-play-outline</v-icon>
                            GENERAL
                        </v-tab>

                        <v-tab v-if="payload.type != 'sstaff'"
                               href="#account"
                               @click="accountStatus"
                               class="primary--text"
                        >
                            <v-icon>mdi-point-of-sale</v-icon>
                            Estado de Cuenta
                        </v-tab>

                        <v-tab-item value="general">
                            <v-row>
                                <v-col xs="12" sm="12" md="8">
                                    <v-col>

                                        <v-card>
                                            <v-card-title class="pb-2">
                                                <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
                                                    <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">
                                                        mdi-clipboard-text
                                                    </v-icon>
                                                    Descripción
                                                </h4>
                                            </v-card-title>
                                            <v-card-text>
                                                <v-row>
                                                    <v-col cols="12" md="12">
                                                        <v-text-field hide-details dense outlined label="Nombre"
                                                                      @keyup="generateUserLogin"
                                                                      v-model="payload.name"
                                                                      :rules="[rules.required]"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" md="6">
                                                        <v-text-field hide-details dense outlined label="Email"
                                                                      v-model="payload.email"
                                                                      disabled
                                                                      :rules="[rules.required]"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="4">
                                                        <v-select hide-details dense outlined label="Tipo"
                                                                  v-model="payload.type"
                                                                  :items="user_types"
                                                                  item-text="text"
                                                                  item-value="value"
                                                                  :rules="[rules.required]">
                                                        </v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="2">
                                                        <v-switch v-model="payload.kiosko" label="Kiosko"></v-switch>
                                                    </v-col>
                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" md="5"
                                                           v-if="payload.type === 'customer' && !payload.kiosko">
                                                        <v-select hide-details dense outlined
                                                                  v-model="payload.reseller_id" label="Distribuidor"
                                                                  :items="resellers" item-text="name" item-value="id"
                                                                  :rules="[rules.required]"></v-select>
                                                    </v-col>

                                                    <v-col cols="12" md="5" v-if="payload.kiosko">
                                                        <v-select hide-details dense outlined
                                                                  v-model="payload.branch_id" label="Sucursal"
                                                                  :items="branches" item-text="name" item-value="id"
                                                                  :rules="[rules.required]"></v-select>
                                                    </v-col>

                                                </v-row>
                                                <v-row>
                                                    <v-col cols="12" md="6">
                                                        <v-text-field hide-details dense outlined
                                                                      v-model="payload.address"
                                                                      label="Dirección"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="6">
                                                        <v-text-field hide-details dense outlined
                                                                      v-model="payload.address2"
                                                                      label="Colonia"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>

                                                <v-row>
                                                    <v-col cols="12" md="5">
                                                        <v-text-field hide-details dense outlined
                                                                      v-model="payload.city"
                                                                      label="Ciudad"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="5">
                                                        <v-text-field hide-details dense outlined
                                                                      v-model="payload.state"
                                                                      label="Estado"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                    <v-col cols="12" md="2">
                                                        <v-text-field hide-details dense outlined
                                                                      v-model="payload.zip"
                                                                      label="Codigo Postal"
                                                        >
                                                        </v-text-field>
                                                    </v-col>
                                                </v-row>

                                                <v-row>
                                                    <v-col xs="6" sm="6" md="6">
                                                        <v-text-field dense outlined label="Teléfono"
                                                                      v-model="payload.phone"
                                                                      placeholder="xxxxxxxxxx"
                                                                      :rules="[rules.required, (v => v.length == 10 || 'El numero telefonico debe contener 10 caracteres' )]"
                                                        >
                                                        </v-text-field>
                                                    </v-col>


                                                    <v-col xs="6" sm="6" md="6">
                                                        <v-dialog
                                                                ref="dialog"
                                                                v-model="modal"
                                                                :return-value.sync="payload.birthday"
                                                                persistent
                                                                width="290px"
                                                        >
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-text-field hide-details dense outlined label
                                                                              v-model="payload.birthday"
                                                                              label="Fecha de nacimiento"
                                                                              prepend-icon="event"
                                                                              readonly
                                                                              v-bind="attrs"
                                                                              v-on="on"
                                                                              :rules="[rules.required]"
                                                                              @change="generateUserLogin"
                                                                ></v-text-field>
                                                            </template>
                                                            <v-date-picker v-model="payload.birthday" scrollable>
                                                                <v-spacer></v-spacer>
                                                                <v-btn text color="primary" @click="modal = false">
                                                                    Cancelar
                                                                </v-btn>
                                                                <v-btn text color="primary"
                                                                       @click="birthdaySelect($refs)">OK
                                                                </v-btn>
                                                            </v-date-picker>
                                                        </v-dialog>
                                                    </v-col>
                                                </v-row>
                                                <v-row>

                                                    <v-col cols="12" md="9" v-if="payload.type=='reseller'">
                                                        <v-select :rules="[rules.required]"
                                                                  v-model="payload.delivery_route_locality_id"
                                                                  hide-details dense outlined label="Localidad"
                                                                  :items="localities" item-value="id"
                                                                  item-text="name"></v-select>
                                                    </v-col>
                                                    <v-col cols="12" md="3" v-if="$route.params.id">
                                                        <v-switch v-model="payload.enabled" label="Activo"></v-switch>
                                                    </v-col>
                                                </v-row>

                                                <v-row v-if="!$route.params.id">
                                                    <v-col xs="6" sm="6" md="6">
                                                        <v-text-field
                                                                label="Password"
                                                                v-model="payload.password"
                                                                :rules=" [rules.required, rules.min]"
                                                                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                                                :type="showPassword ? 'text' : 'password'"
                                                                counter
                                                                @click:append="showPassword = !showPassword"
                                                        ></v-text-field>
                                                    </v-col>

                                                    <v-col xs="6" sm="6" md="6">
                                                        <v-text-field
                                                                label="Confirmacion"
                                                                v-model="passwordConfirmation"
                                                                :rules="[(payload.password === passwordConfirmation) || 'No coincide con la contraseña']"
                                                                :append-icon="showPasswordC ? 'mdi-eye' : 'mdi-eye-off'"
                                                                :type="showPasswordC ? 'text' : 'password'"
                                                                counter
                                                                @click:append="showPasswordC = !showPasswordC"
                                                        ></v-text-field>
                                                    </v-col>
                                                </v-row>

                                            </v-card-text>
                                        </v-card>

                                    </v-col>

                                </v-col>
                                <v-col xs="12" sm="12" md="4">
                                    <v-card>
                                        <v-card-title>
                                            <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
                                                <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">
                                                    mdi-image-area
                                                </v-icon>
                                                Imagen
                                            </h4>
                                        </v-card-title>
                                        <v-card-text>
                                            <v-row justify="center" align="center">
                                                <v-avatar size="250px">
                                                    <v-img
                                                            alt="Avatar"
                                                            :src="photos.photo.url">
                                                    </v-img>
                                                </v-avatar>
                                            </v-row>
                                            <v-col>
                                                <v-file-input v-model="payload.photo" accept="image/*"
                                                              @change="getImage($event,'photo')"
                                                              label="Imagen"
                                                              outlined/>

                                            </v-col>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-tab-item>

                        <v-tab-item value="account">
                            <AccountStatus ref="accountStatusComponent"></AccountStatus>
                        </v-tab-item>
                    </v-tabs>
                </template>
            </v-row>


        </v-form>
    </v-container>
</template>

<script>

    import AccountStatus from "@/views/admin/users/components/AccountStatus";

    export default {
        components: {AccountStatus},
        data() {
            return {
                modal: false,
                validForm: true,
                showPassword: false,
                showPasswordC: false,
                passwordConfirmation: null,
                rules: {
                    required: v => !!v || 'Obligatorio.',
                    min: v => v.length >= 8 || 'Min 8 caracteres',

                },
                resellers: [],
                branches: [],

                payload: {
                    name: '',
                    email: '',
                    photo: null,
                    password: '',
                    type: null,
                    delivery_route_locality_id: null,
                    birthday: '',


                },
                photos: {
                    photo: {},
                    banner: {},
                },


                user_types: [],
                localities: [],

            }
        },

        computed: {},

        methods: {
            birthdaySelect(refs) {
                refs.dialog.save(this.payload.birthday)
                this.generateUserLogin()
            },
            generateUserLogin() {
                if (!this.$route.params.id) {
                    // alert('ok')
                    let name = this.payload.name.replace(/\s+/g, '')
                    let birthday = this.payload.birthday.replace(/-/g, '')
                    let username = name + birthday
                    this.payload.email = username.toLowerCase()
                }
            },
            validateForm() {
                if (this.$refs.form.validate()) {
                    (this.$route.params.id ? this.update() : this.store())
                }
            },
            create() {
                this.$axios
                    .get('/admin/users/create')
                    .then((response) => {
                        this.user_types = response.data.user_types
                        this.photos = response.data.photos
                        this.localities = response.data.delivery_route_localities
                        this.resellers = response.data.resellers
                        this.branches = response.data.branches

                    })
            },

            store() {
                let formData = new FormData();

                // Loops the payload and inserts it inside formData
                Object.entries(this.payload).forEach(([key, value]) => {
                    if (value != null) {
                        formData.append(key, value)
                    }
                })

                // Display the key/value pairs of FormData
                // for (let pair of formData.entries()) {
                //   console.log(pair[0] + ', ' + pair[1]);
                // }

                this.$axios
                    .post('/admin/users', formData)
                    .then((response) => {

                        this.$store.commit('OPEN_SNACKBAR', response.data)
                        (response.data.success ? this.$router.push('/users') : '')

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },

            edit() {
                this.$axios
                    .get(`/admin/users/${this.$route.params.id}/edit`)
                    .then((response) => {
                        this.user_types = response.data.user_types
                        this.photos = response.data.photos
                        this.payload = response.data.item
                        this.localities = response.data.delivery_route_localities
                        this.resellers = response.data.resellers
                        this.branches = response.data.branches
                        //console.log(this.payload)
                    })
            },
            update() {
                let formData = new FormData();

                // Loops the payload and inserts it inside formData
                Object.entries(this.payload).forEach(([key, value]) => {
                    if (value != null) {
                        formData.append(key, value)
                    }
                })

                // Display the key/value pairs of FormData
                // for (let pair of formData.entries()) {
                //   console.log(pair[0] + ', ' + pair[1]);
                // }

                // Sends photos
                formData.append('photos', JSON.stringify(this.photos))

                // Laravel put workaround
                formData.append('_method', 'PUT')

                this.$axios
                    .post(`/admin/users/${this.$route.params.id}`, formData)
                    .then((response) => {

                        this.$store.commit('OPEN_SNACKBAR', response.data)
                        (response.data.success ? this.$router.push('/users') : '')
                    })
                    .catch((error) => {
                        //console.log(error);
                    });
            },


            getImage(e, imageType) {
                //console.log(e)
                // console.log(imageType)
                let file = e
                if (file) {
                    this.loadImage(file, imageType)
                } else {
                    if (imageType == 'photo') {
                        this.photos.photo.url = null
                    } else if (imageType == 'banner') {
                        this.photos.banner.url = null
                    }
                }
            },

            loadImage(file, imageType) {
                let reader = new FileReader()
                reader.onload = (e) => {
                    if (imageType == 'photo') {
                        this.photos.photo = {}
                        this.photos.photo.url = e.target.result

                    } else if (imageType == 'banner') {
                        this.photos.banner = {}
                        this.photos.banner.url = e.target.result
                    }
                }
                reader.readAsDataURL(file)
            },

            accountStatus() {
                const self = this
                setTimeout(function () {
                    self.$refs.accountStatusComponent.getDataFromAPI(self.$route.params.id)
                }, 100);
            }
        },

        mounted() {
            if (this.$route.params.id) {
                this.edit()
            } else {
                this.create()
            }
        }

    }
</script>

