import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import {
    Ripple
} from 'vuetify/lib/directives'

import colors from 'vuetify/lib/util/colors'

Vue.use(colors);
Vue.use(Vuetify)
    // = 8 * 2
export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#00bdaa',
                secondary: '#400082',
                error: '#fe346e',
                accent: '#82B1FF',
                info: '#080DCC',
                success: '#216353',
                warning: '#FB8C00',

            },
            dark: {
                primary: '#00bdaa',
                secondary: '#400082',
                error: '#fe346e',
                accent: '#82B1FF',
                info: '#080DCC',
                success: '#216353',
                warning: '#FB8C00', // If not using lighten/darken, use base to return hex
            }

        },
        dark: false,
        options: {
            minifyTheme: function(css) {
                return process.env.NODE_ENV === 'production' ?
                    css.replace(/[\r\n|\r|\n]/g, '') :
                    css
            },
        },
    },
    breakpoint: {
        thresholds: {
            xs: 340,
            sm: 540,
            md: 800,
            lg: 1280,
        },
        scrollBarWidth: 24,
    },
})