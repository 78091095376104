<template>
  <v-container fluid>
    <v-form class="pa-0" ref="form" v-model="validForm" lazy-validation>

      <v-col class="">

        <v-row >

          <h2 v-if="!$route.params.id" :class="$vuetify.theme.isDark ? 'white--text' : ''">
            Crear Corrida
          </h2>

          <h2 v-else :class="$vuetify.theme.isDark ? 'white--text' : ''">
            Editar Corrida
          </h2>

          <v-spacer></v-spacer>

          <v-btn :disabled="!validForm" class="mx-1" elevation="0" color="primary" rounded @click="validateForm">
            Guardar
          </v-btn>

          <v-btn class="mx-1" elevation="0" color="error" rounded v-if="$route.params.id" @click="destroyDialog = true">
            Eliminar
          </v-btn>
        </v-row>

      </v-col>

      <v-row>
        <v-col cols="12" md="6">
          <v-card>
            <v-card-title class="pb-2">
              <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
                <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-clipboard-text</v-icon>
                Descripción
              </h4>
            </v-card-title>

            <v-card-text>
              <v-row>

                <v-col cols="12" md="6">
                  <v-text-field :rules="emptyRule" hide-details dense outlined v-model="payload.name" label="Nombre">
                  </v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="px-6 py-0">
                  <v-row align="center" justify="center">

                    <h4 class="mb-0 pr-3" :class="$vuetify.theme.isDark ? 'white--text' : ''">Activo:</h4>
                    <v-switch :color="$vuetify.theme.isDark ? 'primary' : 'secondary'" v-model="payload.enabled">
                    </v-switch>
                  </v-row>
                </v-col>

              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" md="6">
          <v-card>
            <v-card-title class="pb-2">
              <v-row justify="space-between" align="center">
                <v-col cols="8">
                  <h4 class="mb-0" :class="$vuetify.theme.isDark ? 'white--text' : ''">
                    <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-clipboard-text</v-icon>
                    Items  ({{ payload.items.length }})
                  </h4>

                </v-col>
                <v-btn
                @click="addChip"
                class="mx-4"
                fab
                small
                elevation="0"
                rounded
                color="primary">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
              </v-row>
            </v-card-title>

            <v-card-text>
              <hr class="my-0">
              <v-col cols="12" >
                <v-row justify="start" :key="index"
                       v-for="(row, index)  in sortedItems">
                  
                  <v-col class="pb-0" md="6" lg="3" cols="6" >

                    <v-text-field
                    :rules="emptyRule"
                    v-model="row.value" 
                    label="Talla">
                    </v-text-field>
                  </v-col>

                  <v-col class="pb-0" md="6" lg="3" cols="6">
                    <v-text-field
                        label="Orden"
                        :rules="emptyRule"
                        v-model="row.order" ></v-text-field>
                  </v-col>
                  <v-col>
                    <template
                        v-if="payload.items.length > 1">
                      <v-btn
                          @click="removeChip(index)"
                          small
                          icon>
                        <v-icon>
                          mdi-close
                        </v-icon>
                      </v-btn>

                    </template>
                  </v-col>
                  
                </v-row>

                <!-- <v-row>
                  <v-col>
                  <draggable tag="ul" :list="payload.items" >
                  <li v-for="(row, index) in payload.items" :key="index" >
                  <i class="fa fa-align-justify handle"></i>
                  <span class="text">{{ index }} </span>
                  <v-text-field  :rules="emptyRule"
                    dark
                    flat
                    dense
                    rounded                       
                    background-color="secondary" 
                    solo
                    v-model="row.value" 
                    label="Talla">
                    <template 
                      v-if="payload.items.length > 1"
                      slot="append">
                        <v-btn
                        @click="removeChip(index)"
                        small
                        icon>
                          <v-icon>                            
                            mdi-close
                          </v-icon>
                        </v-btn>
                        
                      </template>
          </v-text-field>
        </li>
      </draggable>
                  </v-col>
                </v-row> -->
              </v-col>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="destroyDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">¿Estas seguro?</v-card-title>

          <v-card-text>
            Una vez elimines el registro, no podrás recuperarlo.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn rounded color="primary" text @click="destroyDialog = false">
              Mejor no
            </v-btn>

            <v-btn elevation="0" class="pa-4" rounded color="error" @click="destroy">
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-form>
  </v-container>
</template>


<script>
let item_order = 1
 import draggable from 'vuedraggable'
  export default {
    components: {draggable},
    data() {
      return {
        destroyDialog: false,
        validForm: true,
        emptyRule: [v => !!v || 'Necesario'],
        payload: {
          id: null,
          name: null,
          enabled: true,
          items:[
            { 
              order:1,
              value: null
            }
          ]
        }
      }
    },
    methods: {
      addChip() {
        item_order ++
        let row = { value: '',order:item_order};
        this.payload.items.unshift(row);
      },
      removeChip(index) {
        this.payload.items.splice(index, 1);
      },
      validateForm() {
        if (this.$refs.form.validate()) {
          (this.$route.params.id ? this.update() : this.store())
        }
      },
      edit() {
        this.$axios
          .get(`/admin/size-collections/${this.$route.params.id}/edit`)
          .then((response) => {
            // console.log(response.data)
            this.payload = response.data.item
          })
      },
      store() {
        console.log(this.payload);
        this.$axios
          .post('/admin/size-collections', this.payload)
          .then((response) => {
            // console.log(this.payload)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/size-collections') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
      },
      update() {
        // Laravel put workaround
        this.payload['_method'] = 'PUT';

        this.$axios
          .post(`/admin/size-collections/${this.$route.params.id}`, this.payload)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/size-collections') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
      },
      destroy() {
        let formData = new FormData();

        // Laravel delete workaround
        formData.append('_method', 'DELETE')

        this.$axios
          .post(`/admin/size-collections/${this.$route.params.id}`, formData)
          .then((response)=>{
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/size-collections') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
      }
    },
    
    computed: {
      sortedItems() {
        let sorted = this.payload.items.sort((a, b) => {
          return a.order + b.order;
        })
        return sorted
      },
     
    },

    mounted() {
      if (this.$route.params.id) {
        this.edit()
      }
    }
  }
</script>