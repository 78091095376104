<template>
    <v-container fluid>
        <v-form class="pa-0" ref="form" v-model="validForm" lazy-validation>

            <v-toolbar flat color="transparent">

                <v-toolbar-title>
                    <h2 v-if="!$route.params.id" :class="$vuetify.theme.isDark ? 'white--text' : ''">
                        Registrar Cliente
                    </h2>

                    <h2 v-else :class="$vuetify.theme.isDark ? 'white--text' : ''">
                        Editar Cliente
                    </h2>

                </v-toolbar-title>

                <v-spacer/>

                <v-btn :disabled="!validForm" class="mx-1" elevation="0" color="primary" rounded @click="validateForm">
                    Guardar
                </v-btn>

                <!-- <v-btn class="mx-1" elevation="0" color="error" rounded v-if="$route.params.id" @click="destroyDialog = true">
                   Eliminar
                 </v-btn>-->

            </v-toolbar>


            <v-row>
                <v-col xs="12" sm="12" md="8">
                    <v-col>

                        <v-card>
                            <v-card-title class="pb-2">
                                <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
                                    <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-clipboard-text
                                    </v-icon>
                                    Descripción
                                </h4>
                            </v-card-title>
                            <v-card-text>
                                <v-row>
                                    <v-col>
                                        <v-text-field hide-details dense outlined label="Nombre"
                                                      v-model="payload.name"
                                                      @keyup="generateUserLogin"
                                                      :rules="[rules.required]"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-text-field hide-details dense outlined label="Usuario"
                                                      disabled
                                                      v-model="payload.email"
                                                      :rules="[rules.required]"
                                        >
                                        </v-text-field>
                                    </v-col>
                                </v-row>
                                <v-row>

                                    <v-col xs="6" sm="6" md="6">
                                        <v-text-field dense outlined label="Teléfono"
                                                      type="number"
                                                      v-model="payload.phone"
                                                      placeholder="xxxxxxxxxx"
                                                      :rules="[rules.required, (v => v.length == 10 || 'El numero telefonico debe contener 10 caracteres' )]"
                                        >
                                        </v-text-field>
                                    </v-col>


                                    <v-col xs="6" sm="6" md="6">

                                        <v-dialog
                                                ref="dialog"
                                                v-model="modal"
                                                :return-value.sync="payload.birthday"
                                                persistent
                                                width="290px"
                                        >
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-text-field hide-details dense outlined label
                                                              v-model="payload.birthday"
                                                              label="Fecha de nacimiento"
                                                              prepend-icon="event"
                                                              readonly
                                                              v-bind="attrs"
                                                              v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker v-model="payload.birthday" :active-picker="activePicker"  >
                                                <v-spacer></v-spacer>
                                                <v-btn text color="primary" @click="modal = false">Cancelar</v-btn>
                                                <v-btn text color="primary"
                                                       @click="birthdaySelect($refs)">OK
                                                </v-btn>
                                            </v-date-picker>
                                        </v-dialog>
                                    </v-col>
                                </v-row>

                                <!-- <v-col>
                                   <v-select hide-details dense outlined label="Tipo"
                                             v-model="payload.type"
                                             :items="user_types"
                                             item-text="text"
                                             item-value="value"
                                             :rules="[rules.required]">
                                   </v-select>
                                 </v-col>-->

                                <v-col v-if="$route.params.id">
                                    <v-switch v-model="payload.enabled" label="Activo"></v-switch>
                                </v-col>


                                <v-row v-if="!$route.params.id">
                                    <!--                  <v-col xs="6" sm="6" md="6">-->
                                    <!--                    <v-text-field-->
                                    <!--                        label="Password"-->
                                    <!--                        v-model="payload.password"-->
                                    <!--                        :rules=" [rules.required, rules.min]"-->
                                    <!--                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"-->
                                    <!--                        :type="showPassword ? 'text' : 'password'"-->
                                    <!--                        counter-->
                                    <!--                        @click:append="showPassword = !showPassword"-->
                                    <!--                    ></v-text-field>-->
                                    <!--                  </v-col>-->

                                    <!--                  <v-col xs="6" sm="6" md="6">-->
                                    <!--                    <v-text-field-->
                                    <!--                        label="Confirmacion"-->
                                    <!--                        v-model="passwordConfirmation"-->
                                    <!--                        :rules="[(payload.password === passwordConfirmation) || 'No coincide con la contraseña']"-->
                                    <!--                        :append-icon="showPasswordC ? 'mdi-eye' : 'mdi-eye-off'"-->
                                    <!--                        :type="showPasswordC ? 'text' : 'password'"-->
                                    <!--                        counter-->
                                    <!--                        @click:append="showPasswordC = !showPasswordC"-->
                                    <!--                    ></v-text-field>-->
                                    <!--                  </v-col>-->
                                    <v-col>
                                        <v-alert type="info" v-if="payload.phone && payload.phone.length === 10">
                                            Utilizaremos el numero telefonico como contraseña para este usuario
                                            ({{payload.phone}}). Sin embargo si posteriormente cambia de numero
                                            telefonico la contraseña NO se actualizara.
                                        </v-alert>
                                    </v-col>
                                </v-row>

                            </v-card-text>
                        </v-card>

                    </v-col>

                </v-col>

                <v-col xs="12" sm="12" md="4">
                    <v-card>
                        <v-card-title>
                            <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
                                <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-image-area</v-icon>
                                Imagen
                            </h4>
                        </v-card-title>
                        <v-card-text>
                            <v-row justify="center" align="center">
                                <v-avatar size="250px">
                                    <v-img
                                            alt="Avatar"
                                            :src="photos.photo.url">
                                    </v-img>
                                </v-avatar>
                            </v-row>
                            <v-col>
                                <v-file-input v-model="payload.photo" accept="image/*"
                                              @change="getImage($event,'photo')" label="Imagen"
                                              outlined/>

                            </v-col>
                        </v-card-text>
                    </v-card>
                </v-col>

            </v-row>


        </v-form>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                modal: false,
                validForm: true,
                showPassword: false,
                showPasswordC: false,
                passwordConfirmation: null,
                rules: {
                    required: v => !!v || 'Obligatorio.',
                    min: v => v.length >= 10 || 'Min 10 caracteres',

                },
                activePicker:'YEAR',

                payload: {
                    name: '',
                    email: null,
                    phone: '',
                    photo: null,
                    password: '',
                    type: 'customer',
                    birthday: new Date().toISOString().substr(0, 10),

                },
                photos: {
                    photo: {},
                    banner: {},
                },


                user_types: [],

            }
        },


        methods: {
            birthdaySelect(refs) {
                refs.dialog.save(this.payload.birthday)
                this.generateUserLogin()
            },
            generateUserLogin() {
                if (!this.$route.params.id) {
                   // alert(this.payload.birthday)
                    //let name = this.payload.name.replace(/\s+/g, '')
                    let name = this.payload.name
                    let name_split = name.split(' ')

                    let birthday = this.payload.birthday.replace(/-/g, '')
                    let username = name_split[0] +birthday.substring(6,8) + birthday.substring(4,6)
                    this.payload.email = username.toLowerCase()
                }
            },
            flatPickerDate() {
                var config = {
                    altInput: true,
                    altFormat: APP.DATE_FORMAT.KEY,
                };

                return config;
            },
            validateForm() {
                if (this.$refs.form.validate()) {
                    (this.$route.params.id ? this.update() : this.store())
                }
            },
            create() {
                this.$axios
                    .get('/reseller/customers/create')
                    .then((response) => {
                        //this.user_types = response.data.user_types
                        this.photos = response.data.photos
                        //console.log(this.avatar_preview)
                    })
            },

            store() {
                this.payload.password = this.payload.phone
                let formData = new FormData();

                // Loops the payload and inserts it inside formData
                Object.entries(this.payload).forEach(([key, value]) => {
                    formData.append(key, value)
                })

                // Display the key/value pairs of FormData
                // for (let pair of formData.entries()) {
                //   console.log(pair[0] + ', ' + pair[1]);
                // }

                this.$axios
                    .post('/reseller/customers', formData)
                    .then((response) => {

                        this.$store.commit('OPEN_SNACKBAR', response.data)
                        (response.data.success ? this.$router.push('/customers') : '')

                    })
                    .catch((error) => {
                        console.log(error);
                    });
            },

            edit() {
                this.$axios
                    .get(`/reseller/customers/${this.$route.params.id}/edit`)
                    .then((response) => {
                        this.user_types = response.data.user_types
                        this.photos = response.data.photos
                        this.payload = response.data.item
                        //console.log(this.payload)
                    })
            },
            update() {
                let formData = new FormData();

                // Loops the payload and inserts it inside formData
                Object.entries(this.payload).forEach(([key, value]) => {
                    formData.append(key, value)
                })

                // Display the key/value pairs of FormData
                // for (let pair of formData.entries()) {
                //   console.log(pair[0] + ', ' + pair[1]);
                // }

                // Sends photos
                formData.append('photos', JSON.stringify(this.photos))

                // Laravel put workaround
                formData.append('_method', 'PUT')

                this.$axios
                    .post(`/reseller/customers/${this.$route.params.id}`, formData)
                    .then((response) => {
                        console.log(response.data)
                        this.$store.commit('OPEN_SNACKBAR', response.data)
                        (response.data.success ? this.$router.push('/customers') : '')
                    })
                    .catch((error) => {
                        //console.log(error);
                    });
            },


            getImage(e, imageType) {
                //console.log(e)
                // console.log(imageType)
                let file = e
                if (file) {
                    this.loadImage(file, imageType)
                } else {
                    if (imageType == 'photo') {
                        this.photos.photo.url = null
                    } else if (imageType == 'banner') {
                        this.photos.banner.url = null
                    }
                }
            },

            loadImage(file, imageType) {
                let reader = new FileReader()
                reader.onload = (e) => {
                    if (imageType == 'photo') {
                        this.photos.photo = {}
                        this.photos.photo.url = e.target.result
                        console.log(this.photos)
                    } else if (imageType == 'banner') {
                        this.photos.banner = {}
                        this.photos.banner.url = e.target.result
                    }
                }
                reader.readAsDataURL(file)
            },

        },
        mounted() {
            if (this.$route.params.id) {
                this.edit()
            } else {
                this.create()
            }
        }

    }
</script>

