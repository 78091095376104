<template>
    <v-container fluid>

        <v-col cols="12">

            <v-row align="center" justify="space-between">
                <v-col cols="12" md="6">
                    <h1 class="mb-0"
                    :class="$vuetify.theme.isDark ? 'white--text' : ''" >
                        Sin disponibilidad
                    </h1>

                </v-col>

                <v-col cols="12" md="6">
                  <v-btn
                      class="mx-2"
                      fab
                      dark
                      small
                      color="primary"
                      @click="filterModal.visible = true"
                  >
                    <v-icon dark>
                      mdi-filter
                    </v-icon>
                  </v-btn>
                </v-col>


            </v-row>
        </v-col>


        <v-card>
            <v-card-text>
                <v-row class="elevated-3">
                    <v-col>
                        <v-col xs="12" md="12" sm="12">


                            <v-data-table :items="items" :headers="headers" :search="search">
                              <template v-slot:item.updated_at="{item}">
                                {{formatOnlyDate(item.updated_at)}}
                              </template>

                            </v-data-table>

                        </v-col>
                    </v-col>
                </v-row>
            </v-card-text>

        </v-card>

      <v-dialog v-model="filterModal.visible" max-width="800">
        <v-card>
          <v-card-text>
            <v-col>
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                >
                  <v-date-picker
                      v-model="filterModal.payload.dates"
                      range
                  ></v-date-picker>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                >
                  <v-text-field
                      v-model="dateRangeText"
                      label="Rango de Fecha"
                      prepend-icon="mdi-calendar"
                      readonly
                  ></v-text-field>

                </v-col>
              </v-row>
            </v-col>
          </v-card-text>
          <v-card-actions>
            <v-btn color="primary" @click="generate">Generar </v-btn>
            <v-col align="right">
            <v-btn color="secondary" @click="filterModal.visible=false"> Cancelar </v-btn>
            </v-col>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>

</template>

<script>
    import moment from "moment";
    moment.locale('America/Mexico_City')
    export default {
        data() {
            return {
              headers:[
                {'text': 'Cantidad', 'value':'quantity'},
                {'text': 'ID de Pedido', 'value':'sale_order_id'},
                {'text': 'Producto', 'value':'description'},
                {'text': 'Fecha', 'value':'updated_at'}
              ],
              filterModal:{
                visible:true,


                payload:{
                  dates: [],

                },
              },
                items: [],
                search: '',

            };
        },
        computed: {
          dateRangeText () {
            return this.filterModal.payload.dates.join(' ~ ')
          },
        },
        methods: {
          formatOnlyDate(value) {
            return moment(value).format("DD/MM/YYYY h:mm:ss a")
          },
            generate() {
                console.log(this.filterModal.payload)
              this.$axios
                  .post('admin/reports/unavailable', this.filterModal.payload)
                  .then((response) => {
                    this.items = response.data
                    this.filterModal.visible=false
                  })
                  .catch((error) => {
                    console.log(error);
                  });
            },



        },
        mounted() {

        }
    }
</script>