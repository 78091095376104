<template>
  <v-container fluid>
    <v-form class="pa-0" ref="form" v-model="validForm" lazy-validation>

      <v-toolbar flat color="transparent">

        <v-toolbar-title>
          <h2 v-if="!$route.params.id" :class="$vuetify.theme.isDark ? 'white--text' : ''">
            Crear Sucursal
          </h2>

          <h2 v-else :class="$vuetify.theme.isDark ? 'white--text' : ''">
            Editar Sucursal
          </h2>

        </v-toolbar-title>

        <v-spacer />

        <v-btn :disabled="!validForm" class="mx-1" elevation="0" color="primary" rounded @click="validateForm">
          Guardar
        </v-btn>

<!--        <v-btn class="mx-1" elevation="0" color="error" rounded v-if="$route.params.id" @click="destroyDialog = true">-->
<!--          Eliminar-->
<!--        </v-btn>-->

      </v-toolbar>


      <v-row>
        <v-col cols="12" md="12">
          <v-card>
            <v-card-title class="pb-2">
              <h4 :class="$vuetify.theme.isDark ? 'white--text' : ''">
                <v-icon :color="$vuetify.theme.isDark ? 'white' : 'secondary'">mdi-clipboard-text</v-icon>
                Descripción
              </h4>
            </v-card-title>

            <v-card-text>
              <v-row  align="center">

                <v-col cols="12" md="6">
                  <v-text-field :rules="[rules.required]" hide-details dense outlined v-model="payload.name" label="Nombre">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="2">
                  <v-text-field :rules="[rules.required]" hide-details dense outlined v-model="payload.prefix" label="Prefijo">
                  </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                  <v-autocomplete hide-details dense outlined v-model="payload.warehouse_id" :rules="[rules.required]" label="Almacen" :items="warehouses" item-text="name" item-value="id" ></v-autocomplete>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field hide-details dense outlined v-model="payload.street" label="Direccion"></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field hide-details dense outlined v-model="payload.street2" label="Direccion 2"></v-text-field>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" md="4">
                  <v-text-field hide-details dense outlined v-model="payload.city" label="Ciudad"></v-text-field>
                </v-col>

                <v-col cols="12" md="4">
                  <v-text-field hide-details dense outlined v-model="payload.state" label="Estado"></v-text-field>
                </v-col>

                <v-col cols="12" md="2">
                  <v-text-field hide-details dense outlined v-model="payload.country" label="Pais"></v-text-field>
                </v-col>

                <v-col cols="12" md="2">
                  <v-text-field hide-details dense outlined v-model="payload.zip" label="C.P."></v-text-field>
                </v-col>

              </v-row>

              <v-row>
                <v-col cols="12" md="2">
                  <v-text-field hide-details dense outlined v-model="payload.phone" label="Telefono"></v-text-field>
                </v-col>

                <v-col cols="12" md="2">
                  <v-text-field hide-details dense outlined v-model="payload.phone2" label="Telefono 2"></v-text-field>
                </v-col>



              </v-row>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-dialog v-model="destroyDialog" max-width="400">
        <v-card>
          <v-card-title class="headline">¿Estas seguro?</v-card-title>

          <v-card-text>
            Una vez elimines el registro, no podrás recuperarlo.
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn rounded color="primary" text @click="destroyDialog = false">
              Mejor no
            </v-btn>

            <v-btn elevation="0" class="pa-4" rounded color="error" @click="">
              Eliminar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

    </v-form>
  </v-container>
</template>


<script>
  export default {
    data() {
      return {
        destroyDialog: false,
        validForm: true,
        rules: {
          required: v => !!v || 'Required.',
          min: v => v.length >= 8 || 'Min 8 caracteres',
        },
        warehouses:[],
        payload: {
          id: null,
          name: null,
          warehouse_id:99,
          street:'',
          street2:'',
        }
      }
    },
    methods: {
      validateForm() {
        if (this.$refs.form.validate()) {
          (this.$route.params.id ? this.update() : this.store())
        }
      },

      create() {
        this.$axios
                .get(`/admin/branches/create`)
                .then((response) => {
                  // console.log(response.data)
                  this.warehouses = response.data.warehouses
                })
      },

      edit() {
        this.$axios
          .get(`/admin/branches/${this.$route.params.id}/edit`)
          .then((response) => {
             console.log(response.data)
            this.payload = response.data.item
            this.warehouses = response.data.warehouses
          })
      },

      store() {


        this.$axios
          .post('/admin/branches', this.payload)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/branches') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
      },

      update() {


        // Laravel put workaround
        this.payload._method = 'PUT'

        this.$axios
          .post(`/admin/branches/${this.$route.params.id}`, this.payload)
          .then((response) => {
             console.log(this.payload)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.$router.push('/branches') : '')
          })
          .catch((error)=>{
            console.log(error);
          });
      },


    },

    mounted() {
      if (this.$route.params.id) {
        this.edit()
      }else {
        this.create()
      }
    }
  }
</script>