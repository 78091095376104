<template>
  <v-container fluid>

    <v-col cols="12">

      <v-row align="center" justify="space-between">
        <v-col cols="12" md="4">
          <h1 class="mb-0"
              :class="$vuetify.theme.isDark ? 'white--text' : ''" >
            Pedidos
          </h1>
        </v-col>

        <v-col cols="8" md="7">
          <v-text-field hide-details dense outlined flat v-model="search" append-icon="mdi-magnify"
                        label="Buscar...">
          </v-text-field>
        </v-col>

        <!--<v-col cols="4" md="1">
          <v-row justify="center">
            <v-btn fab small elevation="0" color="primary" @click="createItem()">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-row>
        </v-col>-->
      </v-row>
    </v-col>


    <v-card>
      <v-card-text>
        <v-row class="elevated-3">
          <v-col>
            <v-col xs="12" md="12" sm="12">

              <v-data-table :headers="headers" :items="items" :search="search" :options.sync="pagination" :server-items-length="total_items">

                <template v-slot:item.status="{item}">
                  <StatusLabel :status="item.status"></StatusLabel>
                </template>

                <template v-slot:item.created_at="{item}">
                  {{formatOnlyDate(item.created_at)}}
                </template>

                <template v-slot:item.actions="{item}">
                  <v-tooltip top>
                    <template v-slot:activator="{ on }">
                      <v-btn class="mx-1" fab x-small color="warning" dark depressed
                             @click="editItem(item.id)" v-on="on">
                        <v-icon >mdi-dots-horizontal</v-icon>
                      </v-btn>
                    </template>
                    <span>Editar</span>
                  </v-tooltip>
                </template>

              </v-data-table>

            </v-col>
          </v-col>
        </v-row>
      </v-card-text>

    </v-card>
  </v-container>

</template>

<script>
import StatusLabel from "@/components/helpers/statusLabel";
import moment from 'moment'
moment.locale('America/Mexico_City')

export default {
  components: {StatusLabel},
  data() {
    return {
      items: [],
      total_items:0,
      search: '',
      pagination: {
        sortBy:['id'],
        sortDesc:['true']
      },
      headers: [{
        text: 'ID',
        align: 'center',
        value: 'id',
        sortable: true,
      },
        {
          text: 'Folio',
          align: 'center',
          value: 'number',
        },
        {
          text: 'Fecha',
          align: 'center',
          value: 'created_at'
        },

        {
          text: 'Cliente',
          align: 'center',
          value: 'user.name'
        },

        {
          text: 'Distribuidor',
          align: 'center',
          value: 'reseller.name'
        },

        {
          text: 'Estado',
          align: 'center',
          value: 'status'
        },

        {
          text: 'Acciones',
          align: 'center',
          value: 'actions'
        }
      ]
    };
  },

  watch: {
    pagination: {
      handler() {
        this.getDataFromAPI();
        console.log(this.pagination);
      },
    },
      search:{
      handler() {
        this.getDataFromAPI();
      }
    }
  },
  methods: {
    formatOnlyDate(value) {
      return moment(value).format("DD/MM/YYYY h:mm:ss a")
    },

    editItem(id) {
      this.$router.push(`/order/${id}`)
    },

    getDataFromAPI() {

      const { sortBy, sortDesc, page, itemsPerPage } = this.pagination;

      var params = {
        sort_by: sortBy,
        descending: sortDesc,
        page: page,
        rowsPerPage: itemsPerPage,
        search: this.search
      };


      this.$axios
          .get('admin/saleorders',{
            params: params,
          })
          .then((response) => {
            this.items = response.data.sale_orders.data
            this.total_items = response.data.sale_orders.total

          })
    }
  },
  mounted() {
    this.getDataFromAPI()
  }
}
</script>