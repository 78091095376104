<template>
  <v-container fluid>


    <v-toolbar flat color="transparent">
      <v-toolbar-title>
        <h2 v-if="$route.params.id" :class="$vuetify.theme.isDark ? 'white--text' : ''">
          Orden
        </h2>
      </v-toolbar-title>
      <v-spacer/>
    </v-toolbar>

    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12" md="12">
            FOLIO: {{ payload.number }}
            <v-chip color="orange" text-color="white">{{ orderStatus }}</v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col>Cliente:<h4>{{payload.user.name}}</h4></v-col>

        </v-row>

        <v-col cols="12">
          <v-row>
            <v-col cols="12" md="3">
              <v-select v-if="payload.status==='created'" label="Condiciones de pago" v-model="payload.payment_terms" :items="paymentTerms" @change="update"></v-select>
              <v-select v-else disabled label="Condiciones de pago" v-model="payload.payment_terms" :items="paymentTerms"></v-select>
            </v-col>
          </v-row>
        </v-col>
      </v-card-title>
    </v-card>
<v-divider></v-divider>
    <v-card>


      <v-card-text>
        <v-data-table :items="payload.lines" :headers="headers" hide-default-footer>
          <template v-slot:item.line_amount="{item}">
            {{ (item.amount * item.quantity).toFixed(2) }}
          </template>

          <template v-slot:item.unavailable="{item}">
            <v-checkbox v-model="item.unavailable"/>
          </template>

        </v-data-table>
      </v-card-text>

      <v-card-actions v-if="payload.status==='created'">

        <v-col align="left">
          <v-btn color="primary" @click="dialogShow('authorize')">
            Autorizar
          </v-btn>
        </v-col>
        <v-col align="right">
          <v-btn  color="secondary" @click="dialogShow('reject')">
            Rechazar
          </v-btn>
        </v-col>
      </v-card-actions>

    </v-card>

    <!--    Dialog -->
    <v-dialog max-width="400" v-model="modal.visible">
      <v-card>
        <v-card-title>
          {{ modal.title }}
        </v-card-title>
        <v-card-text>
          {{ modal.message }}
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn rounded color="primary" text @click="modal.visible = false">
            Mejor no
          </v-btn>

          <v-btn elevation="0" class="pa-4" rounded color="error" @click="dialogAction(modal.action)">
            {{ modal.textAction }}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
    <!--End Dialog-->

  </v-container>


</template>

<script>
export default {

  data() {

    return {
      validForm: false,
      paymentTerms:[
        {value:'cash', text:'CONTADO (-20%)'},
        {value:'credit', text:'CREDIGENS (16%)'}
      ],
      emptyRule: [v => !!v || 'Necesario'],
      chipStatus: null,
      modal: {
        visible: false,
        title: null,
        message: null,
        textAction: null,
        action: null
      },
      headers: [
        {
          text: 'Cantidad',
          align: 'left',
          value: 'quantity',
        },
        {
          text: 'Producto',
          align: 'left',
          value: 'description',
        },

        {
          text: 'Precio Base',
          align: 'left',
          value: 'price',
        },

        {
          text: 'Descuento',
          align: 'left',
          value: 'discount',
        },

        {
          text: 'Precio',
          align: 'left',
          value: 'amount',
        },

        {
          text: 'Importe',
          align: 'left',
          value: 'line_amount',
        },

      ],
      payload: {
        user:{}
      }
    }
  },
  methods: {
    getDataFromAPI() {
      this.$axios
          .get(`reseller/saleorders/${this.$route.params.id}`)
          .then((response) => {
            this.payload = response.data

          })
    },
    dialogShow($action) {
      switch ($action) {
        case 'authorize':
          this.modal.title = 'Autorizar'
          this.modal.message = 'Esta seguro que desea autorizar este pedido? Al autorizar la orden acepta la compra del producto asi como los cargos establecidos en la orden'
          this.modal.textAction = ' SI, LO AUTORIZO'
          this.modal.action = $action
          this.modal.visible = true
          break;

        case 'reject':
          this.modal.title = 'Rechazar pedido'
          this.modal.message = 'Esta seguro que desea rechazar el pedido? esta accion es irreversible por lo que sera necesario ingresar el pedido de nuevo'
          this.modal.textAction = ' SI, RECHAZAR'
          this.modal.action = $action
          this.modal.visible = true
          break;


      }

    },
    dialogAction($action) {
      switch ($action)
      {
        case 'authorize':
          this.authorizeOrder();
          break;
        case 'reject':
          this.rejectOrder();
          break;

      }
      this.modal.visible=false;

    },
    authorizeOrder(){
      let formData = new FormData();

      // Loops the payload and inserts it inside formData


        formData.append('id', this.payload.id)
        formData.append('payment_terms', this.payload.payment_terms)


      this.$axios
          .post('/reseller/saleorder/authorize', formData)
          .then((response) => {
            //console.log('data',response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.getDataFromAPI() : '')
          })
          .catch((error)=>{
            //console.log(error);
          });
    },

    rejectOrder(){
      let formData = new FormData();

      // Loops the payload and inserts it inside formData


      formData.append('id', this.payload.id)


      this.$axios
          .post('/reseller/saleorder/reject', formData)
          .then((response) => {
            //console.log('data',response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.getDataFromAPI() : '')
          })
          .catch((error)=>{
            //console.log(error);
          });
    },

    update()
    {
      let formData = new FormData();
      formData.append('_method', 'PUT')
      formData.append('payment_terms', this.payload.payment_terms)
      this.$axios
              .post(`/reseller/saleorders/${this.$route.params.id}`, formData)
              .then((response) => {
                console.log(response.data)
                this.$store.commit('OPEN_SNACKBAR', response.data)
                (response.data.success ? this.getDataFromAPI() : '')
              })
              .catch((error) => {
                //console.log(error);
              });
      console.log(this.payload.payment_terms);
    }



  },
  computed: {
    orderStatus() {
      switch (this.payload.status) {
        case 'created':
          return 'Esperamos tu autorización';
        case 'authorized':
          return 'Estamos procesando tu orden';
        case 'reserved':
          return 'Hemos apartado tu mercancia!';
        case 'rejected':
          return 'El pedido ha sido rechazado';
        case 'processed':
          return 'Tu pedido ha sido procesado';
        case 'transit':
          return 'Tu pedido se encuentra en transito';
        case 'delivered':
          return 'Entregado';
        case 'cancel':
          return 'Cancelado';

      }
    }
  },
  mounted() {
    this.getDataFromAPI();
  }
}

</script>

<style scoped>

</style>