<template>

  <v-container fluid>

    <v-col cols="12">

      <v-row align="center" justify="space-between">
        <v-col cols="12" md="4">
          <h1 class="mb-0"
              :class="$vuetify.theme.isDark ? 'white--text' : ''">
            Tabla de precios
          </h1>
        </v-col>

        <v-col cols="8" md="7">
          <v-text-field hide-details dense outlined flat v-model="search" append-icon="mdi-magnify"
                        label="Buscar...">
          </v-text-field>
        </v-col>

        <v-col cols="4" md="1">
          <v-row justify="center">
            <v-btn v-if="rowChanged" fab small elevation="0" color="secondary" @click="update">
              <v-icon>mdi-content-save-alert-outline</v-icon>
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
    </v-col>

    <v-tabs fixed-tabs>
      <v-tabs-slider></v-tabs-slider>
      <v-tab
          href="#general"
          class="primary--text"
      >
        <v-icon>mdi-book-play-outline</v-icon>
        GENERAL
      </v-tab>
      <v-tab
          href="#exceptions"
          class="primary--text"
          @click="loadExceptions()"
      >
        <v-icon>mdi-focus-field</v-icon>
        EXCEPCIONES
      </v-tab>

      <v-tab-item :value="'general'">


        <v-card>
          <v-card-text>
            <v-alert
                :value="rowChanged"
                color="warning"
                icon="new_releases"
            >
              Ha realizado cambios no olvide guardarlos (haga click en el icono guardar de la parte superior)
            </v-alert>
            <v-col>
              <v-btn dark small color="pink" @click="loadModal('custom',null)">
                <v-icon dark>mdi-compare</v-icon>
                Cuanto me gano?
              </v-btn>

            </v-col>
            <v-row class="elevated-3">

              <v-col>
                <v-col xs="12" md="12" sm="12">
                  <v-data-table :headers="headers" :items="sortedItems" :search="search" disable-sort>

                    <template v-slot:item.action="props">
                      <v-btn dark small color="pink" @click="loadModal(null,props.item)">
                        <v-icon dark>mdi-compare</v-icon>
                        Comparar
                      </v-btn>
                    </template>

                  </v-data-table>

                </v-col>
              </v-col>
            </v-row>
          </v-card-text>

        </v-card>

      </v-tab-item>

      <v-tab-item :value="'exceptions'">
        <v-card>

          <v-col>
            <v-btn dark small color="orange" @click="loadExceptionModal('create')">
              <v-icon dark>mdi-plus</v-icon>
              Agregar Excepción
            </v-btn>

          </v-col>
          <v-col>
            <v-alert
                border="top"
                type="info"
                dark
            >
              Las excepciones son configuraciones personalizadas que le permitiran sobre escribir directamente el precio
              de venta de un producto en especifico.
            </v-alert>
          </v-col>


          <v-data-table :items="exceptionItems" :headers="exceptionHeaders">

            <template v-slot:item.actions="props">
              <v-btn dark small color="pink" @click="loadExceptionModal('edit',props.item)">
                <v-icon dark>mdi-pencil</v-icon>
                Editar
              </v-btn>
            </template>


          </v-data-table>
        </v-card>
      </v-tab-item>
    </v-tabs>

    <v-dialog max-width="400" persistent v-model="modal.visible">
      <v-card>
        <v-card-title>
          <v-row align="center">
            <v-col cols="12" class="text-end py-0">
              <v-row justify="end">
                <v-col align="left">
                  <span class="title">Tabla de comparación</span>
                </v-col>
                <v-col xs="4" md="2" lg="2" class="py-0" align="right">
                  <v-btn
                          fab
                          icon
                          depressed
                          color="error"
                          @click="modal.visible = false">
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-card-title>
        <v-card-subtitle>
          <v-col align="center" v-if="modal.calculatingType!=='custom'"> Rango de ${{ modal.rangeFrom }} -
            ${{ modal.rangeTo }}
          </v-col>
        </v-card-subtitle>
        <v-card-text>


          <v-row v-if="modal.calculatingType=='custom'">
            <v-col>
              <v-text-field outlined dense label="Precio base:" @change="calculateDiscount" type="number"
                            v-model="modal.basePrice"></v-text-field>
            </v-col>
          </v-row>
          <v-row v-else>
            <v-col><strong>Precio base:</strong></v-col>
            <v-col align="right">${{ modal.basePrice }}</v-col>
          </v-row>
          <v-row>
            <v-col><strong>Descuento:</strong></v-col>
            <v-col align="right" v-if="modal.saleType==='cash'"><strong>20%</strong></v-col>
            <v-col align="right" v-else><strong>16%</strong></v-col>
            <v-col align="right">${{ modal.suggestedDiscount }}</v-col>
          </v-row>
          <v-row>
            <v-col><strong>Precio de compra:</strong></v-col>
            <v-col align="right">${{ calculateBaseCost }}</v-col>
          </v-row>

          <v-radio-group
              align="center"
              v-model="modal.saleType"
              row>
            <v-col align="left">
              <v-radio
                  label="Contado"
                  value="cash"
                  color="secondary"
              ></v-radio>
            </v-col>
            <v-col align="right">
              <v-radio
                  label="CREDIGENS"
                  value="credit"
                  color="red"

              ></v-radio>
            </v-col>
          </v-radio-group>

          <v-divider></v-divider>

          <v-row>
            <v-col v-if="modal.calculatingType!='custom'">
              <v-row>
                <v-col align="center"><strong>SUGERIDO</strong></v-col>
              </v-row>
              <v-row>

                <v-col align="right"><span class="label-price-table-suggered">${{ modal.suggestedFinalPrice }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>Utilidad $:</v-col>
                <v-col align="right"><span class="label-price-table-suggered">${{ this.suggestedAmountProfit }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>Utilidad %</v-col>
                <v-col align="right"><span
                  class="label-price-table-suggered">{{ this.suggestedPercentageProfit }}</span></v-col>
              </v-row>
              <v-row>
                <v-col align="right">
                  <v-text-field outlined dense disabled label="Incremento $" type="number"
                                v-model="modal.suggestedIncrement"></v-text-field>
                </v-col>
              </v-row>


            </v-col>
            <v-divider vertical></v-divider>
            <v-col>
              <v-row>
                <v-col align="center"><strong>PERSONALIZADO</strong></v-col>
              </v-row>
              <v-row>

                <v-col align="right"><span class="label-price-table-custom">${{ this.customFinalPrice }}</span></v-col>
              </v-row>

              <v-row>
                <v-col>Utilidad $:</v-col>
                <v-col align="right"><span class="label-price-table-custom">${{ this.customAmountProfit }}</span>
                </v-col>
              </v-row>
              <v-row>
                <v-col>Utlidad %:</v-col>
                <v-col align="right"><span class="label-price-table-custom">{{ customPercentageProfit }}</span></v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field outlined dense label="Incremento $" type="number"
                                v-model="modal.customIncrement"></v-text-field>
                </v-col>
              </v-row>

            </v-col>
          </v-row>
        </v-card-text>

        <v-row v-if="modal.calculatingType!='custom'">
          <v-col align="center"><h4>Mí Selección</h4></v-col>
        </v-row>
        <v-card-actions v-if="modal.calculatingType!='custom'">
          <v-btn class="btn-choice" color="secondary" outlined @click="setIncrementItem(modal.item,'suggested')">
            Sugerido
          </v-btn>
          <v-col align="right">
            <v-btn class="btn-choice" color="green" outlined @click="setIncrementItem(modal.item,'custom')">
              Personalizado
            </v-btn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog max-width="400" persistent v-model="exceptionModal.visible">
      <v-card
          class="mx-auto my-12"
          max-width="400">

        <v-card-title>
          <v-row align="center">
            <v-col cols="12" class="text-end py-0">
              <v-row justify="end">
                <v-col align="left">
                  <span class="title">Crear Excepción</span>
                </v-col>
                <v-col xs="4" md="2" lg="2" class="py-0" align="right">
                  <v-btn
                          fab
                          icon
                          depressed
                          color="error"
                          @click="exceptionModal.visible = false">
                    <v-icon>mdi-close-circle-outline</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>

        </v-card-title>

        <v-img v-if="exceptionModal.product.photo"
               height="250"
               :src="exceptionModal.product.photo"
        ></v-img>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <v-autocomplete
                  label="Marca"
                  :items="brands"
                  v-model="exceptionModal.brand_id"
                  item-text="name"
                  item-value="id"
                  @change="loadProducts()"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row v-if="exceptionModal.brand_id">
            <v-col cols="12" md="12">
              <v-autocomplete
                  label="Producto"
                  :items="products"
                  v-model="exceptionModal.payload.product_id"
                  item-text="name"
                  item-value="id"
                  @change="loadProductDetail()"
              ></v-autocomplete>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="exceptionModal.product.cost">
              <v-text-field outlined disabled dense type="number" label="Tu costo"
                            v-model="exceptionModal.product.cost">
              </v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col v-if="exceptionModal.product.price">
              <v-text-field outlined dense type="number"
                            label="Precio al publico"
                            v-model="exceptionModal.payload.fixed_price"
                            @change="checkPrice()"
              >
              </v-text-field>
              <v-alert type="warning" v-model="exceptionModal.costAlert">El precio de venta no puede ser menor a su
                costo
              </v-alert>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions v-if="exceptionModal.payload.id">
          <v-col v-if="!exceptionModal.costAlert && exceptionModal.payload.product_id" align="left">
            <v-btn color="secondary" @click="exceptionUpdate()">ACTUALIZAR</v-btn>
          </v-col>
          <v-col v-if="exceptionModal.payload.id" align="right">
            <v-btn color="error" @click="destroyDialog=true">ELIMINAR</v-btn>
          </v-col>
        </v-card-actions>
        <v-card-actions v-else>
          <v-col v-if="!exceptionModal.costAlert && exceptionModal.payload.product_id" align="right">
            <v-btn color="secondary" @click="exceptionStore()">GUARDAR</v-btn>
          </v-col>
        </v-card-actions>
      </v-card>

    </v-dialog>

    <v-dialog v-model="destroyDialog" max-width="400">
      <v-card>
        <v-card-title class="headline">¿Estas seguro?</v-card-title>

        <v-card-text>
          Una vez elimines el registro, no podrás recuperarlo.
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn rounded color="primary" text @click="destroyDialog = false">
            Mejor no
          </v-btn>

          <v-btn elevation="0" class="pa-4" rounded color="error" @click="exceptionDestroy">
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>

</template>

<script>


export default {
  data() {
    return {
      rowChanged: false,
      destroyDialog: false,
      brands: null,
      products: null,
      exceptionItems: [],
      exceptionHeaders: [
        {
          text: 'Marca',
          align: 'center',
          value: 'product.brand.name',
        },
        {
          text: 'Modelo',
          align: 'center',
          value: 'product.name',
        },
        {
          text: 'Precio Fijo',
          align: 'center',
          value: 'fixed_price',
        },
        {
          align: 'right',
          value: 'actions'
        }

      ],
      exceptionModal: {
        visible: false,
        costAlert: false,
        brand_id: null,
        product: {},
        payload: {
          product_id: null,
          fixed_price: 0.00
        },

      },
      modal: {
        visible: false,
        calculatingType: null,
        basePrice: 1000,
        baseCost: 800,
        discount: 0,
        saleType: 'cash',
        rangeFrom: 0.00,
        rangeTo: 0.00,

        suggestedDiscount: 0,
        suggestedIncrement: 0,
        suggestedProfit: 300,
        suggestedFinalPrice: 0,
        suggestedPercentProfit: 37.5,

        customDiscount: 0,
        customIncrement: 100,
        customProfit: 300,
        customFinalPrice: 1100,
        customPercentProfit: 37.5

      },
      items: [],
      search: '',
      headers: [{
        text: 'De',
        align: 'center',
        value: 'from',
      },
        {
          text: 'Hasta',
          align: 'center',
          value: 'to',
        },


        {
          text: 'Incremento',
          align: 'center',
          value: 'increment',
        },

        {
          text: '',
          align: 'center',
          value: 'action'
        },


      ]
    };
  },
  computed: {
    sortedItems() {
      return this.items.sort((a, b) => (a.id > b.id) ? 1 : -1)
    },

    calculateBaseCost() {
      let basePrice = this.modal.basePrice
      if (basePrice > 0) {
        let cost = 0
        if (this.modal.saleType === 'cash') {

          cost = basePrice * 0.80
        } else {
          cost = basePrice * 0.84
        }
        cost = cost.toFixed(0)
        this.modal.suggestedDiscount = basePrice - cost
        this.modal.baseCost = cost
        return cost
      }
    },

    suggestedAmountProfit() {
      if (this.modal.baseCost > 0 && this.modal.suggestedFinalPrice > 0) {
        let amountProfit = this.modal.suggestedFinalPrice - this.modal.baseCost
        amountProfit = amountProfit.toFixed(0)
        return amountProfit
      }
    },

    suggestedPercentageProfit() {
      if (this.modal.baseCost > 0 && this.modal.suggestedFinalPrice > 0) {
        let percentageProfit = (((this.modal.suggestedFinalPrice / this.modal.baseCost) * 100) - 100)
        percentageProfit = percentageProfit.toFixed(0)
        return percentageProfit + ' %'
      }
    },


    customFinalPrice() {
      let finalPrice = 0
      finalPrice = parseFloat(this.modal.basePrice) + parseFloat(this.modal.customIncrement)

      if (finalPrice > 0) {
        finalPrice = finalPrice.toFixed(0)
        this.modal.customFinalPrice = finalPrice
      } else {
        this.modal.customFinalPrice = 0
      }
      return this.modal.customFinalPrice
    },

    customAmountProfit() {
      if (this.modal.baseCost > 0 && this.modal.customFinalPrice > 0) {
        let amountProfit = this.modal.customFinalPrice - this.modal.baseCost
        amountProfit = amountProfit.toFixed(0)
        return amountProfit
      }
    },
    customPercentageProfit() {
      if (this.modal.baseCost > 0 && this.modal.customFinalPrice > 0) {
        let percentageProfit = (((this.modal.customFinalPrice / this.modal.baseCost) * 100) - 100)
        percentageProfit = percentageProfit.toFixed(0)
        return percentageProfit + ' %'
      }
    },

    calculateDiscount() {
      let discount = 0;
      if (this.modal.saleType === 'cash') {
        discount = (parseFloat(this.modal.basePrice) * 0.20).toFixed(0)
      } else {
        discount = (parseFloat(this.modal.basePrice) * 0.16).toFixed(0)
      }
      this.modal.suggestedDiscount = discount
      return discount
    },


  },
  methods: {


    calculatePercentageProfit(baseCost, finalPrice) {
      if (baseCost > 0 && finalPrice > 0) {
        let percentageProfit = (((finalPrice / baseCost) * 100) - 100)
        percentageProfit = percentageProfit.toFixed(0)
        return percentageProfit + ' %'
      }
    },


    loadModal(type = null, item) {
      this.modal.calculatingType = type
      if (type === null) {

        this.modal.item = item
        this.modal.rangeFrom = parseFloat(item.from).toFixed(0)
        this.modal.rangeTo = parseFloat(item.to).toFixed(0)
        this.modal.basePrice = parseFloat(item.to).toFixed(0)
        //this.modal.basePrice = parseFloat(this.modal.basePrice).toFixed(0)

        this.modal.suggestedIncrement = item.suggested_increment
        this.modal.customIncrement = item.increment
        let suggestedFinalPrice = parseFloat(this.modal.basePrice) + parseFloat(this.modal.suggestedIncrement)
        suggestedFinalPrice = suggestedFinalPrice.toFixed(0)
        this.modal.suggestedFinalPrice = suggestedFinalPrice
        this.modal.suggestedPercentProfit = this.calculatePercentageProfit(this.modal.baseCost, suggestedFinalPrice)

        this.modal.visible = true
      } else {

        //alert(type)
        this.modal.visible = true

      }

    },

    setIncrementItem(item, choice) {
      this.rowChanged = true
      if (choice === 'suggested') {
        item.increment = this.modal.suggestedIncrement
      } else {
        item.increment = this.modal.customIncrement
      }
      this.modal.visible = false
    },

    update() {
      this.$axios
          .post('/reseller/tablePrice', this.items)
          .then((response) => {

            this.$store.commit('OPEN_SNACKBAR', response.data)
            this.getDataFromAPI()
            //(response.data.success ? this.$router.push('/pricestable') : '')
            this.rowChanged = false
          })
          .catch((error) => {

          });
    },


    getDataFromAPI() {
      this.$axios
          .get('reseller/tablePrice')
          .then((response) => {
            this.items = response.data.price_table
            this.brands = response.data.brands

          })
    },
    checkPrice() {
      if (this.exceptionModal.product.cost > this.exceptionModal.payload.fixed_price) {
        this.exceptionModal.costAlert = true
      } else {
        this.exceptionModal.costAlert = false
      }
    },
    loadProducts() {
      this.$axios
          .get('reseller/pricetable/getProductsByBrand/' + this.exceptionModal.brand_id)
          .then((response) => {
            //console.log(response);
            this.products = response.data

          })

    },
    loadExceptionModal(type = null, item = null) {
      this.exceptionModal.payload = {}
      this.exceptionModal.product = {}
      this.exceptionModal.brand_id = null

      if (type === 'create') {
        this.exceptionModal.visible = true
      } else {
        this.exceptionModal.brand_id = item.product.product_brand_id
        this.loadProducts()
        this.exceptionModal.payload = item
        this.loadProductDetail()

        this.exceptionModal.visible = true
      }
    },

    loadProductDetail() {
      this.$axios
          .get('reseller/pricetable/getProductsDetail/' + this.exceptionModal.payload.product_id)
          .then((response) => {
            //console.log(response);
            this.exceptionModal.payload.product_id = response.data.id
            this.exceptionModal.product = response.data


          })
    },

    loadExceptions() {
      this.$axios
          .get('reseller/tablePriceExceptions')
          .then((response) => {
            this.exceptionItems = response.data


          })

    },

    exceptionStore() {
      this.checkPrice()
      if (!this.exceptionModal.costAlert) {
        let formData = new FormData();

        // Loops the payload and inserts it inside formData
        Object.entries(this.exceptionModal.payload).forEach(([key, value]) => {
          formData.append(key, value)
        })
        this.$axios
            .post('reseller/tablePriceExceptions', formData)
            .then((response) => {
              // console.log(response.data)
              this.$store.commit('OPEN_SNACKBAR', response.data);
              (response.data.success ? this.exceptionModal.visible = false : '')
            })
            .then(() => {
              this.loadExceptions()
            })
            .catch((error) => {
              console.log(error);
            });
      }
    },

    exceptionUpdate() {
      let formData = new FormData();

      // Loops the payload and inserts it inside formData
      Object.entries(this.exceptionModal.payload).forEach(([key, value]) => {
        formData.append(key, value)
      })

      // Sends photos
      formData.append('photos', JSON.stringify(this.photos))

      // Laravel put workaround
      formData.append('_method', 'PUT')

      this.$axios
          .post(`/reseller/tablePriceExceptions/${this.exceptionModal.payload.id}`, formData)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            (response.data.success ? this.exceptionModal.visible = false : '')
          })
          .then(() => {
            this.loadExceptions()
          })
          .catch((error) => {
            console.log(error);
          });
    },

    exceptionDestroy() {
      let formData = new FormData();

      // Laravel delete workaround
      formData.append('_method', 'DELETE')

      this.$axios
          .post(`/reseller/tablePriceExceptions/${this.exceptionModal.payload.id}`, formData)
          .then((response) => {
            // console.log(response.data)
            this.$store.commit('OPEN_SNACKBAR', response.data);
            if (response.data.success) {
              this.destroyDialog = false
              this.exceptionModal.visible = false
              this.loadExceptions()
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },

  },

  mounted() {
    this.getDataFromAPI()
  }

}
</script>

<style scoped>
.label-price-table-suggered {

  display: block;
  border: 1px solid;
  text-align: center;
  color: #400082;
}

.label-price-table-custom {

  display: block;
  border: 1px solid;
  text-align: center;
  color: green;
}

.btn-choice {
  width: 164px;
}

</style>

