import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        snackbar: {
            active: false,
            text: 'My timeout is set to 2000.',
            timeout: 3000,
            success: false
        },
        user: {
            isLoggedIn:false,
            data:null
        }
    },
    mutations: {
        OPEN_SNACKBAR(state, payload) {
            state.snackbar.active = true
            state.snackbar.text = payload.msg
            state.snackbar.success = payload.success
        },
        CLOSE_SNACKBAR(state) {
            state.snackbar.active = false
        },
        SET_USER(state, response) {
            if(response != null){
                state.user = response
                state.user.isLoggedIn=true
            }else{
                state.user = {}
            }

        }
    },
    actions: {

        getUser(){
            let respond = null
            const instance = axios.create({
                baseURL: process.env.VUE_APP_BACKEND_API_URL,
                headers: {'X-Requested-With': 'XMLHttpRequest'},
                withCredentials:true,
            });
           return instance.post('getUser')
                .then((response) => {
                    console.log(response);
                    this.commit("SET_USER", response.data)

                })
                .then(()=>{
                    respond = true
                })
                .catch((error)=>{
                    console.log('error',error)
                    this.commit("SET_USER",null)
                    respond = false
                    //window.location.href = process.env.VUE_APP_BACKEND_URL + '/login';
                });

        },


    },
    modules: {}
})
