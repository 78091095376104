<template>
    <v-col align="center">
    <img src="../../../assets/img/logo.png">
    </v-col>
</template>

<script>
    export default {
        name: "dashboard"
    }
</script>

<style scoped>

</style>