<!--======================================
=            SIDEBAR-VERTICAL            =
=======================================-->

<template>
  <v-navigation-drawer
    class="sidebar-vertical elevation-2"
    v-model="appConfig.model"
    :clipped="appConfig.clipped"
    :floating="appConfig.floating"
    :mini-variant="appConfig.expandOnHover"
    :permanent="appConfig.type === 'permanente'"
    :temporary="appConfig.type === 'temporal'"
    app
    overflow
    :dark="appConfig.dark"
    width="256"
  >
  <template v-slot:prepend>
      <v-toolbar class="elevation-0 transparent">
        <v-toolbar-title class="logo-img">
            <a href="https://genscatalogo.com">
        <img src="../../../assets/img/logo.png">
            </a>
        </v-toolbar-title>
      </v-toolbar>

    </template>
      <v-list >
  <v-list-item two-line v-if="this.$store.state.user.data">
          <v-list-item-avatar>
            <v-img :src="this.$store.state.user.photos.photo.url" ></v-img>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title   :dark="appConfig.dark">{{this.$store.state.user.data.name }}</v-list-item-title>
            <v-list-item-subtitle   :dark="appConfig.dark">Bienvenido</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>


      </v-list>



    <v-divider></v-divider>
    <starter-list></starter-list>
  </v-navigation-drawer>
</template>
<script>
import StarterList from "../Starter/StarterList";
export default {
  components: { StarterList },
  props: {
    source: String
  },
  data: () => ({
    isActive: true,
    active: true,
    mini: false,
    navbar: true
  }),
  methods: {
    goGroup(x, y) {
      let vm = this;
      vm.$router.push(`/${x}/${y}`);
    },
    goPage(x, y) {
      let vm = this;
      vm.$router.push(`/${x}/${y}`);
    }
  },
  computed: {}
};
</script>

<style scoped>
.sidebar-vertical {



  height: 100% !important;
  top: 0px !important;
  text-decoration-line: none;
}
.logo-img img {
  width: 180px;
  height: 70px;
  text-align: center;
  padding: 20px;
  margin-left: 20px;
  margin-top: 10px;
  margin-bottom: 20px;
}.bg-gradient{
background-image: linear-gradient( 135deg, #2AFADF 10%, #4C83FF 100%);
}
</style>
<!--====  End of SIDEBAR-VERTICAL  ====-->
