<template>
  <!-- base-footer -->
  <v-footer :inset="appConfig.footer.inset" :dark="appConfig.dark" app>
    <span class="px-4">&copy; {{ new Date().getFullYear() }}<strong> Gens </strong>. Todos los derechos reservados.</span>
  </v-footer>
</template>
<script>
export default {

  data: () => ({
    icons: [
      "fab fa-facebook",
      "fab fa-twitter",
      "fab fa-google-plus",
      "fab fa-linkedin",
      "fab fa-instagram"
    ]
  })
};
</script>
<style scoped>
.v-footer {
  z-index: auto !important;
}
.app-footer {
  margin: 10;
  background: transparent;
  padding: 1.25rem;
  padding-left: 30%;
  position: relative;
  z-index: initial;
  border-radius: 10px;
}

</style>
